import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicFileUploadFieldSets = ({
  onFieldChange,
  acceptedTypes,
  noteLabel,
  inputIdPrefix,
  fileCategory,
}) => {
  const [fields, setFields] = useState([
    { content: "", extension: "", field_name: "" },
  ]);

  const handleFileChange = (event, index) => {
    const file = event.target?.files[0];
    if (file) {
      const fileExtension = getFileExtension(file.name);
      getBase64(file).then((base64) => {
        const attachment = {
          documentType: fileCategory,
          field_name: fields[index]?.field_name
            ? fields[index]?.field_name
            : "",
          file_content: base64,
          file_extension: fileExtension,
        };
        // Calculate the file size
        const fileSize = file.size;

        // Pass the file size to the parent component
        onFileSizeChange(fileSize);

        const updatedFields = [...fields];
        updatedFields[index] = attachment;
        setFields(updatedFields);
        onFieldChange(updatedFields);
      });
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      };
    });
  };
  const onFieldNameChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index].field_name = value;
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  const addField = () => {
    const newField = { file: { content: "", extension: "" } };
    setFields([...fields, newField]);
  };

  const onFieldRemove = (index) => {
    const updatedFields = fields.filter((_, i) => index !== i);
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    } else if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  const onFileSizeChange = (fileSize) => {
    const formattedSize = formatFileSize(fileSize);
  };
  return (
    <Col md={12} className="dynamicField">
      <Button
        className="clickable addStyle mb-3 ms-auto d-flex"
        onClick={addField}
      >
        <span className="addBttn d-block">+</span>
      </Button>
      {fields.map((field, index) => (
        <div className="d-flex gap-3" key={index}>
          <div className="d-flex flex-column flex-wrap gap-2 w-100 mb-3">
            <Form.Group className="form-floating position-relative">
              <Form.Control
                type="text"
                className="uploadlabel docUploader w-100"
                name="document"
                placeholder="title"
                id={`${inputIdPrefix}-label-${index}`}
                onChange={(e) => onFieldNameChange(index, e.target.value)}
              />
              <Form.Label
                className="form-label file-label"
                htmlFor={`${inputIdPrefix}-label-${index}`}
              >
                Add your {fileCategory} title
              </Form.Label>
            </Form.Group>
            <Form.Group className="flex-grow-1">
              <Form.Label
                className="form-label file-upload-label w-100 mb-0"
                htmlFor={`${inputIdPrefix}-file-${index}`}
              >
                <Form.Control
                  type="file"
                  className="uploadFile docUploader w-100 mt-1"
                  name="document"
                  id={`${inputIdPrefix}-file-${index}`}
                  accept={acceptedTypes}
                  onChange={(e) => handleFileChange(e, index)}
                />
                <div className="docPreview">
                  <span className="theme-text-2 d-block">
                    *Upload Your {noteLabel} File
                  </span>
                </div>
              </Form.Label>
            </Form.Group>
          </div>
          <Button
            className="clickable addStyle bg-danger border-danger"
            onClick={() => onFieldRemove(index)}
          >
            <span className="addBttn d-block">
              <Trash />
            </span>
          </Button>
        </div>
      ))}
    </Col>
  );
};

export default DynamicFileUploadFieldSets;
