import React, { useEffect, useState } from "react";
import "./contact.css";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  Twitter,
  Facebook,
  Google,
  Instagram,
  Check2Circle,
} from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import EmailHeader from "../../components/emailTemplate/emailHeader";
import EmailCorporateBody from "../../components/emailTemplate/getCorporateBody";
import EmailFooter from "../../components/emailTemplate/emailFooter";
import { confirmAlert } from "react-confirm-alert";
import useUserData from "../../components/useUserDetails/useUserDetails";
import CustomLoader from "../../components/loader/loader";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";

const Contact = () => {
  const currentDomain = window.location.hostname;
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");
  const fromEmail = SubDomainRouter("fromEmail");

  const [loading, setLoading] = useState(true);
  const userData = useUserData({ setLoading });

  const [reqLoading, setReqLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    message_for_team: "A User is seeking for help",
  });

  const campaignFields = {
    userId: myUserId,
    type: "email",
    to: [],
    cc: [],
    bcc: [],
    subject: "Request From User Through Contact Form",
    from: fromEmail,
    Attachments: null,
    Template: null,
    IsHTML: true,
  };

  useEffect(() => {
    setFormData({
      ...formData,
      phone: userData?.phone,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setReqLoading(true);

    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const mappedBodyData = EmailCorporateBody(formData);
    const footer_email_content = EmailFooter(logo, title);

    const data = {
      ...campaignFields,
      userId: myUserId,
      to: [adminEmail, supportEmail],
      message: `${header_email_content} ${mappedBodyData} ${footer_email_content}`,
    };

    axios
      .post(`${Actions.APIGATEWAY_URL}email`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setReqLoading(false);
        successCampaignAlert();
        resetFields();
      })
      .catch((error) => {
        setReqLoading(false);
        console.log(error);
      });
  };

  const resetFields = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      message_for_team: "A User is seeking for help",
    });
  };
  const successCampaignAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white py-4 px-2 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <p className="text-center lh-1">
              <small>
                Thank You for showing Interest&nbsp;
                <b>
                  {formData?.first_name} {formData?.last_name}
                </b>
                <br />
                We will contact you soon!
              </small>
            </p>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };
  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>Contact | {siteName}</title>
      </Helmet>

      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="ContactUsPage mt-4">
          <Container fluid="lg">
            <Form
              className="ContactUsForm mt-sm-5 mt-4 px-md-2 py-md-4 py-3"
              onSubmit={handleSubmit}
            >
              <h1 className="text-center mb-5">Get in Touch</h1>

              <Row>
                <Col lg={6}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      id="first_name"
                      name="first_name"
                      required
                      placeholder="First Name"
                      value={formData?.first_name}
                      onChange={(e) =>
                        setFormData({ ...formData, first_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="first_name">First Name</Form.Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      id="last_name"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData?.last_name}
                      onChange={(e) =>
                        setFormData({ ...formData, last_name: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="last_name">Last Name</Form.Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      id="email"
                      name="email"
                      placeholder="email"
                      required
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="email">Email</Form.Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData?.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-floating formfloating mb-3">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      required
                      id="message"
                      name="message"
                      placeholder="WHAT DO YOU HAVE IN MIND"
                      value={formData?.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></Form.Control>
                    <Form.Label htmlFor="message">
                      WHAT DO YOU HAVE IN MIND
                    </Form.Label>
                  </div>
                </Col>
                <Row className="mt-4">
                  <Col lg={12} className="ContactBottom">
                    <Button
                      className="ContactSubmitBtn add-button"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                  {currentDomain === "ameerpetit" && (
                    <>
                      <Col
                        lg={12}
                        className="social-media-links ContactBottom mb-3 mt-5"
                      >
                        <a href="/">
                          <Twitter />
                        </a>
                        <a href="/" target="_blank">
                          <Facebook />
                        </a>
                        <a href="/" target="_blank">
                          <Google />
                        </a>
                        <a href="/" target="_blank">
                          <Instagram />
                        </a>
                      </Col>
                      <Col lg={12} className=" ContactBottom">
                        <p className="contactAddress">
                          Address : 39206 Guardino Dr, Fremont, CA - 94538 (USA)
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default Contact;
