import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Actions from "../../constants/baseUrl";
import EmailFooter from "../emailTemplate/emailFooter";
import EmailHeader from "../emailTemplate/emailHeader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import AddTag from "../tagInput/tagInput";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";
import EntityItem from "./entityItem";
import DaysSelect from "../durationSelect/daysSelect";
import EmailBodyNewsletter from "../emailTemplate/emailBodyNewsletter";

const DailyNewsletter = () => {
  const navigate = useNavigate();

  const logo = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const courseAlias = SubDomainRouter("courseAlias");
  const demoAlias = SubDomainRouter("demoAlias");
  const demosAlias = SubDomainRouter("demosAlias");
  const adminEmail = SubDomainRouter("adminEmail");
  const supportEmail = SubDomainRouter("supportEmail");
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const fromEmail = SubDomainRouter("fromEmail");
  const title = SubDomainRouter("title");
  const userData = useUserData();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [templateData, setTemplateData] = useState([]);
  const [emailLists, setEmailLists] = useState([]);

  const [hasDemos, setHasDemos] = useState(false);
  const [hasCourses, setHasCourses] = useState(false);
  const [numCourses, setNumCourses] = useState(1);
  const [numDemos, setNumDemos] = useState(1);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [demosData, setDemosData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);

  const [formData, setFormData] = useState({
    campaign_name: "My Newsletter",
    description: "My Newsletter Description",
    userId: myUserId,
    type: "campaign",
    emaillists: [],
    entityRelation: [],
    subject: `${title} Newsletter Template`,
    body_header: "",
    body: "",
    body_footer: "",
    body_plain_text: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    scheduled_date: "",
    scheduled_time: "",
    campaign_sent_status: "",
    tags: [],
    comments: "",
  });

  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedEmailList, setSelectedEmailList] = useState([]);

  const [fromUserEmail, setFromUserEmail] = useState(null);
  const [addedIDs, setAddedIDs] = useState([]);

  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [convertedUTCTime, setConvertedUTCTime] = useState("");
  const [startTime, setStartTime] = useState(new Date());

  const [hasMessage, setHasMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState("");

  // Email part
  const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
  const [emailBodyContent, setEmailBodyContent] = useState(undefined);
  const [emailFooterContent, setEmailFooterContent] = useState(undefined);

  const handleFieldChange = (fieldName, fieldValue) => {
    let newValue;

    if (fieldName === "cc" || fieldName === "bcc" || fieldName === "to") {
      newValue = fieldValue
        ?.split(/[, \n]+/)
        ?.map((id) => id?.trim())
        .filter((id) => id?.length > 0);
    } else {
      newValue = fieldValue;
    }

    const newState = {
      ...formData,
      [fieldName]: newValue,
    };

    setFormData(newState);
  };
  // Get templates
  const getTemplates = () => {
    axios
      .get(`${Actions.BASE_URL}templates/users/${myUserId}`, formData, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setTemplateData(res.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // Get Emaillists
  const getEmaillists = () => {
    axios
      .get(`${Actions.BASE_URL}emaillists/users/${myUserId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setEmailLists(res.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleEmailListSelection = async (selectedListName) => {
    setSelectedEmailList(selectedListName);
  };

  // Handle selected template's data
  const handleTemplateSelection = (selectedTemplateName) => {
    const selectedTemplateData = templateData.find(
      (temp) => temp.template_name === selectedTemplateName
    );
    setSelectedTemplate(selectedTemplateName);

    setFormData({
      ...formData,
      template_name: selectedTemplateData?.template_name,
      subject: selectedTemplateData?.subject,
      body: selectedTemplateData?.body,
      body_plain_text: selectedTemplateData?.body_plain_text,
    });
  };

  const mainTimeZones = [
    "Asia/Kolkata",
    "Asia/Karachi",
    "Europe/London",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "Australia/Sydney",
    "Europe/Berlin",
    "Asia/Tokyo",
    "Africa/Johannesburg",
    "Europe/Paris",
    "America/Toronto",
    "Asia/Dubai",
    "America/Denver",
    "Asia/Hong_Kong",
    "America/Mexico_City",
    "Europe/Madrid",
    "Asia/Singapore",
    "America/Phoenix",
    "Australia/Perth",
    "Africa/Cairo",
    "Asia/Kuala_Lumpur",
  ];

  const handleTimeZoneChangeURL = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    convertToUTC(eventTime, selectedOption?.value);
  };

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment
      .tz(givenTime, "HH:mm", timeZone)
      .utc()
      .format("HH:mm");
    setConvertedUTCTime(utcTime);
  };

  const timeZones = moment.tz
    .names()
    .filter((tz) => mainTimeZones.includes(tz))
    .map((tz) => ({
      label: tz,
      value: tz,
    }));
  const handleEventTimeChange = (e) => {
    const newTime = startTime;
    setEventTime(newTime);

    if (selectedTimeZone) {
      convertToUTC(newTime, selectedTimeZone.value);
    }
  };

  useEffect(() => {
    handleEventTimeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, selectedTimeZone]);

  useEffect(() => {
    handleFieldChange("scheduled_time", convertedUTCTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedUTCTime]);

  // Steps
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    setLoading(true);

    const data = {
      ...formData,
      emaillists: [...selectedEmailList],
      body: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      to: [...addedIDs, adminEmail, supportEmail],
      cc: [fromUserEmail],
      from: fromEmail,
    };

    axios
      .post(`${Actions.BASE_URL}campaigns`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(false);
        successCampaignAlert("newsletter");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} sent successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate("/campaigns-dashboard/newsletter");
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  // Call Req fun initially
  useEffect(() => {
    getTemplates();
    getEmaillists();

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mappedBodyData = EmailBodyNewsletter(
      selectedItemDetails,
      customMessage,
      demosAlias,
      coursesAlias
    );

    setEmailBodyContent(mappedBodyData);
  }, [coursesAlias, customMessage, demosAlias, selectedItemDetails]);

  useEffect(() => {
    const mappedBodyData = EmailBodyNewsletter(
      selectedItemDetails,
      customMessage,
      demosAlias,
      coursesAlias
    );
    setEmailBodyContent(mappedBodyData);
    handleFieldChange("body", mappedBodyData);
  }, [coursesAlias, customMessage, demosAlias, selectedItemDetails]);

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    setEmailHeaderContent(header_email_content);
    setEmailFooterContent(footer_email_content);

    const newState = {
      ...formData,
      body_header: header_email_content,
      body_footer: footer_email_content,
    };

    setFormData(newState);
  }, [coursesAlias, demosAlias, logo, title]);

  const generatedTemplate = `${emailHeaderContent}${emailBodyContent}${emailFooterContent}`;
  useEffect(() => {
    localStorage.setItem("generatedEmail", generatedTemplate);
    // const jsonString = JSON.stringify(relationData);
    // sessionStorage.setItem("entityRelations", jsonString);
    sessionStorage.setItem("recipient", addedIDs);
    sessionStorage.setItem("fromUser", fromUserEmail);
  }, [addedIDs, formData, fromUserEmail, generatedTemplate]);

  useEffect(() => {
    setFromUserEmail(userData?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    // Fetch demos data
    setIsLoadingData(true);
    axios
      .get(`${Actions.BASE_URL}demos/days/${numDemos}`)
      .then((response) => {
        setDemosData(response.data);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.error("Error fetching demos:", error);
      });
  }, [hasDemos, numDemos]);

  useEffect(() => {
    // Fetch courses data
    setIsLoadingData(true);
    axios
      .get(`${Actions.BASE_URL}courses/days/${numCourses}`)
      .then((response) => {
        setCoursesData(response.data);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.error("Error fetching courses:", error);
      });
  }, [hasCourses, numCourses]);

  const handleCheckboxChange = async (type, id) => {
    // Fetching details based on the selected type and id
    setLoading(true);
    try {
      const response = await axios.get(`${Actions.BASE_URL}${type}s/${id}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setLoading(false);

      // Filter the selected item based on the ID
      const filteredItem =
        type.toLowerCase() === "demo"
          ? response.data
          : type.toLowerCase() === "course"
          ? response.data
          : null;

      // Update the selected items' details state
      setSelectedItemDetails((prevSelectedItems) => {
        if (type === "demo" && !hasDemos) {
          // If demos are unchecked, remove only the selected demos
          return prevSelectedItems.filter((item) => item.type !== "demo");
        }

        return prevSelectedItems.some((item) => item.id === filteredItem.id)
          ? prevSelectedItems.filter((item) => item.id !== filteredItem.id)
          : [...prevSelectedItems, filteredItem];
      });
    } catch (error) {
      setLoading(false);
      console.error(error.response);
    }
  };

  return (
    <Container fluid="lg" className="formMain create p-0 demosEditSection">
      {loading || isLoadingData ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      <div className="demosAddFormHeader text-center">
        <h2 className="addFormHeading theme-text-2">Newsletter</h2>
        <div className="demosEditHeader text-center my-3 mb-lg-5">
          <ul className="demosEditList">
            <li>
              <button
                className={`stepstriggers ${step === 1 ? "active" : null}`}
                onClick={() => setStep(1)}
              >
                <span>1 </span>
              </button>
            </li>
            <li>
              <button
                className={`stepstriggers ${step === 2 ? "active" : null}`}
                onClick={() => setStep(2)}
              >
                <span>2</span>
              </button>
            </li>
            <li>
              <button
                className={`stepstriggers ${step === 3 ? "active" : null}`}
                onClick={() => setStep(3)}
                disabled={
                  selectedEmailList?.length === 0 && addedIDs?.length === 0
                }
              >
                <span>3</span>
              </button>
            </li>
            <li>
              <button
                className={`stepstriggers ${step === 4 ? "active" : null}`}
                onClick={() => setStep(4)}
                disabled={
                  selectedEmailList?.length === 0 && addedIDs?.length === 0
                }
              >
                <span>4</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/* Step 1 */}
        <Row className={`mx-0 ${step === 1 ? "d-block" : "d-none"}`}>
          <Col
            md={12}
            className="d-flex justify-content-between gap-3 flex-md-row flex-column"
          >
            <h5 className="theme-text flex-grow-1">
              Select {courseAlias}(s) or {demoAlias}(s)
            </h5>
            <div className="d-flex gap-3">
              <Form.Check
                type="checkbox"
                className="custom-checkbox ps-0"
                id="checkboxDemos"
                label={demosAlias}
                onChange={() => setHasDemos(!hasDemos)}
                checked={hasDemos}
                entityType="demo"
              />
              <Form.Check
                type="checkbox"
                className="custom-checkbox ps-0"
                id="checkboxCourses"
                label={coursesAlias}
                onChange={() => setHasCourses(!hasCourses)}
                checked={hasCourses}
                entityType="course"
              />
            </div>
          </Col>
          <hr />
          {!hasDemos && !hasCourses ? <p>Select an entity first.</p> : null}
          <Col md={12}>
            {hasDemos ? (
              <div className="py-3">
                <div className="d-flex justify-content-between align-items-md-center mb-2 flex-md-row flex-column gap-2">
                  <h4 className="text-capitalize theme-text-2">
                    {demosAlias}.
                  </h4>
                  <FormGroup className="form-floating w-25">
                    <DaysSelect
                      setState={setNumDemos}
                      titleAlias={demosAlias}
                    />
                  </FormGroup>
                </div>
                {demosData.length > 0 ? (
                  demosData.map((demo) => (
                    <EntityItem
                      key={demo.id}
                      id={demo.id}
                      isChecked={selectedItemDetails.some(
                        (item) => item.id === demo.id
                      )}
                      onCheckboxChange={() =>
                        handleCheckboxChange("demo", demo.id)
                      }
                      item={demo}
                    />
                  ))
                ) : (
                  <small>No {demosAlias} found...</small>
                )}
              </div>
            ) : null}

            {hasCourses ? (
              <div className="py-3">
                <div className="d-flex justify-content-between align-items-md-center mb-2 flex-sm-row flex-col gap-2">
                  <h4 className="text-capitalize theme-text-2">
                    {coursesAlias}.
                  </h4>
                  <FormGroup className="form-floating w-25">
                    <DaysSelect
                      setState={setNumCourses}
                      titleAlias={coursesAlias}
                    />
                  </FormGroup>
                </div>
                {coursesData.length > 0 ? (
                  coursesData.map((course) => (
                    <EntityItem
                      key={course.id}
                      id={course.id}
                      isChecked={selectedItemDetails.some(
                        (item) => item.id === course.id
                      )}
                      onCheckboxChange={() =>
                        handleCheckboxChange("course", course.id)
                      }
                      item={course}
                    />
                  ))
                ) : (
                  <small>No {coursesAlias} found...</small>
                )}
              </div>
            ) : null}
          </Col>

          <Col md={12} className="mt-3">
            <Button
              type="button"
              onClick={handleNext}
              className="next demosEditBtn w-auto"
            >
              Next
            </Button>
          </Col>
        </Row>
        {step === 2 && (
          <Row className="mx-0">
            <Col md={12}>
              <h5 className="theme-text mb-2">
                Select Email Lists & Sender Info
              </h5>
            </Col>
            <hr />

            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="email"
                  className="italicInput"
                  id="from"
                  required
                  placeholder="From"
                  name="from"
                  value={fromUserEmail || ""}
                  onChange={(e) => setFromUserEmail(e.target.value)}
                />
                <Form.Label className="formLabel" htmlFor="from">
                  From
                </Form.Label>
              </Form.Group>
            </Col>

            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-2">
                <Select
                  className="text-capitalize form-control select-email"
                  required
                  isMulti
                  placeholder="Select an existing email list"
                  options={emailLists.map((email) => ({
                    value: email.id,
                    label: email.list_name,
                  }))}
                  onChange={(selectedOptions) =>
                    handleEmailListSelection(
                      selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []
                    )
                  }
                />
              </Form.Group>
            </Col>
            <Col
              md={12}
              className="d-flex align-items-center justify-content-center gap-2 mb-2"
            >
              <hr width="25%" className="my-1" />
              Or
              <hr width="25%" className="my-1" />
            </Col>
            <Col md={12}>
              <AddTag
                label={"To"}
                addedTags={addedIDs}
                setAddedTags={setAddedIDs}
              />
            </Col>

            <Col ms={12} className="mt-3">
              <Button
                type="button"
                className="previous-button demosEditBtn"
                onClick={handlePrevious}
              >
                Previous
              </Button>
              <Button
                type="submit"
                disabled={
                  selectedEmailList?.length === 0 && addedIDs?.length === 0
                }
                onClick={handleNext}
                className="next demosEditBtn"
              >
                Next
              </Button>
            </Col>
          </Row>
        )}
        {step === 3 && (
          <Row className="mx-0">
            <Col md={12}>
              <h5 className="theme-text mb-2">Compose Email from Template</h5>
            </Col>
            <hr />
            <Col md={12}>
              <Form.Group className="form-floating mb-3">
                <Form.Select
                  id="template"
                  className="text-capitalize form-control"
                  required
                  name="template"
                  multiple=""
                  value={selectedTemplate || ""}
                  onChange={(e) => handleTemplateSelection(e.target.value)}
                >
                  <option value="">
                    {templateData.length > 0
                      ? "Select an existing Template"
                      : "No Existing Templates"}
                  </option>
                  {templateData?.map((temp) => (
                    <option key={temp.id} value={temp.template_name}>
                      {temp.template_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  placeholder="Template Name"
                  className="form-control italicInput"
                  name="template_name"
                  value={formData?.template_name || ""}
                  id="template_name"
                  onChange={(e) =>
                    handleFieldChange("template_name", e.target.value)
                  }
                />
                <Form.Label className="form-label" htmlFor="template_name">
                  Template Name
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  className="italicInput"
                  id="subject"
                  required
                  placeholder="Enter Event Subject"
                  name="subject"
                  value={formData?.subject || ""}
                  onChange={(e) => handleFieldChange("subject", e.target.value)}
                />
                <Form.Label className="formLabel" htmlFor="subject">
                  Subject
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={"4"}
                  placeholder="body_header"
                  className="form-control max-h-auto"
                  name="body_header"
                  value={formData?.body_header}
                  id="body_header"
                  onChange={(e) =>
                    handleFieldChange("body_header", e.target.value)
                  }
                />
                <Form.Label className="form-label" htmlFor="body_header">
                  Body Header
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12} className="d-flex gap-1 align-items-baseline">
              <Form.Check
                name="has_message"
                checked={hasMessage}
                id="has_message"
                onChange={() => {
                  setHasMessage(!hasMessage);
                }}
              />
              <Form.Label
                className="theme-text-2 fw-normal"
                htmlFor="has_message"
              >
                Add a custom message
              </Form.Label>
            </Col>
            {hasMessage && (
              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    as={"textarea"}
                    rows={"4"}
                    placeholder="body_custom_message"
                    className="form-control max-h-auto"
                    name="body_custom_message"
                    value={customMessage}
                    id="body_custom_message"
                    onChange={(e) => setCustomMessage(e.target.value)}
                  />
                  <Form.Label
                    className="form-label"
                    htmlFor="body_custom_message"
                  >
                    Body Custom Message
                  </Form.Label>
                </Form.Group>
              </Col>
            )}
            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={formData?.body ? "12" : "4"}
                  placeholder="body"
                  className="form-control max-h-auto"
                  name="body"
                  value={formData?.body}
                  id="body"
                  onChange={(e) => handleFieldChange("body", e.target.value)}
                />
                <Form.Label className="form-label" htmlFor="body">
                  Body Content
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={"4"}
                  placeholder="body_footer"
                  className="form-control max-h-auto"
                  name="body_footer"
                  value={formData?.body_footer}
                  id="body_footer"
                  onChange={(e) =>
                    handleFieldChange("body_footer", e.target.value)
                  }
                />
                <Form.Label className="form-label" htmlFor="body_footer">
                  Body Footer
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as={"textarea"}
                  rows={"4"}
                  placeholder="body_plain_text"
                  className="form-control max-h-auto"
                  name="body_plain_text"
                  value={formData?.body_plain_text}
                  id="body_plain_text"
                  onChange={(e) =>
                    handleFieldChange("body_plain_text", e.target.value)
                  }
                />
                <Form.Label className="form-label" htmlFor="body_plain_text">
                  Body Plain Text
                </Form.Label>
              </Form.Group>
            </Col>

            <Col ms={12} className="mt-3">
              <Button
                type="button"
                className="previous-button demosEditBtn w-auto"
                onClick={handlePrevious}
              >
                Previous
              </Button>

              <div className="next d-flex gap-2">
                <Button
                  type="button"
                  className="demosEditBtn"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {step === 4 && (
          <Row className="mx-0">
            <Col md={12}>
              <h5 className="theme-text mb-2">Send Campaign</h5>
            </Col>
            <hr />
            <Col md={6}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mx-auto mb-3">
                <ReactDatePicker
                  className="form-control form-select w-100"
                  selected={startTime}
                  onChange={(date) => {
                    setStartTime(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  multiple=""
                  dateFormat="h:mm aa"
                />
                {startTime == null && (
                  <Form.Label className="formLabel" htmlFor="time">
                    Select a time to send this campaign
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="batch">
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mx-auto mb-3">
                <Select
                  className="timezone form-control z-3"
                  required
                  id="timezone"
                  options={timeZones}
                  value={selectedTimeZone}
                  onChange={handleTimeZoneChangeURL}
                  placeholder="Select your time zone"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mx-auto mb-3">
                <Form.Control
                  type="date"
                  className="italicInput"
                  id="scheduled_date"
                  placeholder="Enter date"
                  name="scheduled_date"
                  value={formData?.scheduled_date}
                  onChange={(e) =>
                    handleFieldChange("scheduled_date", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="scheduled_date">
                  Scheduled Date
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <small className="text-end d-block text-dark">
                required<span className="text-danger">*</span>
              </small>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  className="italicInput"
                  id="campaign_name"
                  placeholder="Enter Event Subject"
                  name="campaign_name"
                  value={formData?.campaign_name}
                  onChange={(e) =>
                    handleFieldChange("campaign_name", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="campaign_name">
                  Give a Name to this campaign
                </Form.Label>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  as="textarea"
                  rows={"4"}
                  id="description"
                  placeholder="Enter Event Subject"
                  name="description"
                  value={formData?.description}
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                />
                <Form.Label className="formLabel" htmlFor="to">
                  Campaign Description
                </Form.Label>
              </Form.Group>
            </Col>

            <Col ms={12} className="mt-3">
              <Button
                type="button"
                className="previous-button demosEditBtn w-auto"
                onClick={handlePrevious}
              >
                Previous
              </Button>
              <div className="next-button next d-flex gap-sm-3 gap-2">
                <Link
                  to={`/campaigns/email`}
                  target="_blank"
                  className="demosEditBtn text-nowrap add-button btn"
                >
                  Generate Email
                </Link>

                <Button type="submit" className="demosEditBtn">
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
};

export default DailyNewsletter;
