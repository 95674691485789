import axios from "axios";
import React from "react";
import * as Actions from "../../constants/baseUrl";
import AddLogo from "./AddLogo.png";
import "./intro.css";
import LandingNavBar from "./landingnavbar";

export default class TrainerIntro extends React.Component {
  state = {
    email: "",
    name: "",
    phone: "",
    whatsapp: "",
    specialization: "",
    video_urls: [{}],
  };
  state = {
    videos: [{}],
  };
  addVideoLink() {
    this.setState((prevState) => ({
      videos: [...prevState.videos, {}],
    }));
  }

  deleteVideoLink(index) {
    this.setState((prevState) => {
      const videos = [...prevState.videos];
      videos.splice(index, 1);
      return { videos };
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    var data = this.state;

    axios.post(`${Actions.BASE_URL}trainers`, data).then((res) => {
      alert("Thank you for your submittion!");
      window.location.reload();
    });
  };
  render() {
    return (
      <>
        <LandingNavBar />
        <form action="" method="post" role="form">
          <div className="container-lg col-lg-6">
            <div className="row">
              <h2 className="payheader">Provide Trainer Info Below</h2>
            </div>
            <div className="row">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="row mt-3">
              <label htmlFor="email">Email </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="row mt-3">
              <label htmlFor="specialization">Specialization </label>
              <input
                type="text"
                id="specialization"
                name="specialization"
                onChange={(e) =>
                  this.setState({ specialization: e.target.value })
                }
              />
            </div>
            <div className="row mt-3">
              <label htmlFor="phone">Phone </label>
              <input
                type="text"
                id="phone"
                name="phone"
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
            <div className="row mt-3">
              <label htmlFor="whatsapp">Whatsapp </label>
              <input
                type="text"
                id="whatsapp"
                name="whatsapp"
                onChange={(e) => this.setState({ whatsapp: e.target.value })}
              />
            </div>
            <div className="row mt-3">
              <label htmlFor="video_request">
                <input
                  type="checkbox"
                  id="video_request"
                  className="visible"
                  name="video_request"
                  onChange={(e) =>
                    this.setState({ video_request: e.target.value })
                  }
                />{" "}
                Schedule a Demo for recoding Video{" "}
              </label>
            </div>
            <div className="row mt-3">
              {this.state.videos.map((email, index) => (
                <div className="col-lg-12  col-md-12 row mt-2" key={index}>
                  <div className="col-lg-8 col-md-12 col-sm-12 no-padding">
                    <div className="form-floating form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id={`video_urls${index}`}
                        name={`video_urls${index}`}
                        placeholder="Video Urls"
                        onChange={(e) =>
                          this.setState({
                            video_urls: {
                              ...this.state.video_urls,
                              [`Intro_${index}`]: e.target.value,
                            },
                          })
                        }
                      ></input>
                      <label htmlFor={`video_urls${index}`}>
                        Please provide your best teaching video urls
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-3 no-padding">
                    {index > 0 ? (
                      <a
                        type="button"
                        onClick={() => this.deleteVideoLink(index)}
                        className="btn btn-danger deleteStyle"
                      >
                        X
                      </a>
                    ) : null}
                  </div>
                  {index == this.state.videos.length - 1 ? (
                    <div className="col-lg-2 col-md-2">
                      <a
                        onClick={() => this.addVideoLink()}
                        className="clickable"
                      >
                        <img src={AddLogo} alt="" height={35} />
                      </a>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

            <div className="row mt-5">
              {" "}
              <input
                type="submit"
                name="submit"
                value="Submit"
                className="btn btn-success"
                onClick={this.handleSubmit}
              />
            </div>
            <div className="row mt-3">
              <b className="txtbold">
                By Submitting you are Accepting Terms of AmeerpetIT to Validate
                the video by posting on AmeerpetIT Hub.
              </b>{" "}
            </div>
          </div>
        </form>
      </>
    );
  }
}
