import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import moment from "moment-timezone";
import useUserData from "../useUserDetails/useUserDetails";

const CampaignRelations = ({
  fields,
  field,
  index,
  onFieldChange,
  onEntityDetailsChange,
  selectOptions,
}) => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();
  const role = userData.role?.toLocaleLowerCase();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(Object.keys(field)[0]);
  const [value, setValue] = useState(field[type]);
  const [coursesData, setCoursesData] = useState([]);
  const [demosData, setDemosData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleTypeChange = (newType) => {
    setType(newType);
    const updatedFields = [...fields];
    updatedFields[index] = { [type]: value };
    onFieldChange(updatedFields);
  };

  useEffect(() => {
    // Extract selected items from the fields array
    const updatedSelectedItems = fields
      .map((field) => {
        const type = Object.keys(field)[0];
        const value = field[type];
        return type && value ? getSelectedItem(type, value) : null;
      })
      .filter((item) => item !== null);

    // Update the selected items state
    setSelectedItems(updatedSelectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const getSelectedItem = (type, value) => {
    if (type.toLowerCase() === "demo") {
      return demosData.find((demo) => demo?.id === value);
    } else if (type.toLowerCase() === "course") {
      return coursesData.find((course) => course?.id === value);
    } else {
      return null;
    }
  };

  const handleValueChange = async (newValue) => {
    setValue(newValue);
    const updatedFields = [...fields];
    updatedFields[index] = { [type]: newValue };
    onFieldChange(updatedFields);
    setLoading(true);
    try {
      await axios.get(`${Actions.BASE_URL}${type}s/${newValue}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setLoading(false);
      // Filter the selected item based on the ID
      const filteredItem =
        type.toLowerCase() === "demo"
          ? demosData.find((demo) => demo?.id === newValue)
          : type.toLowerCase() === "course"
          ? coursesData.find((course) => course?.id === newValue)
          : null;

      setSelectedItems((prevSelectedItems) => {
        // Check if the item is already in the array
        if (!prevSelectedItems.some((item) => item?.id === filteredItem?.id)) {
          return [...prevSelectedItems, filteredItem];
        } else {
          return prevSelectedItems;
        }
      });
    } catch (error) {
      setLoading(false);
      console.error(error.response);
    }
  };

  const onFieldRemove = () => {
    const updatedFields = fields.filter((_, i) => i !== index);
    onFieldChange(updatedFields);
  };

  useEffect(() => {
    onFieldChange(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  // Get Demos
  const getDemos = () => {
    const data = {
      userid: myUserId,
    };

    const demosEC =
      role === "admin" || "superadmin"
        ? axios.get(`${Actions.BASE_URL}demos`, {
            headers: {
              Authorization: "Bearer " + myToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
        : axios.post(
            `${Actions.BASE_URL}dashboard/MyDemos?userid=${myUserId}`,
            data,
            {
              headers: {
                Authorization: "Bearer " + myToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

    demosEC
      .then((res) => {
        // Sort data  in ascending order
        const sortedData = res.data?.sort((a, b) => {
          const dateDiff = moment(a.start_date).diff(moment(b.start_date));

          // If the dates are equal, compare based on time
          if (dateDiff === 0) {
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          }

          return dateDiff;
        });
        setDemosData(sortedData);
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error.response);
      });
  };

  // Get Courses
  const getCourses = () => {
    const data = {
      userid: myUserId,
    };

    const coursesEC =
      role === "admin" || "superadmin"
        ? axios.get(`${Actions.BASE_URL}courses`, {
            headers: {
              Authorization: "Bearer " + myToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
        : axios.post(
            `${Actions.BASE_URL}dashboard/MyCourses?userid=${myUserId}`,
            data,
            {
              headers: {
                Authorization: "Bearer " + myToken,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
    coursesEC
      .then((res) => {
        // Sort data  in ascending order
        const sortedData = res.data?.sort((a, b) => {
          const dateDiff = moment(a.start_date).diff(moment(b.start_date));

          // If the dates are equal, compare based on time
          if (dateDiff === 0) {
            return moment(a.start_time, "HH:mm:ss").diff(
              moment(b.start_time, "HH:mm:ss")
            );
          }

          return dateDiff;
        });
        setCoursesData(sortedData);
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error.response);
      });
  };

  // Call Req fun initially
  useEffect(() => {
    getCourses();
    getDemos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUndefinedData = selectedItems?.map((item) => item === undefined);

  useEffect(() => {
    if (hasUndefinedData && type && value) {
      handleValueChange(value);
      handleTypeChange(type);
      onFieldChange(fields);
      getSelectedItem(type, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onEntityDetailsChange(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  return (
    <div className="d-flex gap-3 w-100 align-items-sm-center mb-3">
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      <div className="flex-grow-1 row mx-0">
        <div className="col-sm-6 mb-2 ps-0">
          <Form.Group className="form-floating formfloating">
            <Form.Select
              value={type}
              onChange={(e) => handleTypeChange(e.target.value)}
              className="form-control form-select text-capitalize"
            >
              <option value="">Select Entity Type</option>
              {selectOptions?.map((option) => (
                <option
                  className="text-capitalize"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="col-sm-6 mb-2 ps-sm-2 ps-0">
          <Form.Group className="form-floating">
            <Form.Select
              value={value}
              onChange={(e) => handleValueChange(e.target.value)}
              className="form-control form-select text-capitalize"
              disabled={!type}
            >
              <option value="">
                {type.toLocaleLowerCase() === "demo" ? (
                  <>
                    {demosData?.length > 0
                      ? `Select ${demosAlias}`
                      : `No ${demosAlias} Yet`}
                  </>
                ) : type.toLocaleLowerCase() === "course" ? (
                  <>
                    {coursesData?.length > 0
                      ? `Select ${coursesAlias}`
                      : `No ${coursesAlias} Yet`}
                  </>
                ) : (
                  "an entity type first"
                )}
              </option>
              {type.toLocaleLowerCase() === "demo" &&
                demosData?.map((demo) => (
                  <option
                    className="text-capitalize"
                    key={demo?.id}
                    value={demo?.id}
                  >
                    {demo.title}
                  </option>
                ))}
              {type.toLocaleLowerCase() === "course" &&
                coursesData?.map((course) => (
                  <option
                    className="text-capitalize"
                    key={course?.id}
                    value={course?.id}
                  >
                    {course.title}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>

      <Button
        className="clickable addStyle bg-danger border-danger"
        onClick={onFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default CampaignRelations;
