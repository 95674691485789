import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Filter, PlusCircle, Share } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import USER from "../../img/user.png";
import FilterChips from "../filterSidebar/filterChips";
import FilterSidebar from "../filterSidebar/filterSidebar";
import CustomLoader from "../loader/loader";
import StarRating from "../starRating/starRating";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import useUserData from "../useUserDetails/useUserDetails";
import "./trainersCard.css";

export default function Trainers() {
  const entities = "trainers";

  const userData = useUserData();
  const role = userData.role?.toLocaleLowerCase();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [smFilterMenu, setSmFilterMenu] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(9);
  const [courseNamesKeys, setCourseNamesKeys] = useState([]);
  const [subCategoryKeys, setSubCategoryKeys] = useState([]);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [entityObj, setEntityObj] = useState({
    type: "trainer",
    searchTerm: "",
    subcategory: [""],
    category: [""],
    entity_types: [""],
    course_types: [""],
    teaching_modes: [""],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    type: "trainer",
    course_types: [],
    category: [],
    subcategory: [],
    entity_types: [""],
    searchTerm: "",
    teaching_modes: [],
  });
 

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-info",
          onClick: () => {
            axios.delete(`${Actions.BASE_URL}${entities}/${id}`).then((res) => {
              populateList();
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityObj]);

  useEffect(() => {
    fetchCourseNames();
    fetchCategory();
    fetchSubCategory();
  }, []);

  // Get Cards Data
  const populateList = async () => {
    setLoadingFilter(true);
    try {
      if (
        entityObj?.course_types == "" &&
        entityObj?.category == "" &&
        entityObj?.subcategory == "" &&
        entityObj?.teaching_modes == "" &&
        entityObj?.searchTerm?.trim() == ""
      ) {
        const response = await axios.get(`${Actions.BASE_URL}${entities}`);
        setItems(response.data);
        setLoading(false);
      } else {
        const response = await axios.post(
          `${Actions.BASE_URL}Search/filters`,
          entityObj
        );
        setItems(response.data.trainers);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error calling API:", error);
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };

  const fetchCourseNames = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/courseNames`)
        .then((response) => {
          setCourseNamesKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/categoryTypes`)
        .then((response) => {
          setCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // entity category fetched from ENUM
  const fetchSubCategory = async () => {
    try {
      await axios
        .get(`${Actions.BASE_URL}enums/subcategoryTypes`)
        .then((response) => {
          setSubCategoryKeys(response.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 3 <= items.length
          ? prevDisplayedCards + 3
          : items.length
      );
    };

    return (
      <div className="d-flex mt-5 pt-5">
        <FilterSidebar
          filteringFrom="trainer"
          courseNamesKeys={courseNamesKeys}
          categoryKeys={categoryKeys}
          subCategoryKeys={subCategoryKeys}
          setSmFilterMenu={setSmFilterMenu}
          smFilterMenu={smFilterMenu}
          setEntityObj={setEntityObj}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        <div className="overlay" onClick={() => setSmFilterMenu(false)}></div>
        <Container fluid="lg" className="px-lg-4">
          <Row className="my-4 justify-content-md-start justify-content-center">
            <Col lg={10} sm={6} className="mb-3">
              <h3
                id="tabelLabel"
                className="pageSubHeading mb-md-0 mb-2  text-md-start text-center"
              >
                Trainers
              </h3>
            </Col>
            {role === "Admin" && (
              <Col
                lg={2}
                sm={6}
                className="d-flex align-items-center mb-3 justify-content-md-end justify-content-center"
              >
                <Link
                  to={`/${entities}/add`}
                  className="form-control w-md-75 w-auto px-md-3 px-5 btn d-flex align-items-center justify-content-center add-button"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            )}

            <Col
              md={12}
              className="d-flex align-items-sm-center justify-content-between align-items-start"
            >
              <FilterChips selectedFilters={selectedFilters} />
              <Button
                variant="link"
                className="filterToggle ms-auto p-0 text-right ms-auto d-lg-none me-lg-0 me-2 d-flex align-items-center justify-content-center btn bg-transparent"
                onClick={() => setSmFilterMenu(!smFilterMenu)}
              >
                <Filter />
              </Button>
            </Col>
            {loadingFilter ? (
              <CustomLoader />
            ) : (
              <>
                {items?.length !== 0 ? (
                  items.slice(0, displayedCards).map((item) => (
                    <Col
                      xl={4}
                      md={6}
                      sm={10}
                      key={item.id}
                      className="mt-2 my-3"
                    >
                      <Card className="profileCard">
                        <div className="contUser d-flex align-items-end gap-2">
                          <Link
                            to={`/trainers/details/${item.id}`}
                            className="userImage"
                          >
                            <Card.Img
                              src={item.listing_image_url || USER}
                              alt="Load Error"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = USER;
                              }}
                              className="img-fluid"
                            />
                          </Link>
                          <div className="d-flex align-items-center justify-content-between gap-1">
                            <div className="det-cont flex-grow-1">
                              <Link
                                to={`/trainers/details/${item.id}`}
                                className="username mt-2"
                              >
                                {item.name}
                              </Link>
                              <p className="info specialization mb-1">
                                <span>
                                  {item.specialization &&
                                  item.specialization.length > 27
                                    ? `${item.specialization.slice(0, 27)}...`
                                    : item.specialization}
                                </span>
                              </p>
                            </div>
                            <Link
                              to={`/share/${item.type}/${item.id}`}
                              className="actionBtn btn action entity-share-btn d-flex align-items-center cursor-pointer"
                              title="review"
                            >
                              <Share />
                            </Link>
                          </div>
                        </div>
                        <Card.Body className="text-start flex-column d-flex justify-content-between">
                          <div className="content">
                            <StarRating rating={item.star_rating} />
                            <p className="info my-1 text-overflow-desc-sm">
                              <span>
                                {item.bio && item.bio.length > 27
                                  ? `${item.bio}`
                                  : item.bio}
                              </span>
                            </p>
                          </div>

                          <div className="itemEdit d-flex align-items-center justify-content-center flex-wrap gap-2 mt-sm-4 mt-3">
                            <Link
                              to={`/trainers/details/${item.id}`}
                              className="actionBtn btn action cursor-pointer"
                              title="trainers"
                            >
                              View Profile
                            </Link>
                            <Link
                              to={`/trainers/details/${item.id}?trainer=reviews`}
                              className="actionBtn btn action cursor-pointer"
                              title="review"
                            >
                              Reviews
                            </Link>
                            {role === "Admin" && (
                              <span className="d-flex gap-2">
                                <Link
                                  to={`/${entities}/edit/${item.id}`}
                                  className="actionBtn btn action cursor-pointer"
                                >
                                  Edit
                                </Link>
                                <Button
                                  href="#"
                                  className="actionDltBtn btn action cursor-pointer"
                                  onClick={() => deleteItem(item.id)}
                                >
                                  Delete
                                </Button>
                              </span>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No data Found</p>
                )}
              </>
            )}
          </Row>
          {!loading && !loadingFilter && displayedCards < items.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto "
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  const siteName = SubDomainRouter("name");

  return (
    <>
      <Helmet>
        <title>Trainers | {siteName}</title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
