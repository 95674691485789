const EmailFooter = (logo, title) => {
  const myHost = window.location.hostname;

  const htmlContent = `
                <!-- Footer -->
                <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
                    align="center">
                    <tr>
                        <td height="20"></td>
                    </tr>
                    <tr>
                        <td align="center">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td height="40" width="64" align="center">
                                        <a href="${myHost}/" target="_blank"
                                            style="text-decoration: none;">
                                            <img src="${logo}" 
                                                alt="${title}" loading="lazy" width="100%">
                                        </a>
                                    </td>
                                    <td width="12"></td>
                                    <td width="200" style="text-wrap: balance; font-size: 12px;" align="left" valign="center">
                                        ${title}
                                    </td>
                                    ${
                                      myHost === "ameerpetit.com"
                                        ? `<td width="280"
                                        style="text-wrap: balance; font-size: 12px; line-height:1.2; color: #333;"
                                        valign="center" align="right">
                                        <span>44790 S Grimmer Blvd, <br>
                                            Suite #103, Fremont CA 94538</span>
                                    </td>`
                                        : `
                                          <td width="280" style="text-wrap: balance; font-size: 12px; line-height:1.2;"
                                          valign="center" align="end">
                                          <a href="" style="color: #e02029;">
                                              Unsubscribe
                                          </a>
                                      </td>`
                                    }
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td height="16"></td>
                    </tr>
                   ${
                     myHost === "ameerpetit.com"
                       ? `
                    <tr>
                        <td>
                            <table table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td width="100" style="text-wrap: balance; font-size: 12px; line-height:1.2;"
                                        valign="top" align="center">
                                        <a href="" style="color: #e02029;">
                                            Unsubscribe
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    `
                       : ""
                   }
                    <tr>
                        <td height="12"></td>
                    </tr>
                    <tr>
                        <td align="center">
                            <span style="color: #666666; font-size: 12px;">&copy; 2023 AmeerpetIT.
                                All rights reserved.
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="20"></td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>`;
  return htmlContent;
};

export default EmailFooter;
