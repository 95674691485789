import React from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import "./form.css";

export default class Add_Team extends React.Component {
  entities = "teams";
  state = {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    country: "",
    state: "",
    city: "",
    linkedin: "",
    trainer_bio: "",
    years_experience: "",
    skillset_speciality: "",
    specialization: "",
    modeOfTraining: "",
    institutes_associated: "",
    corporate_experience: "",
    achievements: "",
    past_training_sessions: "",
    past_projects: "",
    proposed_plan_for_training: "",
    courses_offered: "",
    video_links: "",
    demo_className_slot: "",
    photo: "",
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const item = this.state;
    console.log(item);

    axios.post(`${Actions.BASE_URL}${this.entities}`, item).then((res) => {
      alert("data saved successfully!");
      window.location.href = "/" + this.entities;
      this.setState({
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        phone: "",
        whatsapp: "",
        website: "",
        country: "",
        state: "",
        city: "",
        linkedin: "",
        trainer_bio: "",
        years_experience: "",
        skillset_speciality: "",
        specialization: "",
        modeOfTraining: "",
        institutes_associated: "",
        corporate_experience: "",
        achievements: "",
        past_training_sessions: "",
        past_projects: "",
        proposed_plan_for_training: "",
        courses_offered: "",
        video_links: "",
        demo_className_slot: "",
        photo: "",
      });
    });
  };

  resetFields = () => {
    this.setState({
      first_name: "",
      last_name: "",
      name: "",
      email: "",
      phone: "",
      whatsapp: "",
      website: "",
      country: "",
      state: "",
      city: "",
      linkedin: "",
      trainer_bio: "",
      years_experience: "",
      skillset_speciality: "",
      specialization: "",
      modeOfTraining: "",
      institutes_associated: "",
      corporate_experience: "",
      achievements: "",
      past_training_sessions: "",
      past_projects: "",
      proposed_plan_for_training: "",
      courses_offered: "",
      video_links: "",
      demo_className_slot: "",
      photo: "",
    });
  };

  render() {
    return (
      <>
        <br />
        <br />
        <h1 className="heading">Add Trainer</h1>

        <div className="container">
          <form
            className="col-lg-10 row mt-4 form"
            onSubmit={this.handleSubmit}
          >
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  defaultValue={this.state.first_name}
                  onChange={(e) =>
                    this.setState({ first_name: e.target.value })
                  }
                ></input>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  defaultValue={this.state.id}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  defaultValue={this.state.last_name}
                  onChange={(e) => this.setState({ last_name: e.target.value })}
                ></input>
                <label htmlFor="last_name">Last Name</label>
              </div>
              {/* <div className='col-lg-12'>
              <input className='form-control' name="last_name" defaultValue={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })}></input>
            </div> */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="name"
                  name="name"
                  placeholder="Name"
                  defaultValue={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                ></input>
                <label htmlFor="name">Name</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="email"
                  name="email"
                  placeholder="Email"
                  defaultValue={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></input>
                <label htmlFor="email">Email</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  defaultValue={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                ></input>
                <label htmlFor="phone">Phone</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="whatsapp"
                  name="whatsapp"
                  placeholder="Whatsapp"
                  defaultValue={this.state.whatsapp}
                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                ></input>
                <label htmlFor="name">Whatsapp</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="website"
                  name="website"
                  placeholder="Website"
                  defaultValue={this.state.website}
                  onChange={(e) => this.setState({ website: e.target.value })}
                ></input>
                <label htmlFor="website">Website</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="country"
                  name="country"
                  placeholder="Country"
                  defaultValue={this.state.country}
                  onChange={(e) => this.setState({ country: e.target.value })}
                ></input>
                <label htmlFor="country">Country</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="state"
                  name="state"
                  placeholder="State"
                  defaultValue={this.state.state}
                  onChange={(e) => this.setState({ state: e.target.value })}
                ></input>
                <label htmlFor="state">State</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="city"
                  name="city"
                  placeholder="City"
                  defaultValue={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                ></input>
                <label htmlFor="city">City</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="linkedin"
                  name="linkedin"
                  placeholder="LinkedIn"
                  defaultValue={this.state.linkedin}
                  onChange={(e) => this.setState({ linkedin: e.target.value })}
                ></input>
                <label htmlFor="linkedin">LinkedIn</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="years_experience"
                  name="years_experience"
                  placeholder="Years of Experience"
                  defaultValue={this.state.years_experience}
                  onChange={(e) =>
                    this.setState({ years_experience: e.target.value })
                  }
                ></input>
                <label htmlFor="years_experience">Years of Experiencce</label>
              </div>
            </div>

            <div className="col-lg-12 col-md-6">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="trainer_bio"
                  name="trainer_bio"
                  placeholder="Trainer Bio"
                  defaultValue={this.state.trainer_bio}
                  onChange={(e) =>
                    this.setState({ trainer_bio: e.target.value })
                  }
                ></input>
                <label htmlFor="trainer_bio">Trainer Bio</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="skillset_speciality"
                  name="skillset_speciality"
                  placeholder="Skillset/Speciality"
                  defaultValue={this.state.skillset_speciality}
                  onChange={(e) =>
                    this.setState({ skillset_speciality: e.target.value })
                  }
                ></input>
                <label htmlFor="skillset_speciality">Skillset/Speciality</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="specialization"
                  name="specialization"
                  placeholder="Specialization"
                  defaultValue={this.state.specialization}
                  onChange={(e) =>
                    this.setState({ specialization: e.target.value })
                  }
                ></input>
                <label htmlFor="specialization">Specialization</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="modeOfTraining"
                  name="modeOfTraining"
                  placeholder="Mode of Training"
                  defaultValue={this.state.modeOfTraining}
                  onChange={(e) =>
                    this.setState({ modeOfTraining: e.target.value })
                  }
                ></input>
                <label htmlFor="modeOfTraining">Mode of Training</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="institutes_associated"
                  name="institutes_associated"
                  placeholder="Institutes Associated"
                  defaultValue={this.state.institutes_associated}
                  onChange={(e) =>
                    this.setState({ institutes_associated: e.target.value })
                  }
                ></input>
                <label htmlFor="institutes_associates">
                  Institutes Associated
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="corporate_experience"
                  name="corporate_experience"
                  placeholder="Corporate Experience"
                  defaultValue={this.state.corporate_experience}
                  onChange={(e) =>
                    this.setState({ corporate_experience: e.target.value })
                  }
                ></input>
                <label htmlFor="corporate_experience">
                  Corporate Experience
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="achievements"
                  name="achievements"
                  placeholder="Achievements"
                  defaultValue={this.state.achievements}
                  onChange={(e) =>
                    this.setState({ achievements: e.target.value })
                  }
                ></input>
                <label htmlFor="achievements">Achievements</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="past_training_sessions"
                  name="past_training_sessions"
                  placeholder="Past Training Sessions"
                  defaultValue={this.state.past_training_sessions}
                  onChange={(e) =>
                    this.setState({ past_training_sessions: e.target.value })
                  }
                ></input>
                <label htmlFor="past_training_sessions">
                  Past Training Sessions
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="past_projects"
                  name="past_projects"
                  placeholder="Past Projects"
                  defaultValue={this.state.past_training_sessions}
                  onChange={(e) =>
                    this.setState({ past_training_sessions: e.target.value })
                  }
                ></input>
                <label htmlFor="past_projects">Past Projects</label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="proposed_plan_for_training"
                  name="proposed_plan_for_training"
                  placeholder="Proposed Plan for Training"
                  defaultValue={this.state.proposed_plan_for_training}
                  onChange={(e) =>
                    this.setState({
                      proposed_plan_for_training: e.target.value,
                    })
                  }
                ></input>
                <label htmlFor="proposed_plan_for_training">
                  Proposed Plan for Training
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="courses_offered"
                  name="courses_offered"
                  placeholder="Courses Offered"
                  defaultValue={this.state.courses_offered}
                  onChange={(e) =>
                    this.setState({ courses_offered: e.target.value })
                  }
                ></input>
                <label htmlFor="proposed_plan_for_training">
                  Courses Offered
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="video_links"
                  name="video_links"
                  placeholder="Video Links"
                  defaultValue={this.state.video_links}
                  onChange={(e) =>
                    this.setState({ video_links: e.target.value })
                  }
                ></input>
                <label htmlFor="proposed_plan_for_training">Video Links</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="demo_className_slot"
                  name="demo_className_slot"
                  placeholder="Demo Class Name & Slot"
                  defaultValue={this.state.demo_className_slot}
                  onChange={(e) =>
                    this.setState({ demo_className_slot: e.target.value })
                  }
                ></input>
                <label htmlFor="demo_className_slot">
                  Demo Class Name & Slot
                </label>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="form-floating formfloating mb-3">
                <input
                  className="form-control fromcontrol"
                  id="photo"
                  name="photo"
                  placeholder="Phone"
                  defaultValue={this.state.photo}
                  onChange={(e) => this.setState({ photo: e.target.value })}
                ></input>
                <label htmlFor="photo">Photo</label>
              </div>
              {this.state.photo_preview ? (
                <img
                  className="mt-2"
                  src={this.state.photo_preview}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                ></img>
              ) : (
                <img
                  className="mt-2"
                  src={this.state.photo_url}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                ></img>
              )}
            </div>
            <div className="form-group text-center col-lg-12">
              <button type="submit" className="btn button2 mt-3">
                {this.state.id ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </div>

        <br />
        <br />
      </>
    );
  }
}
