import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Search, X } from "react-bootstrap-icons";
import "./SearchBar.css";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

function SearchBar({ setSearchQuery }) {
  const [searchedTerm, setSearchedTerm] = useState("");

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchedTerm(term);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (searchedTerm.trim() !== "") setSearchQuery(searchedTerm);
  };
  const handleClear = (event) => {
    event.preventDefault();
    setSearchedTerm("");
    setSearchQuery("");
  };

  const currentDomain = window.location.hostname;

  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  return (
    <Form onSubmit={handleClick} className="form-container">
      <InputGroup className="mb-3 inputGroup w-100 rounded-pill">
        <Form.Control
          className="py-0"
          value={searchedTerm}
          onChange={handleInputChange}
          placeholder={
            currentDomain === "events.indianstartups.us" ||
            currentDomain === "events.indianstartups.com"
              ? "Search Events"
              : `Search for ${demosAlias}, ${coursesAlias}...`
          }
        />
        {searchedTerm !== "" && (
          <Button
            className="bg-transparent p-0 m-0 border-0 me-sm-2 w-fit"
            onClick={handleClear}
          >
            <X className="fs-4" />
          </Button>
        )}
        <InputGroup.Text type="button" id="search" onClick={handleClick}>
          <Search />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}

export default SearchBar;
