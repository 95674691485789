import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
  Toast,
} from "react-bootstrap";
import { Clock, XCircleFill } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import VideoUploaderEdit from "../../components/VideoUploader/VideoUploaderEdit ";
import AddTag from "../../components/tagInput/tagInput";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import BatchForm from "../../components/batchForm/batchForm";
import ConvertMinutesToTime from "../../components/convertFromMinutes/convertFromMinutes  ";
import DynamicRelationFieldEdit from "../../components/dynamicRelationFieldSet/dynamicRelationFieldEdit";
import DynamicFieldEdit from "../../components/dynamicUrlField/dynamicUrlFieldEdit";
import DynamicFileUploadEdit from "../../components/fileUploadFieldSet/fileUploadEdit";
import CustomLoader from "../../components/loader/loader";
import DynamicLocationEdit from "../../components/locationFieldSet/locationFieldSetEdit";
import ScheduleDateModal from "../../components/scheduleDateModal/scheduleDateModal";
import "./edit.css";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import { useNavigate } from "react-router-dom";
import API from "../../components/API/API";
function EditDemo(props) {
  const navigate = useNavigate();
  const entities = "demos";

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);

  const [demoTypesData, setDemoTypesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [countryTypesData, setCountryTypesData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [courseNamesData, setCourseNamesData] = useState([]);
  const [step, setStep] = useState(1);
  const [starRatings, setStarRatings] = useState([]);
  const [formData, setFormData] = useState({
    userId: myUserId,
    type: "demo",
    entity_type: "demo",
    tags: [],
    entityRelation: [],
    social_links: {},
    attachments: {},
    locations: [],
    approval_status: "",
    country: "",
    category: "",
    subcategory: "",
    subcategorylevel1: "",
    subcategorylevel2: "",
    comments: "",
    title: "",
    start_date: "",
    start_time: "",
    duration: "",
    description: "",
    meeting_id: "",
    rsvp_redirect_url: "",
    video_url: "",
    share_link: "",
    online_join_link: "",
    star_rating_type: "",
    instructor_first_name: "",
    instructor_last_name: "",
    instructor_photo_url: "",
    next_follow_url: "",
    instructor_email: "",
    instructor_phone: "",
    instructor_whatsapp: "",
    instructor_bio: "",
    organizer_first_name: "",
    organizer_last_name: "",
    organizer_company_name: "",
    organizer_phone: "",
    organizer_whatsapp: "",
    organizer_email: "",
    organizer_website: "",
    organizer_alias_name: "",
    organizer_logo_url: "",
    sponsor_alias_name: "",
    sponsor_company_name: "",
    sponsor_email: "",
    sponsor_first_name: "",
    sponsor_last_name: "",
    sponsor_logo_url: "",
    sponsor_phone: "",
    sponsor_website: "",
    sponsor_whatsapp: "",
    listing_image_url: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
  });
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [addedTags, setAddedTags] = useState([]);

  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [instructorSameAsOrganizer, setInstructorSameAsOrganizer] =
    useState(false);

  const [batchData, setBatchData] = useState([]);
  const [relationData, setRelationData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [refLinksData, setRefLinksData] = useState([]);
  // Schedule modal
  const [scheduleModalShow, setScheduleModalShow] = useState(false);

  const attachmentFields = [
    {
      content: "",
      extension: "",
      field_name: "",
      url: "",
    },
  ];
  const batchFields = [
    {
      courseName: "",
      date: "",
      startTime: "",
      duration: "",
      teaching_mode: "",
      trainer: "",
    },
  ];

  const populateList = async (id) => {
    try {
      const response = await fetch(`${Actions.BASE_URL}demos/${id}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setAddedTags(data?.tags);

      setBatchData(data?.batches || batchFields);

      setRelationData(data?.entityRelation || [{ type: "", value: "" }]);
      setSocialData(data?.social_links || [{ type: "", value: "" }]);
      setLocationData(data?.locations || [{ city: "", address: "" }]);

      setImageData(data?.attachments?.images || attachmentFields);
      setDocumentData(data?.attachments?.documents || attachmentFields);
      setVideoData(data?.attachments?.videos || attachmentFields);
      setRefLinksData(data?.attachments?.links || attachmentFields);

      setFormData({
        ...data,
      });
      setLoading(false);
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
    //
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const addedAttachments = {
      documents: documentData,
      images: imageData,
      videos: videoData,
      links: refLinksData,
    };
    const data = {
      ...formData,
      tags: addedTags,
      batches: batchData,
      attachments: addedAttachments,
      entityRelation: relationData,
      locations: locationData,
      social_links: socialData,
      amount: formData.amount,
    };

    axios
      .put(`${Actions.BASE_URL}${entities}/${data.id}`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData({
          type: "demo",
          entity_type: "demo",
          tags: [],
          entityRelation: [],
          social_links: {},
          attachments: {},
          locations: [],
          approval_status: "",
          category: "",
          subcategory: "",
          subcategorylevel1: "",
          subcategorylevel2: "",
          comments: "",
          title: "",
          start_date: "",
          start_time: "",
          duration: "",
          description: "",
          meeting_id: "",
          rsvp_redirect_url: "",
          video_url: "",
          share_link: "",
          online_join_link: "",
          star_rating_type: "",
          instructor_first_name: "",
          instructor_last_name: "",
          instructor_photo_url: "",
          next_follow_url: "",
          instructor_email: "",
          instructor_phone: "",
          instructor_whatsapp: "",
          instructor_bio: "",
          organizer_first_name: "",
          organizer_last_name: "",
          organizer_company_name: "",
          organizer_phone: "",
          organizer_whatsapp: "",
          organizer_email: "",
          organizer_website: "",
          organizer_alias_name: "",
          organizer_logo_url: "",
          sponsor_alias_name: "",
          sponsor_company_name: "",
          sponsor_email: "",
          sponsor_first_name: "",
          sponsor_last_name: "",
          sponsor_logo_url: "",
          sponsor_phone: "",
          sponsor_website: "",
          sponsor_whatsapp: "",
          listing_image_url: "",
          listing_image_content: "",
          listing_image_extension: "",
          hero_image_url: "",
          hero_image_content: "",
          hero_image_extension: "",
        });
        navigate(`/${entities}/`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("API request failed:", error);
      });
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const titleInputRef = useRef(null);

  useEffect(() => {
    populateList(props.params.id);
    fetchFilterTypes();

    titleInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params.id]);

  // Category type fetched from ENUM
  const fetchFilterTypes = async () => {
    try {
      const data = await API("get", "configs");
      const categorySettings = data?.categorysettings || {};

      const countryTypeObj = categorySettings.countryTypes || {};
      const coursesObj = categorySettings.courseNames || {};
      const teachingModeObj = categorySettings.teachingModes || {};
      const paymentTypesObj = categorySettings.paymentTypes || {};
      const subcategoryTypesObj = categorySettings.subcategoryTypes || {};
      const starRatingsObj = categorySettings.starRatings || {};

      const mapToObject = (sourceObj) =>
        Object.entries(sourceObj).reduce((acc, [key, value]) => {
          acc[key] = {
            key: key,
            value: value,
          };
          return acc;
        }, {});

      setCountryTypesData(mapToObject(countryTypeObj));
      setCourseNamesData(mapToObject(coursesObj));
      setDemoTypesData(mapToObject(teachingModeObj));
      setCategoriesData(mapToObject(paymentTypesObj));
      setSubCategoryData(mapToObject(subcategoryTypesObj));
      setStarRatings(mapToObject(starRatingsObj));
    } catch (error) {
      console.error("Error fetching filter types:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleCheckboxChange = () => {
    setInstructorSameAsOrganizer(!instructorSameAsOrganizer);
  };
  const organizerFirstNameRef = useRef(null);
  const organizerLastNameRef = useRef(null);
  const organizerEmailRef = useRef(null);
  const organizerPhoneRef = useRef(null);
  const organizerWhatsappRef = useRef(null);

  useEffect(() => {
    if (instructorSameAsOrganizer) {
      const updateState = {
        ...formData,
        organizer_first_name: formData.instructor_first_name,
        organizer_last_name: formData.instructor_last_name,
        organizer_email: formData.instructor_email,
        organizer_phone: formData.instructor_phone,
        organizer_whatsapp: formData.instructor_whatsapp,
      };

      setFormData(updateState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructorSameAsOrganizer]);

  const instructorDetails = () => {
    return (
      formData.instructor_first_name &&
      formData.instructor_last_name &&
      formData.instructor_email &&
      formData.instructor_phone
    );
  };

  const addField = (setField, field) => {
    setField([...field, {}]);
  };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };
  useEffect(() => {
    if (locationData.length === 0) {
      setLocationData([{ city: "", address: "" }]);
    }
    if (relationData.length === 0) {
      setRelationData([{ type: "", id: "" }]);
    }
    if (socialData.length === 0) {
      setSocialData([{ type: "", value: "" }]);
    }
  }, [locationData, relationData, socialData]);

  // Timezone user
  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  let localTime = "";
  if (formData.start_time && selectedTimeZone.value) {
    const [utcHours, utcMinutes] = formData?.start_time?.split(":").map(Number);

    const localDate = new Date();
    localDate?.setUTCHours(utcHours, utcMinutes);

    const localHours = localDate?.getHours();
    const localMinutes = localDate?.getMinutes();

    const period = localHours >= 12 ? "PM" : "AM";
    localTime = `${localHours % 12 || 12}:${localMinutes
      .toString()
      .padStart(2, "0")} ${period}`;
  }

  const DemoAlias = SubDomainRouter("demoAlias");

  return (
    <Container fluid="lg" className="demosEditSection">
      {loading ? (
        <CustomLoader />
      ) : (
        <Row>
          <Form action="" role="form" onSubmit={handleSubmit}>
            <h1 className="text-center text-capitalize">Edit {DemoAlias}</h1>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <p>Fill all form fields to go to the next step</p>
              <ul className="demosEditList">
                <li
                  className={
                    step === 1
                      ? "active stepstriggers"
                      : "stepstriggers cursor-pointer"
                  }
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </li>
                <li
                  className={
                    step === 2
                      ? "active stepstriggers"
                      : "stepstriggers cursor-pointer"
                  }
                  onClick={() => setStep(2)}
                >
                  <span>2</span>
                </li>
                <li
                  className={
                    step === 3
                      ? "active stepstriggers"
                      : "stepstriggers cursor-pointer"
                  }
                  onClick={() => setStep(3)}
                >
                  <span>3</span>
                </li>
                <li
                  className={
                    step === 4
                      ? "active stepstriggers"
                      : "stepstriggers cursor-pointer"
                  }
                  onClick={() => setStep(4)}
                >
                  <span>4</span>
                </li>
                <li
                  className={
                    step === 5
                      ? "active stepstriggers"
                      : "stepstriggers cursor-pointer"
                  }
                  onClick={() => setStep(5)}
                >
                  <span>5</span>
                </li>
              </ul>
            </div>

            {step === 1 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="form-control italicInput"
                        id="title"
                        required
                        value={formData.title}
                        placeholder="Enter Event Title"
                        name="title"
                        ref={titleInputRef}
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="title">
                        Title
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  {/* Data Append from schedule popup */}
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3 position-relative">
                      <Form.Control
                        type="button"
                        value={formData.start_date || "YYYY/MM/DD"}
                        required
                        name="start_date"
                        id="start_date"
                        onClick={() => setScheduleModalShow(true)}
                      />
                      <Form.Label className="form-label" htmlFor="start_date">
                        Start Date
                      </Form.Label>
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        autohide
                        delay={3000}
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: 0,
                          zIndex: "99",
                        }}
                      >
                        <Toast.Header closeButton={false}>
                          <strong className="mr-auto text-danger">
                            <Clock />
                            &nbsp;Error
                          </strong>
                        </Toast.Header>
                        <Toast.Body>
                          Invalid date. Please select a date not earlier than
                          today.
                        </Toast.Body>
                      </Toast>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        id="duration"
                        required
                        value={
                          formData.duration
                            ? ConvertMinutesToTime({
                                minutes: formData.duration,
                              })
                            : "Select Your Event Duration"
                        }
                        name="duration"
                        onClick={() => setScheduleModalShow(true)}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        required
                        onClick={() => setScheduleModalShow(true)}
                        name="start_time"
                        value={localTime ? localTime : "Select Your Event Time"}
                        id="start_time"
                      />
                      <Form.Label className="form-label" htmlFor="start_time">
                        Event Time
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <div className="form-floating mb-3">
                      <Form.Control
                        type="button"
                        className="timezone px-2 z-3"
                        required
                        onClick={() => setScheduleModalShow(true)}
                        id="timezone"
                        value={
                          selectedTimeZone?.value || "Select Your Timezone"
                        }
                        placeholder="Select your time zone"
                      />
                    </div>
                  </Col>
                  {/* Data Append from schedule popup */}

                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Select
                        id="country"
                        className={`form-control form-select text-capitalize`}
                        value={formData.country}
                        name="country"
                        onChange={(e) =>
                          handleFieldChange("country", e.target.value)
                        }
                      >
                        {Object.keys(countryTypesData).map((paramType) => (
                          <option
                            key={countryTypesData[paramType]?.key}
                            value={countryTypesData[paramType]?.value}
                          >
                            {countryTypesData[paramType]?.value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Label className="form-label" htmlFor="country">
                        Country
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control"
                        rows={8}
                        name="description"
                        value={formData.description}
                        id="description"
                        placeholder="Enter your event description"
                        onChange={(e) =>
                          handleFieldChange("description", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="description">
                        Description
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      label="Big Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="hero_image"
                      placehold="https://placehold.co/1024x200"
                      state={formData}
                      setState={setFormData}
                      imageURL={formData.hero_image_url}
                      imageContent={formData.hero_image_content}
                      imageExtension={formData.hero_image_extension}
                    />
                  </Col>

                  <Col md={6}>
                    <BannerUploaderEdit
                      label="Small Banner"
                      accept="image/png, image/jpg, image/jpeg"
                      imageType="listing_image"
                      placehold="https://placehold.co/280x180"
                      state={formData}
                      setState={setFormData}
                      imageURL={formData.listing_image_url}
                      imageContent={formData.listing_image_content}
                      imageExtension={formData.listing_image_extension}
                    />
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        id="video_url"
                        name="video_url"
                        value={formData.video_url}
                        type="text"
                        placeholder="Video Url"
                        className="form-control input-md italicInput"
                        onChange={(e) =>
                          handleFieldChange("video_url", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="video_url">
                        Video Url
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <select
                        id="category"
                        className="form-control form-select"
                        required
                        value={formData.category}
                        name="category"
                        onChange={(e) =>
                          handleFieldChange("category", e.target.value)
                        }
                      >
                        <option value="">Course</option>
                        {Object.keys(courseNamesData).map((paramType) => (
                          <option
                            key={courseNamesData[paramType]?.key}
                            value={courseNamesData[paramType]?.value}
                          >
                            {courseNamesData[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <select
                        id="subcategory"
                        className="form-control form-select"
                        value={formData.subcategory}
                        required
                        name="subcategory"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategory", e.target.value)
                        }
                      >
                        <option value="">Learning Level</option>
                        {Object.keys(subCategoryData).map((paramType) => (
                          <option
                            key={subCategoryData[paramType]?.key}
                            value={subCategoryData[paramType]?.value}
                          >
                            {subCategoryData[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating  mb-3">
                      <select
                        id="subcategorylevel1"
                        className="form-control form-select"
                        required
                        value={formData.subcategorylevel1}
                        name="subcategorylevel1"
                        multiple=""
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel1", e.target.value)
                        }
                      >
                        <option value="">Select Payment Option</option>
                        {Object.keys(categoriesData).map((paramType) => (
                          <option
                            key={categoriesData[paramType]?.key}
                            value={categoriesData[paramType]?.value}
                          >
                            {categoriesData[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <select
                        className="form-select form-control"
                        aria-label="event type"
                        required
                        value={formData.subcategorylevel2}
                        id="subcategorylevel2"
                        name="subcategorylevel2"
                        onChange={(e) =>
                          handleFieldChange("subcategorylevel2", e.target.value)
                        }
                      >
                        <option value="">Teaching Mode</option>
                        {Object.keys(demoTypesData).map((paramType) => (
                          <option
                            key={demoTypesData[paramType]?.key}
                            value={demoTypesData[paramType]?.value}
                          >
                            {demoTypesData[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </Form.Group>
                  </Col>
                  {formData.subcategorylevel1 === "paid" && (
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <div className="d-flex gap-3">
                        <Form.Group className="form-floating w-25 mb-3">
                          <select
                            id="currency"
                            required
                            className="form-control form-select text-uppercase"
                            name="currency"
                            value={formData.currency}
                            onChange={(e) =>
                              handleFieldChange("currency", e.target.value)
                            }
                          >
                            <option value="">Currency</option>
                            <option value="usd">usd</option>
                            <option value="cad">cad</option>
                            <option value="inr">inr</option>
                            <option value="eur">eur</option>
                            <option value="gbp">gbp</option>
                          </select>
                        </Form.Group>
                        <Form.Group className="form-floating mb-3 flex-grow-1">
                          <Form.Control
                            id="amount"
                            name="amount"
                            type="number"
                            required
                            value={formData.amount}
                            onChange={(e) =>
                              handleFieldChange("amount", e.target.value)
                            }
                            placeholder="Amount"
                            className="form-control input-md italicInput"
                          />
                          <Form.Label className="form-label" htmlFor="amount">
                            Enter Amount
                          </Form.Label>
                          <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                            .00
                          </span>
                        </Form.Group>
                      </div>
                    </Col>
                  )}

                  <Col md={6}>
                    <div className="form-floating formfloating mb-3">
                      <select
                        className="form-control form-select fromcontrol"
                        id="star_rating_type"
                        value={formData.star_rating_type}
                        name="star_rating_type"
                        onChange={(e) =>
                          handleFieldChange("star_rating_type", e.target.value)
                        }
                      >
                        <option value="">How Do You Rate This Session?</option>
                        {Object.keys(starRatings).map((paramType) => (
                          <option
                            key={starRatings[paramType]?.key}
                            value={starRatings[paramType]?.value}
                          >
                            {starRatings[paramType]?.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <AddTag
                      label={"Tags"}
                      addedTags={addedTags}
                      setAddedTags={setAddedTags}
                    />
                  </Col>
                  <Col ms={12} className="mt-3">
                    <Button
                      type="button"
                      className="next demosEditBtn "
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 2 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col sm={12} className="form-section-header mt-4 mb-3">
                    <h4 className="theme-text-2">Instructor Details</h4>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className="form-control italicInput"
                        name="instructor_first_name"
                        value={formData.instructor_first_name}
                        id="instructor_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_first_name"
                      >
                        First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="form-control italicInput"
                        value={formData.instructor_last_name}
                        name="instructor_last_name"
                        id="instructor_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_last_name"
                      >
                        Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Phone #"
                        className="form-control italicInput"
                        name="instructor_phone"
                        value={formData.instructor_phone}
                        id="instructor_phone"
                        onChange={(e) =>
                          handleFieldChange("instructor_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_phone"
                      >
                        Phone #
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="WhatsApp"
                        className="form-control italicInput"
                        value={formData.instructor_whatsapp}
                        name="instructor_whatsapp"
                        id="instructor_whatsapp"
                        onChange={(e) =>
                          handleFieldChange(
                            "instructor_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_whatsapp"
                      >
                        WhatsApp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="email"
                        className="form-control italicInput"
                        name="instructor_email"
                        value={formData.instructor_email}
                        id="instructor_email"
                        onChange={(e) =>
                          handleFieldChange("instructor_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_email"
                      >
                        Email Address
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        className="w-100 form-control max-h-auto"
                        rows="4"
                        name="instructor_bio"
                        value={formData.instructor_bio}
                        id="instructor_bio"
                        placeholder="Enter Bio of Organization"
                        onChange={(e) =>
                          handleFieldChange("instructor_bio", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="instructor_bio"
                      >
                        Instructor's Brief Bio
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mx-0">
                  <div className="form-section-header mt-4 mb-3">
                    <hr />
                    <h4 className="theme-text-2">Organizer Details</h4>
                  </div>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_first_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_first_name"
                        ref={organizerFirstNameRef}
                        value={
                          instructorSameAsOrganizer
                            ? formData.instructor_first_name
                            : formData.organizer_first_name
                        }
                        id="organizer_first_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_first_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_first_name"
                      >
                        Organizer First Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_last_name"
                        placeholder="Organizer Name"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerLastNameRef}
                        name="organizer_last_name"
                        value={
                          instructorSameAsOrganizer
                            ? formData.instructor_last_name
                            : formData.organizer_last_name
                        }
                        id="organizer_last_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_last_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_last_name"
                      >
                        Organizer Last Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="organizer_company_name"
                        placeholder="Organizer Company"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_company_name"
                        value={formData.organizer_company_name}
                        id="organizer_company_name"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_company_name",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_company_name"
                      >
                        Organizer Company Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Organizer Email"
                        className="form-control italicInput"
                        disabled={instructorSameAsOrganizer ? true : false}
                        ref={organizerEmailRef}
                        name="organizer_email"
                        value={
                          instructorSameAsOrganizer
                            ? formData.instructor_email
                            : formData.organizer_email
                        }
                        id="organizer_email"
                        onChange={(e) =>
                          handleFieldChange("organizer_email", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_email"
                      >
                        Organizer Email:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        className="form-control"
                        name="whatsapp"
                        id="organizer_whatsapp"
                        ref={organizerWhatsappRef}
                        placeholder="organizer_whatsapp"
                        disabled={instructorSameAsOrganizer ? true : false}
                        value={
                          instructorSameAsOrganizer
                            ? formData.instructor_whatsapp
                            : formData.organizer_whatsapp
                        }
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange(
                            "organizer_whatsapp",
                            e.target.value
                          )
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_whatsapp"
                      >
                        Organizer Whatsapp
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Phone"
                        className="form-control"
                        name="organizer_phone"
                        value={
                          instructorSameAsOrganizer
                            ? formData.instructor_phone
                            : formData.organizer_phone
                        }
                        id="organizer_phone"
                        ref={organizerPhoneRef}
                        disabled={instructorSameAsOrganizer ? true : false}
                        maxLength="15"
                        minLength="10"
                        onChange={(e) =>
                          handleFieldChange("organizer_phone", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_phone"
                      >
                        Organizer Phone
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="Website"
                        value={formData.organizer_website}
                        className="form-control"
                        disabled={instructorSameAsOrganizer ? true : false}
                        name="organizer_website"
                        id="organizer_website"
                        onChange={(e) =>
                          handleFieldChange("organizer_website", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="organizer_website"
                      >
                        Organizer Website
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <div className="custom-checkbox form-check ps-0">
                      <label>
                        <input
                          disabled={!instructorDetails()}
                          type="checkbox"
                          className="form-check-input"
                          checked={instructorSameAsOrganizer}
                          onChange={handleCheckboxChange}
                        />
                        <span className="form-check-label">
                          Same as Instructor
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0" className="border-0">
                    <Accordion.Header className="theme-text-2">
                      <h4 className="theme-text-2">Sponsor Details</h4>
                    </Accordion.Header>
                    <Accordion.Body className="px-0">
                      <Row className="mx-0">
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_first_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              value={formData.sponsor_first_name}
                              name="sponsor_first_name"
                              id="sponsor_first_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_first_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_first_name"
                            >
                              Sponsor First Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_last_name"
                              placeholder="Sponsor Name"
                              className="form-control italicInput"
                              value={formData.sponsor_last_name}
                              name="sponsor_last_name"
                              id="sponsor_last_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_last_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_last_name"
                            >
                              Sponsor Last Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_company_name"
                              placeholder="Sponsor Company"
                              className="form-control italicInput"
                              value={formData.sponsor_company_name}
                              name="sponsor_company_name"
                              id="sponsor_company_name"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_company_name",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_company_name"
                            >
                              Sponsor Company Name
                            </Form.Label>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="sponsor_logo_url"
                              placeholder="Sponsor LOGO"
                              className="form-control italicInput"
                              value={formData.sponsor_logo_url}
                              name="sponsor_logo_url"
                              id="sponsor_logo_url"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_logo_url",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_logo_url"
                            >
                              Sponsor Logo URL
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="email"
                              placeholder="Sponsor Email"
                              className="form-control italicInput"
                              value={formData.sponsor_email}
                              name="sponsor_email"
                              id="sponsor_email"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_email",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_email"
                            >
                              Sponsor Email:
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              className="form-control"
                              name="whatsapp"
                              value={formData.sponsor_whatsapp}
                              id="sponsor_whatsapp"
                              placeholder="sponsor_whatsapp"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_whatsapp",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_whatsapp"
                            >
                              Sponsor Whatsapp
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="number"
                              placeholder="Phone"
                              className="form-control"
                              value={formData.sponsor_phone}
                              name="sponsor_phone"
                              id="sponsor_phone"
                              maxLength="15"
                              minLength="10"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_phone",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_phone"
                            >
                              Sponsor Phone
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-floating mb-3">
                            <Form.Control
                              type="url"
                              placeholder="Website"
                              value={formData.sponsor_website}
                              className="form-control"
                              name="sponsor_website"
                              id="sponsor_website"
                              onChange={(e) =>
                                handleFieldChange(
                                  "sponsor_website",
                                  e.target.value
                                )
                              }
                            />
                            <Form.Label
                              className="form-label"
                              htmlFor="sponsor_website"
                            >
                              Sponsor Website
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Row className="mx-0">
                  <Col ms={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 3 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2">Batch Details</h5>
                    <p>Please add any new batches starting soon.</p>
                  </Col>
                  <Col md={12}>
                    {batchData?.map((field, index) => (
                      <React.Fragment key={index}>
                        <BatchForm
                          key={index}
                          field={field}
                          onFieldChange={(newField) => {
                            const updatedFields = [...batchData];
                            updatedFields[index] = newField;
                            setBatchData(updatedFields);
                          }}
                          indexField={index}
                          onRemove={() =>
                            removeItemById(batchData, setBatchData, field.id)
                          }
                          inputIdPrefix={index}
                        />
                        <hr />
                      </React.Fragment>
                    ))}
                    <Button
                      className="clickable add-button mb-3 ms-auto d-flex"
                      onClick={() => addField(setBatchData, batchData)}
                    >
                      Add More Batches
                    </Button>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 4 && (
              <fieldset className="wizard-fieldset show demosAddForm">
                <Row className="mx-0">
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="url"
                        placeholder="https://zoom.us/"
                        className="form-control italicInput wizard-form-error"
                        name="online_join_link"
                        id="online_join_link"
                        onChange={(e) =>
                          handleFieldChange("online_join_link", e.target.value)
                        }
                        disabled
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="online_join_link"
                      >
                        Add your own event link
                      </Form.Label>
                      <span className="theme-text-2 d-block">
                        <small>*Only Premium Users Can Add Custom Link</small>
                      </span>
                    </Form.Group>
                  </Col>
                  <hr />
                  <Col md={12} className="d-flex justify-content-between gap-3">
                    <h6 className="theme-text-2 me-sm-3 mb-3 flex-grow-1">
                      Do you have any Courses, Trainers, Institutes or Reviews
                      associated with this {DemoAlias} that you already have on
                      this website?
                    </h6>
                    <Button
                      className="clickable addStyle mb-3 ms-auto d-flex"
                      onClick={() => {
                        const newField = { type: "", value: "" };
                        setRelationData([...relationData, newField]);
                      }}
                    >
                      <span className="addBttn d-block">+</span>
                    </Button>
                  </Col>
                  <Col md={12}>
                    {relationData?.map((field, index) => (
                      <DynamicRelationFieldEdit
                        key={index}
                        fields={relationData}
                        field={field}
                        index={index}
                        onFieldChange={(newFields) => {
                          setRelationData(newFields);
                        }}
                        fieldLabels={{
                          valueInput: "Start typing",
                        }}
                        selectOptions={[
                          { value: "demo", label: "demo" },
                          { value: "course", label: "course" },
                          { value: "trainer", label: "trainer" },
                          { value: "institute", label: "institute" },
                          { value: "review", label: "review" },
                        ]}
                      />
                    ))}
                  </Col>
                  <hr />
                  <Col md={12} className="d-flex justify-content-between gap-3">
                    <h6 className="theme-text-2 flex-grow-1">Social Links</h6>
                    <Button
                      className="clickable addStyle mb-3 ms-auto d-flex"
                      onClick={() => {
                        const newField = { type: "", value: "" };
                        setSocialData([...socialData, newField]);
                      }}
                    >
                      <span className="addBttn d-block">+</span>
                    </Button>
                  </Col>
                  <Col md={12}>
                    {socialData?.map((field, index) => (
                      <DynamicRelationFieldEdit
                        key={index}
                        fields={socialData}
                        field={field}
                        index={index}
                        onFieldChange={(newFields) => {
                          setSocialData(newFields);
                        }}
                        fieldLabels={{
                          valueInput: "Social Link",
                        }}
                        selectOptions={[
                          { value: "facebook", label: "Facebook" },
                          { value: "instagram", label: "Instagram" },
                          { value: "twitter", label: "Twitter" },
                          { value: "linkedIn", label: "LinkedIn" },
                          { value: "website", label: "Website" },
                          { value: "others", label: "Others" },
                        ]}
                      />
                    ))}
                  </Col>
                  <Col md={12} className="d-flex justify-content-between gap-3">
                    <h6 className="theme-text-2 flex-grow-1">
                      Location / Venue
                    </h6>
                    <Button
                      className="clickable addStyle mb-3 ms-auto d-flex"
                      onClick={() => {
                        const newField = { city: "", address: "" };
                        setLocationData([...locationData, newField]);
                      }}
                    >
                      <span className="addBttn d-block">+</span>
                    </Button>
                  </Col>
                  <Col md={12}>
                    {locationData?.map((field, index) => (
                      <DynamicLocationEdit
                        key={index}
                        fields={locationData}
                        index={index}
                        onFieldChange={(newFields) => {
                          setLocationData(newFields);
                        }}
                      />
                    ))}
                  </Col>

                  <Col md={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            )}

            {step === 5 && (
              <fieldset className="wizard-fieldset show demosAddForm demosViewMoreSection">
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text-2 mb-3">
                      Add any documents, brochures, fliers, images, videos and
                      links related to the demo below
                    </h5>
                  </Col>
                  <Col md={12}>
                    <Tabs
                      defaultActiveKey="documents"
                      id="demo-details"
                      className="mx-0 mt-2"
                      fill
                    >
                      <Tab
                        eventKey="documents"
                        title="Docs"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body>
                            {documentData?.map((field, index) => (
                              <DynamicFileUploadEdit
                                key={field.id}
                                field={field}
                                onFieldChange={(newField) => {
                                  const updatedFields = [...documentData];
                                  updatedFields[index] = newField;
                                  setDocumentData(updatedFields);
                                }}
                                onRemove={() =>
                                  removeItemById(
                                    documentData,
                                    setDocumentData,
                                    field.id
                                  )
                                }
                                fileCategory="document"
                                acceptedTypes="application/pdf,application/msword,text/plain"
                                noteLabel="PDF/DOC/TXT"
                                inputIdPrefix={`documentUpload-${index}`}
                              />
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() =>
                                addField(setDocumentData, documentData)
                              }
                            >
                              Add More Documents
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                      <Tab
                        eventKey="Images"
                        title="Images"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body>
                            {imageData?.map((field, index) => (
                              <DynamicFileUploadEdit
                                key={index}
                                field={field}
                                onFieldChange={(newField) => {
                                  const updatedFields = [...imageData];
                                  updatedFields[index] = newField;
                                  setImageData(updatedFields);
                                }}
                                onRemove={() =>
                                  removeItemById(
                                    imageData,
                                    setImageData,
                                    field.id
                                  )
                                }
                                fileCategory="image"
                                acceptedTypes="image/jpg,image/png,image/jpeg"
                                noteLabel="PNG/JPG/JPEG "
                                inputIdPrefix={`imageUpload-${index}`}
                              />
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() => addField(setImageData, imageData)}
                            >
                              Add More Images
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                      <Tab
                        eventKey="Videos"
                        title="Videos"
                        className="m-0 mt-0"
                      >
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body className="d-flex flex-column gap-3">
                            {videoData?.map((field, index) => (
                              <VideoUploaderEdit
                                key={index}
                                field={field}
                                onFieldChange={(newField) => {
                                  const updatedFields = [...videoData];
                                  updatedFields[index] = newField;
                                  setVideoData(updatedFields);
                                }}
                                indexField={index}
                                onRemove={() =>
                                  removeItemById(
                                    videoData,
                                    setVideoData,
                                    field.id
                                  )
                                }
                                fileCategory="video"
                                inputIdPrefix={`video-${index}`}
                              />
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() => addField(setVideoData, videoData)}
                            >
                              Add More Videos
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                      <Tab eventKey="link" title="Links" className="m-0 mt-0">
                        <Card className="border-top-0 rounded-top-0 p-4">
                          <Card.Body>
                            {refLinksData?.map((field, index) => (
                              <DynamicFieldEdit
                                key={index}
                                field={field}
                                onFieldChange={(newField) => {
                                  const updatedFields = [...refLinksData];
                                  updatedFields[index] = newField;
                                  setRefLinksData(updatedFields);
                                }}
                                indexField={index}
                                onRemove={() =>
                                  removeItemById(
                                    refLinksData,
                                    setRefLinksData,
                                    field.id
                                  )
                                }
                              />
                            ))}
                            <Button
                              className="clickable add-button mb-3 ms-auto d-flex"
                              onClick={() =>
                                addField(setRefLinksData, refLinksData)
                              }
                            >
                              Add More Links
                            </Button>
                          </Card.Body>
                        </Card>
                      </Tab>
                    </Tabs>
                  </Col>
                  <Col md={12} className="position-relative mt-4">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      id="sb-button"
                      className="next-button next demosEditBtn"
                    >
                      Submit
                    </Button>

                    <Toast
                      show={showErrorToast}
                      onClose={() => setShowErrorToast(false)}
                      autohide
                      delay={3200}
                      className="bg-white opacity-1 position-absolute"
                      style={{
                        bottom: "calc(100% + 10px)",
                        right: "10px",
                        zIndex: "999",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <XCircleFill />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body className="text-dark">
                        Fill all the (
                        <span className="text-danger">required*</span>) fields
                        to submit
                      </Toast.Body>
                    </Toast>
                  </Col>
                </Row>
              </fieldset>
            )}
          </Form>
        </Row>
      )}

      <ScheduleDateModal
        show={scheduleModalShow}
        setshow={setScheduleModalShow}
        entityType="demos"
        entity="demo"
        state={formData}
        setstate={setFormData}
        onHide={() => {
          setScheduleModalShow(false);
        }}
      />
    </Container>
  );
}

export default withRouter(EditDemo);
