import UTCLocalTimeConverter from "../UTCLocalTimeConverter/UTCLocalTimeConverter";
import UTCLocalTimeConverterEmail from "../UTCLocalTimeConverter/UTCLocalTimeConverterEmail";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";

const EmailBodyContent = (
  selectedItemDetails,
  customMessage,
  demosAlias,
  coursesAlias
) => {
  const myHost = window.location.hostname;

  const demos = selectedItemDetails?.filter((item) => item?.type === "demo");
  const courses = selectedItemDetails?.filter(
    (item) => item?.type === "course"
  );

  const generateHTML = (items) => {
    return items
      ?.map((detail, index) => {
        return `
        <table width="100%" border="0" cellspacing="0" cellpadding="0" key="${index}">
          <tr>
            <td height="16"></td>
          </tr>
          <tr>
            <td width="100%" align="center">
              <table width="560" border="0" cellspacing="0" cellpadding="0"
                style="border-radius: 12px;     box-shadow: 0 1px 20px 0 #455a6433;">
                <tr>
                  <td height="8"></td>
                </tr>
                <tr>
                  <td width="8"></td>
                  <td align="center" width="554">
                    <img src="${
                      detail?.listing_image_url || detail?.hero_image_url
                    }"
                      alt="Load Error" width="100%" height="100%" loading="lazy"
                      style="object-fit: cover;  max-height: 18rem; border-radius: 10px; box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.06);">
                  </td>
                  <td width="8"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="12"></td>
                  <td width="560" align="center">
                    <span
                      style="font-family: 'Unbounded', sans-serif; color: #43a39f; text-decoration: none; font-size: 1.25rem; font-weight: 500; line-height: normal;">
                      ${detail?.title}
                    </span>
                  </td>
                  <td width="12"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="8"></td>
          </tr>
          <tr>
            <td align="center">
              <span style="font-size: 12px;">
                ${detail?.start_date} - 
                ${UTCLocalTimeConverterEmail({
                  utcTime: detail?.start_time,
                  zone: true,
                })}
               <br> 
                ${ConvertMinutesToTime({
                  minutes: detail?.duration,
                })}
              </span>
              <span>
            </span>
            </td>
          </tr>
          <tr>
            <td height="15"></td>
          </tr>
          <tr>
            <td align="center">
              <a href="https://${`${myHost}/register/${detail?.type}/${detail?.id}`}"
                style="font-size: 14px; font-weight: 500; padding:6px 18px; text-decoration: none; border: 2px solid transparent; background-color: #43a39f; border-radius: 4px; color: #fff;">
                Register
              </a>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="18"></td>
                  <td width="560" align="center" style="line-height: 1.3;">
                    <span style="font-size: 12px; text-align:center; text-wrap: balance;
                    white-space: pre-line;">
                      ${detail?.description}
                    </span>
                  </td>
                  <td width="18"></td>
                </tr>
                <tr>
                  <td height="24"> </td>
                </tr>
              </table>
              <hr width="90%">
            </td>
          </tr>
          <tr>
            <td height="24"> </td>
          </tr>
        </table>
      `;
      })
      .join("");
  };

  const demosHTML = generateHTML(demos);
  const coursesHTML = generateHTML(courses);

  const htmlContent = `

  <!-- ***Body*** -->

  <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
          <td height="24"></td>
      </tr>
      <!-- Main Message -->
      ${
        customMessage
          ? ` 
          <tr>
          <td width="100%" style="line-height: 1.3;">
              <span style="font-size: 12px; font-family: 'Poppins', sans-serif; white-space: pre-line;">
              ${customMessage}
              </span>
          </td>
      </tr>
      <tr>
          <td height="30"></td>
      </tr>
      `
          : ""
      }
     
      <tr>
          <td style="text-wrap: balance; font-size: 13px; color: #e0a721; font-weight: 500;">
              Check out our latest ${demosAlias} and ${coursesAlias}:
          </td>
      </tr>
      <tr>
          <td height="9"></td>
      </tr>
  </table>
  
    <!-- Entity cards -->
    <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
        <td>
          <!-- Demos -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              demosHTML &&
              `
              <tr bgcolor="#43a39f" align="center">
                <td align="center">
                  <span
                    style="color: #fff; line-height: 2.6; font-family: 'Unbounded', sans-serif; font-size: 14px;">
                    Upcoming ${demosAlias}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  ${demosHTML}
                </td>
              </tr>
            `
            }
          </table>
          
          <!-- Courses -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              coursesHTML &&
              `
              <tr bgcolor="#43a39f" align="center">
                <td align="center">
                  <span
                    style="color: #fff; line-height: 2.6; font-family: 'Unbounded', sans-serif; font-size: 14px;">
                    Upcoming  ${coursesAlias}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  ${coursesHTML}
                </td>
              </tr>
            `
            }
          </table>
        </td>
      </tr>
    </table>
  `;

  return htmlContent;
};

export default EmailBodyContent;
