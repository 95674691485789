import React from "react";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { ChevronDown } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";
import useUserData from "../useUserDetails/useUserDetails";
import "./navbarstyle.css";

function NavBar() {
  const location = useLocation();

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;
  const userData = useUserData();
  const role = userData.role?.toLocaleLowerCase();

  const logoSrc = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const homeNavItem = SubDomainRouter("homeNavItem");
  const demosNavItem = SubDomainRouter("demosNavItem");
  const coursesNavItem = SubDomainRouter("homeNavItem");
  const reviewsNavItem = SubDomainRouter("reviewsNavItem");
  const corporatesNavItem = SubDomainRouter("corporatesNavItem");
  const blogNavItem = SubDomainRouter("blogNavItem");
  const codeAINavItem = SubDomainRouter("codeAINavItem");

  const isHeightMoreThan100vh = window.innerHeight > 100;

  const specRoles =
    role === "admin" ||
    role === "trainer" ||
    role === "training-coordinator" ||
    "superadmin";

  return (
    <>
      <Navbar
        expand="lg"
        className={
          isHeightMoreThan100vh
            ? "align-items-center py-0 pad"
            : "align-items-center py-0"
        }
      >
        <Container fluid="xl">
          <Link className="navbar-brand" to="/">
            <img src={`${logoSrc}`} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block d-none">
            <Nav className="mx-auto my-lg-0 my-3 align-items-center">
              {homeNavItem === "show" && (
                <Link
                  to={`/`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/` && "active-link"
                }`}
                >
                  Home
                </Link>
              )}
              {demosNavItem === "show" && (
                <Link
                  to={`/demos`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/demos` && "active-link"
                }`}
                >
                  {demosAlias}
                </Link>
              )}

              {coursesNavItem === "show" && (
                <Link
                  to={`/courses`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/courses` && "active-link"
                }`}
                >
                  {coursesAlias}
                </Link>
              )}
              {reviewsNavItem === "show" && (
                <Link
                  to={`/reviews`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/reviews` && "active-link"
                }`}
                >
                  reviews
                </Link>
              )}
              {corporatesNavItem === "show" && (
                <Link
                  to={`/corporates`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/corporates` && "active-link"
                }`}
                >
                  corporates
                </Link>
              )}
              {blogNavItem === "show" && (
                <Link
                  to={`/blogs`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/blogs` && "active-link"
                }`}
                >
                  blogs
                </Link>
              )}

              {codeAINavItem === "show" && (
                <Link
                  to={`/code-ai`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/code-ai` && "active-link"
                }`}
                >
                  Code(AI)
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="right d-flex align-items-center gap-sm-3 gap-2">
            <NavDropdown
              className="d-sm-block d-none"
              title={
                <Button className="postTrigger">
                  Join As <ChevronDown />
                </Button>
              }
              id="nav-dropdown-dark-example"
            >
              <div className="navbar-nav d-block py-2">
                <Link to="/coordinator/join" className="nav-link mb-2 mx-2">
                  Coordinator
                </Link>
                <Link to="/trainer/join" className="nav-link mx-2">
                  Instructor
                </Link>
              </div>
            </NavDropdown>
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center"
              title={
                <img
                  src={
                    userData?.photo_image_url ||
                    `https://placehold.co/280x280/42a49f/white?text=${
                      userData?.first_name?.slice(0, 1) ||
                      userData?.type?.slice(0, 1) ||
                      "I"
                    }`
                  }
                  loading="lazy"
                  alt=""
                />
              }
              id="nav-dropdown-dark-example"
            >
              <Link className="dropdown-item" to="/dashboard/my-demos">
                My Dashboard
              </Link>
              {specRoles || myUserId === "ravi.chinna225@gmail.com" ? (
                <>
                  <Link
                    className="dropdown-item"
                    to="/my-contacts/all-contacts"
                  >
                    My Contacts
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="campaigns-dashboard/email-campaign"
                  >
                    My Campaigns
                  </Link>
                </>
              ) : null}
              <Link className="dropdown-item" to="/subscriptions">
                My Subscription
              </Link>
              <Link className="dropdown-item" to="/code-ai">
                Code(AI)
              </Link>
              <Link className="dropdown-item" to="/my-profile">
                Profile
              </Link>
              {role === "admin" ||
              role === "superadmin" ||
              myUserId === "ravi.chinna225@gmail.com" ? (
                <Link className="dropdown-item" to="/changepassword">
                  Change Password
                </Link>
              ) : null}
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  localStorage.removeItem("isValidUser");
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center d-lg-none d-block"
              title={<Navbar.Toggle />}
              id="nav-dropdown-dark-example"
            >
              {homeNavItem === "show" && (
                <Link
                  to={`/`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/` && "active-link"
                }`}
                >
                  Home
                </Link>
              )}
              {demosNavItem === "show" && (
                <Link
                  to={`/demos`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/demos` && "active-link"
                }`}
                >
                  {demosAlias}
                </Link>
              )}

              {coursesNavItem === "show" && (
                <Link
                  to={`/courses`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/courses` && "active-link"
                }`}
                >
                  {coursesAlias}
                </Link>
              )}
              {reviewsNavItem === "show" && (
                <Link
                  to={`/reviews`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/reviews` && "active-link"
                }`}
                >
                  reviews
                </Link>
              )}
              {corporatesNavItem === "show" && (
                <Link
                  to={`/corporates`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/corporates` && "active-link"
                }`}
                >
                  corporates
                </Link>
              )}
              {blogNavItem === "show" && (
                <Link
                  to={`/blogs`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/blogs` && "active-link"
                }`}
                >
                  blogs
                </Link>
              )}

              {codeAINavItem === "show" && (
                <Link
                  to={`/code-ai`}
                  className={`
                nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                  location.pathname === `/code-ai` && "active-link"
                }`}
                >
                  Code(AI)
                </Link>
              )}

              <div className=" d-sm-none d-block px-2">
                <NavDropdown.Divider />
                <Link
                  to="/coordinator/join"
                  className="postTrigger btn w-100 mb-2"
                >
                  Join As Coordinator
                </Link>
                <Link to="/trainer/join" className="postTrigger btn w-100 mb-2">
                  Join As Instructor
                </Link>
              </div>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
