import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import "./css/font-awesome.min.css";
import "./css/owl.carousel.css";
import "./css/owl.theme.default.min.css";
import "./myhome.css";
import logo from "../../img/logo.png";
import cimage1 from "./images/courses-image1.jpg";
import cimage2 from "./images/courses-image2.jpg";
import cimage3 from "./images/courses-image3.jpg";
import aimage1 from "./images/author-image1.jpg";
import aimage2 from "./images/author-image2.jpg";
import aimage3 from "./images/author-image3.jpg";
export default class MyHome extends React.Component {
  componentDidMount() {
    setInterval(() => {
      this.handleNext();
    }, 5000);
  }
  state = {
    currentIndex: 1,
  };
  imagelength = 3;
  handlePrev = () => {
    debugger;
    var cIndex = this.state.currentIndex;
    var idx = cIndex <= 1 ? 3 : cIndex - 1;
    this.setState({ currentIndex: idx });
  };

  handleNext = () => {
    var cIndex = this.state.currentIndex;
    var idx = cIndex >= 3 ? 1 : cIndex + 1;
    this.setState({ currentIndex: idx });
  };
  render() {
    return (
      <div className="boxcontainer">
        {/* <!-- PRE LOADER --> */}

        {/* <!-- MENU --> */}
        <section
          className="navbar custom-navbar navbar-fixed-top"
          role="navigation"
        >
          <div className="container-lg row">
            <div className="navbar-header col-lg-2">
              <button
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="icon icon-bar"></span>
                <span className="icon icon-bar"></span>
                <span className="icon icon-bar"></span>
              </button>

              {/* <!-- lOGO TEXT HERE --> */}
              <img
                src={logo}
                alt=""
                className="logstyle"
                width={160}
                height={70}
              />
            </div>

            {/* <!-- MENU LINKS --> */}
            <div className="collapse navbar-collapse col-lg-10">
              <ul className="nav navbar-nav-first">
                <li>
                  <a href="#top" className="smoothScroll">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" className="smoothScroll">
                    About
                  </a>
                </li>
                <li>
                  <a href="#team" className="smoothScroll">
                    Our Trainers
                  </a>
                </li>
                <li>
                  <a href="#courses" className="smoothScroll">
                    Courses
                  </a>
                </li>
                <li>
                  <a href="#testimonial" className="smoothScroll">
                    Reviews
                  </a>
                </li>
                <li>
                  <a href="#contact" className="smoothScroll">
                    Contact
                  </a>
                </li>
              </ul>
              {/* 
               <ul className="nav navbar-right">
                    <li><a href="#"><i className="fa fa-phone"></i> +65 2244 1100</a></li>
               </ul> */}
            </div>
          </div>
        </section>
        {/* <!-- HOME --> */}
        <section id="home">
          <div className="row">
            <div className="owl-theme home-slider">
              <div>
                <a
                  href="#"
                  className="slider-arrow left-arrow"
                  onClick={this.handlePrev}
                >
                  &#10094;
                </a>
                <a
                  className="slider-arrow right-arrow"
                  onClick={this.handleNext}
                >
                  &#10095;
                </a>
              </div>
              <div
                className={
                  "item item-first " +
                  (this.state.currentIndex == 1 ? "show" : "hide")
                }
              >
                <div className="caption">
                  <div className="container-lg">
                    <div className="col-md-6 col-sm-12">
                      <h1>Distance Learning Education Center</h1>
                      <h3>
                        Our online courses are designed to fit in your industry
                        supporting all-round with latest technologies.
                      </h3>
                      <a
                        href="#feature"
                        className="section-btn btn btn-default smoothScroll"
                      >
                        Discover more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "item item-second " +
                  (this.state.currentIndex == 2 ? "show" : "hide")
                }
              >
                <div className="caption">
                  <div className="container-lg">
                    <div className="col-md-6 col-sm-12">
                      <h1>Start your journey with our practical courses</h1>
                      <h3>
                        Our online courses are built in partnership with
                        technology leaders and are designed to meet industry
                        demands.
                      </h3>
                      <a
                        href="#courses"
                        className="section-btn btn btn-default smoothScroll"
                      >
                        Take a course
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  "item item-third " +
                  (this.state.currentIndex == 3 ? "show" : "hide")
                }
              >
                <div className="caption">
                  <div className="container-lg">
                    <div className="col-md-6 col-sm-12">
                      <h1>Efficient Learning Methods</h1>
                      <h3>
                        Nam eget sapien vel nibh euismod vulputate in vel nibh.
                        Quisque eu ex eu urna venenatis sollicitudin ut at
                        libero. Visit{" "}
                        <a
                          rel="nofollow"
                          href="https://www.facebook.com/templatemo"
                        >
                          templatemo
                        </a>{" "}
                        page.
                      </h3>
                      <a
                        href="#contact"
                        className="section-btn btn btn-default smoothScroll"
                      >
                        Let's chat
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- FEATURE --> */}
        <section id="feature">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="feature-thumb">
                  <span>01</span>
                  <h3>Trending Courses</h3>
                  <p>
                    Known is free education HTML Bootstrap Template. You can
                    modify in any way and use this for your website.
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="feature-thumb">
                  <span>02</span>
                  <h3>Books & Library</h3>
                  <p>
                    You are allowed to use Known HTML Template for your
                    commercial or non-commercial websites.
                  </p>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="feature-thumb">
                  <span>03</span>
                  <h3>Certified Trainers</h3>
                  <p>
                    Please spread a word about us. Template redistribution is
                    NOT allowed on any download website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ABOUT --> */}
        <section id="about">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="about-info">
                  <h2>
                    Start your journey to a better life with online practical
                    courses
                  </h2>

                  <figure>
                    <span>
                      <i className="fa fa-users"></i>
                    </span>
                    <figcaption>
                      <h3>Professional Trainers</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sint ipsa voluptatibus.
                      </p>
                    </figcaption>
                  </figure>

                  <figure>
                    <span>
                      <i className="fa fa-certificate"></i>
                    </span>
                    <figcaption>
                      <h3>International Certifications</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sint ipsa voluptatibus.
                      </p>
                    </figcaption>
                  </figure>

                  <figure>
                    <span>
                      <i className="fa fa-bar-chart-o"></i>
                    </span>
                    <figcaption>
                      <h3>Free for 3 months</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sint ipsa voluptatibus.
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>

              <div className="col-md-offset-1 col-md-4 col-sm-12">
                <div className="entry-form">
                  <form action="#" method="post">
                    <h2>Signup today</h2>
                    <input
                      type="text"
                      name="full name"
                      className="form-control"
                      placeholder="Full name"
                      required=""
                    />

                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your email address"
                      required=""
                    />

                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Your password"
                      required=""
                    />

                    <button
                      className="submit-btn form-control"
                      id="form-submit"
                    >
                      Get started
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- TEAM --> */}
        <section id="team">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>
                    Trainers <small>Meet Professional Trainers</small>
                  </h2>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="team-thumb">
                  <div className="team-image">
                    <img
                      src="images/author-image1.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="team-info">
                    <h3>Mark Wilson</h3>
                    <span>I love Teaching</span>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a
                        href="#"
                        className="fa fa-facebook-square"
                        attr="facebook icon"
                      ></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-twitter"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-instagram"></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="team-thumb">
                  <div className="team-image">
                    <img
                      src="images/author-image2.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="team-info">
                    <h3>Catherine</h3>
                    <span>Education is the key!</span>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="#" className="fa fa-google"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-instagram"></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="team-thumb">
                  <div className="team-image">
                    <img
                      src="images/author-image3.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="team-info">
                    <h3>Jessie Ca</h3>
                    <span>I like Online Courses</span>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="#" className="fa fa-twitter"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-envelope-o"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-linkedin"></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="team-thumb">
                  <div className="team-image">
                    <img
                      src="images/author-image4.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  <div className="team-info">
                    <h3>Andrew Berti</h3>
                    <span>Learning is fun</span>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="#" className="fa fa-twitter"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-google"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-behance"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Courses --> */}
        <section className="courses">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>
                    Popular Courses{" "}
                    <small>Upgrade your skills with newest courses</small>
                  </h2>
                </div>

                <div className="owl-theme owl-courses row">
                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="courses-thumb">
                        <div className="courses-top">
                          <div className="courses-image">
                            <img
                              src={cimage1}
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                          <div className="courses-date">
                            <span>
                              <i className="fa fa-calendar"></i> 07 / 20 / 2023
                            </span>
                            <span>
                              <i className="fa fa-clock-o"></i> 2 Hours
                            </span>
                          </div>
                        </div>

                        <div className="courses-detail">
                          <h3>
                            <a href="#">Chat GPT</a>
                          </h3>
                          <p className="text-overflow-desc">
                            ChatGPT (Chat Generative Pre-Trained Transformer) is
                            an artificial intelligence chatbot developed by
                            OpenAI and launched on November 30, 2022. It is
                            notable for enabling users to refine and steer a
                            conversation towards a desired length, format,
                            style, level of detail, and language used.
                          </p>
                        </div>

                        <div className="courses-info">
                          <div className="courses-author">
                            <img
                              src={aimage1}
                              className="img-responsive"
                              alt=""
                            />
                            <span>Mark Wilson</span>
                          </div>
                          <div className="courses-price">
                            <a href="#">
                              <span>USD 25</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="courses-thumb">
                        <div className="courses-top">
                          <div className="courses-image">
                            <img
                              src={cimage2}
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                          <div className="courses-date">
                            <span>
                              <i className="fa fa-calendar"></i> 20 / 7 / 2023
                            </span>
                            <span>
                              <i className="fa fa-clock-o"></i> 4.5 Hours
                            </span>
                          </div>
                        </div>

                        <div className="courses-detail">
                          <h3>
                            <a href="#">Graphic & Web Design</a>
                          </h3>
                          <p className="text-overflow-desc">
                            Graphic design is a craft where professionals create
                            visual content to communicate messages. By applying
                            visual hierarchy and page layout techniques,
                            designers use typography and pictures to meet users'
                            specific needs and focus on the logic of displaying
                            elements in interactive designs, to optimize the
                            user experience
                          </p>
                        </div>

                        <div className="courses-info">
                          <div className="courses-author">
                            <img
                              src={aimage2}
                              className="img-responsive"
                              alt=""
                            />
                            <span>Jessica</span>
                          </div>
                          <div className="courses-price">
                            <a href="#">
                              <span>USD 80</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="courses-thumb">
                        <div className="courses-top">
                          <div className="courses-image">
                            <img
                              src={cimage3}
                              className="img-responsive"
                              alt=""
                            />
                          </div>
                          <div className="courses-date">
                            <span>
                              <i className="fa fa-calendar"></i> 15 / 8 / 2023
                            </span>
                            <span>
                              <i className="fa fa-clock-o"></i> 6 Hours
                            </span>
                          </div>
                        </div>

                        <div className="courses-detail">
                          <h3>
                            <a href="#">Cloud Architecture</a>
                          </h3>
                          <p className="text-overflow-desc">
                            Cloud architecture is the way technology components
                            combine to build a cloud, in which resources are
                            pooled through virtualization technology and shared
                            across a network. The components of a cloud
                            architecture include: A front-end platform the
                            client or device used to access the cloud
                          </p>
                        </div>

                        <div className="courses-info">
                          <div className="courses-author">
                            <img
                              src={aimage3}
                              className="img-responsive"
                              alt=""
                            />
                            <span>Catherine</span>
                          </div>
                          <div className="courses-price free">
                            <a href="#">
                              <span>Free</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-sm-4">
                              <div className="item">
                                   <div className="courses-thumb">
                                        <div className="courses-top">
                                             <div className="courses-image">
                                                  <img src={cimage4} className="img-responsive" alt=""/>
                                             </div>
                                             <div className="courses-date">
                                                  <span><i className="fa fa-calendar"></i> 10 / 8 / 2018</span>
                                                  <span><i className="fa fa-clock-o"></i> 8 Hours</span>
                                             </div>
                                        </div>

                                        <div className="courses-detail">
                                             <h3><a href="#">Summer Kids</a></h3>
                                             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>

                                        <div className="courses-info">
                                             <div className="courses-author">
                                                  <img src="images/author-image1.jpg" className="img-responsive" alt=""/>
                                                  <span>Mark Wilson</span>
                                             </div>
                                             <div className="courses-price">
                                                  <a href="#"><span>USD 45</span></a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="col-md-4 col-sm-4">
                              <div className="item">
                                   <div className="courses-thumb">
                                        <div className="courses-top">
                                             <div className="courses-image">
                                                  <img src={cimage5} className="img-responsive" alt=""/>
                                             </div>
                                             <div className="courses-date">
                                                  <span><i className="fa fa-calendar"></i> 5 / 10 / 2018</span>
                                                  <span><i className="fa fa-clock-o"></i> 10 Hours</span>
                                             </div>
                                        </div>

                                        <div className="courses-detail">
                                             <h3><a href="#">Business &amp; Management</a></h3>
                                             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>

                                        <div className="courses-info">
                                             <div className="courses-author">
                                                  <img src="images/author-image2.jpg" className="img-responsive" alt=""/>
                                                  <span>Jessica</span>
                                             </div>
                                             <div className="courses-price free">
                                                  <a href="#"><span>Free</span></a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- TESTIMONIAL --> */}
        <section className="testimonial">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="section-title">
                  <h2>
                    Student Reviews <small>from around the world</small>
                  </h2>
                </div>

                <div className="owl-theme owl-client row">
                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="tst-image">
                        <img
                          src="images/tst-image1.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="tst-author">
                        <h4>Jackson</h4>
                        <span>Shopify Developer</span>
                      </div>
                      <p>
                        You really do help young creative minds to get quality
                        education and professional job search assistance. I’d
                        recommend it to everyone!
                      </p>
                      <div className="tst-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="tst-image">
                        <img
                          src="images/tst-image2.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="tst-author">
                        <h4>Camila</h4>
                        <span>Marketing Manager</span>
                      </div>
                      <p>
                        Trying something new is exciting! Thanks for the amazing
                        law course and the great Trainer who was able to make it
                        interesting.
                      </p>
                      <div className="tst-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="tst-image">
                        <img
                          src="images/tst-image3.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="tst-author">
                        <h4>Barbie</h4>
                        <span>Art Director</span>
                      </div>
                      <p>
                        Donec erat libero, blandit vitae arcu eu, lacinia
                        placerat justo. Sed sollicitudin quis felis vitae
                        hendrerit.
                      </p>
                      <div className="tst-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div className="item">
                      <div className="tst-image">
                        <img
                          src="images/tst-image4.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="tst-author">
                        <h4>Andrio</h4>
                        <span>Web Developer</span>
                      </div>
                      <p>
                        Nam eget mi eu ante faucibus viverra nec sed magna.
                        Vivamus viverra sapien ex, elementum varius ex sagittis
                        vel.
                      </p>
                      <div className="tst-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- CONTACT --> */}
        <section id="contact">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <form id="contact-form" role="form" action="" method="post">
                  <div className="section-title">
                    <h2>
                      Contact us{" "}
                      <small>we love conversations. let us talk!</small>
                    </h2>
                  </div>

                  <div className="col-md-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter full name"
                      name="name"
                      required=""
                    />

                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email address"
                      name="email"
                      required=""
                    />

                    <textarea
                      className="form-control"
                      rows="6"
                      placeholder="Tell us about your message"
                      name="message"
                      required=""
                    ></textarea>
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <input
                      type="submit"
                      className="form-control"
                      name="send message"
                      value="Send Message"
                    />
                  </div>
                </form>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="contact-image">
                  <img
                    src="images/contact-image.jpg"
                    className="img-responsive"
                    alt="Smiling Two Girls"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- FOOTER --> */}
        <footer id="footer">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="footer-info">
                  <div className="section-title">
                    <h2>Headquarter</h2>
                  </div>
                  <address>
                    <p>
                      1800 dapibus a tortor pretium,
                      <br /> Integer nisl dui, ABC 12000
                    </p>
                  </address>

                  <ul className="social-icon">
                    <li>
                      <a
                        href="#"
                        className="fa fa-facebook-square"
                        attr="facebook icon"
                      ></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-twitter"></a>
                    </li>
                    <li>
                      <a href="#" className="fa fa-instagram"></a>
                    </li>
                  </ul>

                  <div className="copyright-text">
                    <p>Copyright &copy; 2019 Company Name</p>

                    <p>Design: TemplateMo</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="footer-info">
                  <div className="section-title">
                    <h2>Contact Info</h2>
                  </div>
                  <address>
                    <p>+65 2244 1100, +66 1800 1100</p>
                    <p>
                      <a href="mailto:youremail.co">hello@youremail.co</a>
                    </p>
                  </address>

                  <div className="footer_menu">
                    <h2>Quick Links</h2>
                    <ul>
                      <li>
                        <a href="#">Career</a>
                      </li>
                      <li>
                        <a href="#">Investor</a>
                      </li>
                      <li>
                        <a href="#">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="#">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="footer-info newsletter-form">
                  <div className="section-title">
                    <h2>Newsletter Signup</h2>
                  </div>
                  <div>
                    <div className="form-group">
                      <form action="#" method="get">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                          name="email"
                          id="email"
                          required=""
                        />
                        <input
                          type="submit"
                          className="form-control"
                          name="submit"
                          id="form-submit"
                          value="Send me"
                        />
                      </form>
                      <span>
                        <sup>*</sup> Please note - we do not spam your email.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* <!-- SCRIPTS --> */}
        {/* <script src="js/jquery.js"></script> */}
      </div>
    );
  }
}
