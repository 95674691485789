import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../../components/loader/loader";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import PostsSidebar from "../../components/postsSidebar/postsSidebar";
import {
  Calendar2Date,
  Facebook,
  Instagram,
  Linkedin,
  Person,
  Share,
} from "react-bootstrap-icons";
import FormattedDate from "../../components/timestampConverters/createdDateFormatter";
import SocialShare from "../../components/share/socialShare";
import { Link } from "react-router-dom";
import placeholdImg from "../../img/user.png";
import { WhatsApp } from "@mui/icons-material";
import TextFormatter from "../../components/textFormat/textFormat";

function BlogDetails(props) {
  const entityName = "blog";
  const entities = "blogs";

  const currentDomain = window.location.hostname;

  const [loading, setLoading] = useState(true);
  const [entityData, setEntityData] = useState({});

  const populateList = async (id) => {
    try {
      axios.get(`${Actions.BASE_URL}${entities}/${id}`).then((response) => {
        setLoading(false);
        setEntityData(response.data);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
  }, [props.params.id]);

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>
          {entityData?.title ? `${entityData?.title}` : "Blog Post"} |{siteName}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <Row className="mx-0 blog-details">
            <Col
              lg={8}
              className="text-wrap-balance text-sm-start text-center px-lg-2 px-0"
            >
              <div className="blog-card mb-3 p-3 header-card">
                <div className="hero-image border border-secondary-subtle mb-4 h-100 overflow-hidden">
                  <Image
                    src={
                      entityData?.hero_image_url ||
                      entityData?.listing_image_url
                    }
                    loading="lazy"
                    className="w-100 h-100 object-fit-cover"
                    alt=""
                  />
                </div>
                <div className="main-content pt-3">
                  <span className="chip d-block mb-3 rounded-pill w-fit theme-text text-capitalize">
                    {entityData?.type}
                  </span>
                  <h3 className="main-title m-0 theme-text">
                    {entityData?.title}&nbsp;
                  </h3>
                  <Row className="author-data my-sm-3 my-2 mx-0">
                    <Col
                      sm={6}
                      className="d-flex align-items-center justify-content-sm-start justify-content-center p-0"
                    >
                      <Person className="theme-text" />
                      &nbsp;{entityData?.author}
                    </Col>
                    <Col
                      sm={6}
                      className="d-flex align-items-center justify-content-sm-start justify-content-center p-0"
                    >
                      <Calendar2Date className="theme-text" />
                      &nbsp;
                      <FormattedDate createdDate={entityData?.createdDate} />
                    </Col>
                  </Row>

                  <p className="main-data">{entityData?.twoLinerIntro}</p>
                  <p className="main-data">
                    <TextFormatter text={entityData?.blogContent} />
                  </p>

                  {entityData?.tags?.length > 0 && (
                    <div className="d-flex align-items-stretch gap-2 pt-3">
                      {entityData?.tags?.map((tag) => (
                        <span className="chip d-block rounded-pill w-fit theme-text-2 text-uppercase">
                          #{entityData?.type}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="blog-card mb-3 p-3 share-card text-center">
                <h5 className="theme-text">Share this article:</h5>
                <div className="d-flex gap-2 justify-content-center my-3 align-items-center">
                  <SocialShare
                    url={`https://${currentDomain}/blogs/details/${entityData?.id}/`}
                    iconsSize={30}
                    title={entityData?.title}
                    image={entityData?.listing_image_url}
                    description={entityData?.description}
                  />
                  <Link
                    to={`/share/${entityName}/${entityData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <Share />
                  </Link>
                </div>
              </div>

              <Row className="blog-card mb-3 p-3 py-md-4 author-card mx-0 align-items-start">
                <Col sm={3}>
                  <div className="image-wrapper mb-2">
                    <Image
                      src={placeholdImg}
                      className="w-100 h-100 object-fit-cover"
                      alt="author"
                    />
                  </div>
                </Col>
                <Col sm={9} className="content-wrapper">
                  <h5 className="theme-text-2 mb-0">{entityData?.author}</h5>
                  <small className="opacity-75 fw-normal d-block mb-sm-3 mb-2 semi-bg">
                    {entityData?.author && "~Author"}
                  </small>

                  {entityData?.social_links?.length > 0 && (
                    <ul className="social-list list m-0 list-unstyled d-flex align-items-center gap-md-3 gap-2 mb-2">
                      {entityData?.social_links?.map((link) => (
                        <>
                          {link?.facebook && (
                            <li>
                              <a href={`${link?.facebook}`} title="facebook">
                                <Facebook />
                              </a>
                            </li>
                          )}
                          {link?.twitter && (
                            <li>
                              <a href={`${link?.twitter}`} title="twitter">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width="1em"
                                  height="1em"
                                >
                                  <path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
                                </svg>
                              </a>
                            </li>
                          )}
                          {link?.linkedin && (
                            <li>
                              <a href={`${link?.linkedin}`} title="linkedin">
                                <Linkedin />
                              </a>
                            </li>
                          )}
                          {link?.instagram && (
                            <li>
                              <a
                                href={
                                  link?.instagram?.startsWith("https://")
                                    ? link?.instagram
                                    : `https://${link?.instagram}`
                                }
                                title="instagram"
                              >
                                <Instagram />
                              </a>
                            </li>
                          )}
                          {link?.whatsapp && (
                            <li>
                              <a
                                href={`http://wa.me/${link?.whatsapp}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <WhatsApp />
                              </a>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  )}

                  <p className="bio">
                    <TextFormatter text={entityData?.authorBio} />
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="px-lg-2 px-0">
              <PostsSidebar />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
export default withRouter(BlogDetails);
