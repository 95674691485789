import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import AddTag from "../../components/tagInput/tagInput"; 
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import DynamicRelationFieldEdit from "../../components/dynamicRelationFieldSet/dynamicRelationFieldEdit";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../../components/loader/loader";
import useUserData from "../../components/useUserDetails/useUserDetails";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";

const AddBlog = (props) => {
  const itemId = props.params.id;

  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails(); 
  const myUserId = tokenDetails?.Email;

  const entities = "blogs";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const userData = useUserData({ setLoading });

  const [loadingReq, setLoadingReq] = useState(false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    createdBy: myUserId,
    type: "",
    filters: {},
    tags: [],
    comments: "",
    host: "",
    category: "",
    subcategory: "",
    subcategorylevel1: "",
    subcategorylevel2: "",
    subcategorylevel3: "",
    entity_type: "",
    star_rating_type: "",
    entityRelation: [],
    title: "",
    twoLinerIntro: "",
    blogContent: "",
    author: "",
    authorBio: "",
    blogComments: {},
    phone: "",
    whatsapp: "",
    website: "",
    listing_image_content: "",
    listing_image_extension: "",
    hero_image_content: "",
    hero_image_extension: "",
    online_join_link: "",
    meeting_id: "",
    social_links: [],
  });
  const [addedTags, setAddedTags] = useState([]);
  const [step, setStep] = useState(1);
  const [socialData, setSocialData] = useState([{ type: "", value: "" }]);

  useEffect(() => {
    const username = `${
      userData?.first_name
        ? userData?.first_name !== undefined && userData?.first_name
        : ""
    } ${
      userData?.last_name
        ? userData?.last_name !== undefined && userData?.last_name
        : ""
    }`;

    setFormData({
      ...formData,
      phone: userData?.phone,
      whatsapp: userData?.whatsapp,
      author: username !== " " ? username : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const populateForm = (id) => {
    axios
      .get(`${Actions.BASE_URL}${entities}/${id}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData(res.data);
        setAddedTags(res.data.tags);
        setSocialData(res.data.social_links || [{ type: "", value: "" }]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  useEffect(() => {
    populateForm(props?.params?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.params?.id]);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const handleSubmit = () => {
    setLoadingReq(true);

    if (itemId) {
      axios
        .put(`${Actions.BASE_URL}${entities}/${itemId}`, formData, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/${entities}`);
          setLoading(false);
        })
        .catch((error) => {
          setLoadingReq(false);
          console.log(error.response);
        });
    } else {
      const data = {
        ...formData,
        tags: addedTags,
        social_links: socialData,
      };

      axios
        .post(`${Actions.BASE_URL}${entities}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate(`/share/blog/${res?.data?.id}`);
          setLoadingReq(false);
        })
        .catch((error) => {
          setLoadingReq(false);
          console.log(error.response);
        });
    }
  };

  const titleInputRef = useRef(null);

  useEffect(() => {
    titleInputRef?.current?.focus();
  }, []);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const demoAlias = SubDomainRouter("demoAlias");
  const courseAlias = SubDomainRouter("courseAlias");

  return (
    <div className="demosAddFormSection demosEditSection">
      {loadingReq ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-3">
          <div className="demosAddFormHeader text-center">
            <h2 className="addFormHeading text-capitalize">
              {itemId ? "Edit Your Blog" : "Create a Blog"}
            </h2>
            <div className="demosEditHeader text-center mb-3 mb-lg-5">
              <h5>Add Details Below:</h5>
              <ul className="demosEditList">
                <li>
                  <button
                    className={
                      step === 1 ? "active stepstriggers" : "stepstriggers"
                    }
                    onClick={() => setStep(1)}
                  >
                    <span>1</span>
                  </button>
                </li>
                <li>
                  <button
                    className={
                      step === 2 ? "active stepstriggers" : "stepstriggers"
                    }
                    onClick={() => setStep(2)}
                  >
                    <span>2</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <Form
            id="add-demo-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="form-section addFormSection event-detail">
              {step === 1 && (
                <fieldset className="wizard-fieldset show demosAddForm">
                  <Row className="mx-0">
                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="title"
                          required
                          className="italicInput"
                          value={formData?.title}
                          ref={titleInputRef}
                          placeholder="Enter Event Title"
                          name="title"
                          onChange={(e) =>
                            handleFieldChange("title", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="title">
                          Post Title
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="2"
                          name="twoLinerIntro"
                          value={formData?.twoLinerIntro}
                          id="twoLinerIntro"
                          placeholder=" Enter your event twoLinerIntro"
                          onChange={(e) =>
                            handleFieldChange("twoLinerIntro", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Two Liner Into For Post
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="4"
                          name="blogContent"
                          value={formData?.blogContent}
                          id="blogContent"
                          placeholder=" Enter your event blogContent"
                          onChange={(e) =>
                            handleFieldChange("blogContent", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="blogContent"
                        >
                          Post Content
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <BannerUploaderEdit
                        label="Big Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="hero_image"
                        isBlog={true}
                        placehold="https://placehold.co/720x720"
                        state={formData}
                        setState={setFormData}
                        imageURL={formData?.hero_image_url}
                        imageContent={formData?.hero_image_content}
                        imageExtension={formData?.hero_image_extension}
                      />
                    </Col>

                    <Col md={6}>
                      <BannerUploaderEdit
                        label="Small Banner"
                        accept="image/png, image/jpg, image/jpeg"
                        imageType="listing_image"
                        placehold="https://placehold.co/280x280"
                        isBlog={true}
                        state={formData}
                        setState={setFormData}
                        imageURL={formData?.listing_image_url}
                        imageContent={formData?.listing_image_content}
                        imageExtension={formData?.listing_image_extension}
                      />
                    </Col>

                    <Col md={12}>
                      <AddTag
                        label={"Tags"}
                        addedTags={addedTags}
                        setAddedTags={setAddedTags}
                      />
                    </Col>

                    <Col md={12}>
                      <Form.Select
                        className="form-control text-capitalize"
                        onChange={(e) =>
                          handleFieldChange("type", e.target.value)
                        }
                        value={formData?.type}
                      >
                        <option value="">
                          What are you writing this blog for?
                        </option>
                        <option value="demo">{demoAlias}</option>
                        <option value="course">{courseAlias}</option>
                        <option value="institute">Institute</option>
                        <option value="general">General</option>
                      </Form.Select>
                    </Col>

                    <Col ms={12} className="mt-3">
                      <Button
                        type="button"
                        className="next demosEditBtn "
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              )}
              {step === 2 && (
                <fieldset className="wizard-fieldset show demosAddForm">
                  <Row className="mx-0">
                    <Col sm={12} className="form-section-header mt-4 mb-3">
                      <h4 className="theme-text-2">Author's Details</h4>
                    </Col>

                    <Col md={12}>
                      <small className="text-end d-block text-dark">
                        required<span className="text-danger">*</span>
                      </small>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          type="text"
                          id="author"
                          required
                          value={formData?.author}
                          placeholder="Enter Event Title"
                          name="author"
                          onChange={(e) =>
                            handleFieldChange("author", e.target.value)
                          }
                        />
                        <Form.Label className="formLabel" htmlFor="author">
                          Author's Name
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as="textarea"
                          className="w-100 form-control max-h-auto"
                          rows="4"
                          name="authorBio"
                          value={formData?.authorBio}
                          id="authorBio"
                          placeholder=" Enter your event authorBio"
                          onChange={(e) =>
                            handleFieldChange("authorBio", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Author's BIO
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          name="phone"
                          value={formData?.phone}
                          id="phone"
                          placeholder=" Enter your event phone"
                          onChange={(e) =>
                            handleFieldChange("phone", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          Phone No.
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="form-floating mb-4">
                        <Form.Control
                          name="whatsapp"
                          value={formData?.whatsapp}
                          id="whatsapp"
                          placeholder=" Enter your event whatsapp"
                          onChange={(e) =>
                            handleFieldChange("whatsapp", e.target.value)
                          }
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="twoLinerIntro"
                        >
                          WhatsApp
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <hr />
                    <Col
                      md={12}
                      className="d-flex justify-content-between gap-3 mt-3"
                    >
                      <h6 className="theme-text-2 flex-grow-1">Social Links</h6>
                      <Button
                        className="clickable addStyle mb-3 ms-auto d-flex"
                        onClick={() => {
                          const newField = { type: "", value: "" };
                          setSocialData([...socialData, newField]);
                        }}
                      >
                        <span className="addBttn d-block">+</span>
                      </Button>
                    </Col>
                    <Col md={12}>
                      {socialData?.map((field, index) => (
                        <DynamicRelationFieldEdit
                          key={index}
                          fields={socialData}
                          field={field}
                          index={index}
                          onFieldChange={(newFields) => {
                            setSocialData(newFields);
                          }}
                          fieldLabels={{
                            valueInput: "Enter Your Social (Profile) Link",
                          }}
                          selectOptions={[
                            { value: "facebook", label: "Facebook" },
                            { value: "instagram", label: "Instagram" },
                            { value: "twitter", label: "Twitter" },
                            { value: "linkedIn", label: "LinkedIn" },
                            { value: "website", label: "Website" },
                            { value: "others", label: "Others" },
                          ]}
                        />
                      ))}
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col ms={12} className="mt-3">
                      <Button
                        type="button"
                        className="previous-button demosEditBtn"
                        onClick={handlePrevious}
                      >
                        Previous
                      </Button>
                      <Button type="submit" className="next demosEditBtn">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
              )}
            </div>
          </Form>
        </Container>
      )}
    </div>
  );
};
export default withRouter(AddBlog);
