import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ShareFill } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import IMG2 from "../../img/SWIPERIMG3-min.jpg";
import COURSEIMG from "../../img/course.png";
import UTCLocalTimeConverter from "../../components/UTCLocalTimeConverter/UTCLocalTimeConverter";
import ConvertDateFormat from "../../components/convertDateFormat/convertDateFormat";
import UploadedDocuments from "../../components/documents/documents";
import ImageGallery from "../../components/gallery/gallery";
import LinksContent from "../../components/linksContent/linksContent";
import CustomLoader from "../../components/loader/loader";
import RegisterModal from "../../components/register/registerModal";
import SocialShare from "../../components/share/socialShare";
import StarRating from "../../components/starRating/starRating";
import VideoGallery from "../../components/videoGallery/videoGallery";
import "./details.css";
import DemoInfo from "./itemInfo";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";

function DemoDetails(props) {
  const myToken = localStorage.getItem("token");

  const entities = "demos";

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [demoData, setDemoData] = useState({});
  const [reviewData, setReviewData] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(3);

  const populateList = async (id) => {
    try {
      const data = await fetch(`${Actions.BASE_URL}${entities}/${id}`).then(
        (response) => response.json()
      );
      setLoading(false);
      setDemoData(data);
    } catch (error) {
      console.log("There was an error", error);
    }
  };
  const GetReviews = async (id) => {
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}reviews/demo/${id}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setReviewData(response.data);
      ScrollToReviews();
    } catch (error) {
      console.error("There was an error", error);
    }
  };
  // Scroll to reviews
  const location = useLocation();
  const scrollTo = new URLSearchParams(location.search).get("demo");

  // Create a ref for the target element
  const targetRef = useRef(null);

  const ScrollToReviews = () => {
    if (scrollTo) {
      setTimeout(() => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 800);
    }
  };

  useEffect(() => {
    populateList(props.params.id);
    GetReviews(props.params.id);
  }, [props.params.id]);

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) =>
      prevDisplayedCards + 3 <= reviewData.length
        ? prevDisplayedCards + 3
        : reviewData.length
    );
  };

  const siteName = SubDomainRouter("title");
  const currentDomain = window.location.hostname;
  const DemoAlias = SubDomainRouter("demoAlias");

  return (
    <>
      <Helmet>
        <title>
          {demoData?.title ? `${demoData?.title}` : `${DemoAlias} Details`} |{" "}
          {siteName}
        </title>
      </Helmet>

      <Container fluid="lg" className="my-5 pt-5">
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="demosViewMoreSection demoProfile overflow-hidden mx-auto">
            <div className="coverImg p-2 position-relative w-100">
              <Image
                src={
                  demoData?.hero_image_url ||
                  demoData?.listing_image_url ||
                  IMG2
                }
                width="100%"
                alt=""
              />
            </div>

            <Row className="profileHeader align-items-center position-relative py-2 mx-4">
              <Col lg={2} className="mr-3">
                <Image
                  src={
                    demoData?.listing_image_url ||
                    demoData?.hero_image_url ||
                    COURSEIMG
                  }
                  alt="..."
                  className="profileImg"
                />
              </Col>
              <Col md={6} className="mt-lg-0 mt-5 pt-lg-0 pt-2 ps-4">
                <h4 className="trainerName mb-2">
                  {demoData?.title}
                  <br />
                  <span className="industry">
                    {demoData?.start_date != "" ? (
                      <ConvertDateFormat inputDate={demoData?.start_date} />
                    ) : (
                      "Yet To Decide"
                    )}
                    &nbsp; - &nbsp;
                    {demoData?.start_time != "" ? (
                      <b>
                        <UTCLocalTimeConverter
                          zone
                          utcTime={demoData?.start_time}
                        />
                      </b>
                    ) : (
                      ""
                    )}
                  </span>
                </h4>
                {demoData?.star_rating_type && (
                  <StarRating rating={demoData?.star_rating_type} />
                )}
              </Col>
              <Col lg={4} md={6}>
                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                  <SocialShare
                    url={`https://${currentDomain}/${demoData?.type}s/details/${demoData?.id}/`}
                    iconsSize={30}
                    title={demoData?.title}
                    image={demoData?.listing_image_url}
                    description={demoData?.description}
                  />
                  <Link
                    to={`/share/demo/${demoData.id}`}
                    className="entity-share-btn d-flex align-items-center cursor-pointer"
                    title="Share"
                  >
                    <ShareFill />
                  </Link>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <Button
                    onClick={() => {
                      setModalShow(true);
                      setModalData(demoData);
                    }}
                    className="actionBtn btn add-button cursor-pointer"
                    title="demos"
                  >
                    Register
                  </Button>
                  <Link
                    to={`/enquiries/demo/${demoData?.id}/add`}
                    className="add-button btn w-fit px-3 d-block"
                  >
                    Enquiry
                  </Link>
                </div>
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="details"
              id="demo-details"
              className="mx-4 mt-2"
              fill
            >
              <Tab eventKey="details" title="Details" className="m-4 mt-0">
                <DemoInfo
                  demoData={demoData}
                  displayedCards={displayedCards}
                  reviewData={reviewData}
                  targetRef={targetRef}
                  handleShowMore={handleShowMore}
                />
              </Tab>
              <Tab eventKey="gallery" title="Gallery" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <ImageGallery imageData={demoData?.attachments?.images} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="videos" title="Videos" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <VideoGallery videoData={demoData?.attachments?.videos} />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="docs" title="Docs" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <UploadedDocuments
                      docData={demoData?.attachments?.documents}
                    />
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="link" title="Links" className="m-4 mt-0">
                <Card className="border-top-0 rounded-top-0">
                  <Card.Body>
                    <LinksContent LinksData={demoData?.attachments?.links} />
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </div>
        )}

        <RegisterModal
          show={modalShow}
          setshow={setModalShow}
          modaldata={modalData}
        />
      </Container>
    </>
  );
}
export default withRouter(DemoDetails);
