import React from "react";
import { Container, Nav } from "react-bootstrap";
import {
  BookHalf,
  HddStackFill,
  PersonVideo3
} from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import "./dashboard.css";

const MyDashboard = () => {
  const location = useLocation(); 

  const siteName = SubDomainRouter("title");
  const demosAlias = SubDomainRouter("demosAlias");
  const coursesAlias = SubDomainRouter("coursesAlias");

  return (
    <>
      <Helmet>
        <title>My Dashboard | {siteName}</title>
      </Helmet>

      <div className="mt-4 pt-5 dashboardSec">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <div className="d-flex flex-md-column pb-md-5 gap-md-0 gap-4 justify-content-center">
              <Nav.Item>
                <Link
                  to="/dashboard/my-demos"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/dashboard/my-demos" && "active"
                  }`}
                >
                  <PersonVideo3 /> {demosAlias}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/dashboard/my-courses"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/dashboard/my-courses" && "active"
                  }`}
                >
                  <BookHalf /> {coursesAlias}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/dashboard/my-groups"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/dashboard/my-groups" && "active"
                  }`}
                >
                  <HddStackFill /> My Groups
                </Link>
              </Nav.Item> 
            </div>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default MyDashboard;
