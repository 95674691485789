import React, { Component } from "react";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import "./form.css";

class Get_Team extends Component {
  entities = "teams";

  state = {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    country: "",
    state: "",
    city: "",
    linkedin: "",
    trainer_bio: "",
    years_experience: "",
    skillset_speciality: "",
    specialization: "",
    modeOfTraining: "",
    institutes_associated: "",
    corporate_experience: "",
    achievements: "",
    past_training_sessions: "",
    past_projects: "",
    proposed_plan_for_training: "",
    courses_offered: "",
    video_links: "",
    demo_className_slot: "",
    photo: "",
  };

  componentDidMount() {
    console.log("Props:", this.props);
    this.populateList(this.props.params.id);
  }

  render() {
    return (
      <>
        <br />
        <br />
        <h1 className="heading">Details</h1>
        <div className="container">
          <form
            className="offset-sm-2 col-lg-8 row mt-4 form"
            onSubmit={this.handleSubmit}
          >
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">First Name</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="first_name"
                  defaultValue={this.state.first_name}
                  onChange={(e) =>
                    this.setState({ first_name: e.target.value })
                  }
                ></input>

                <input
                  type="hidden"
                  className="form-control input2"
                  name="id"
                  defaultValue={this.state.id}
                ></input>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Last Name</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="last_name"
                  defaultValue={this.state.last_name}
                  onChange={(e) => this.setState({ last_name: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Name</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="name"
                  defaultValue={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                ></input>
                <input
                  type="hidden"
                  className="form-control input2"
                  name="id"
                  defaultValue={this.state.id}
                ></input>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Email</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="email"
                  defaultValue={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></input>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Phone</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="phone"
                  defaultValue={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Whatsapp</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="whatsapp"
                  defaultValue={this.state.whatsapp}
                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Website</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="website"
                  defaultValue={this.state.website}
                  onChange={(e) => this.setState({ website: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Country</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="country"
                  defaultValue={this.state.country}
                  onChange={(e) => this.setState({ country: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">State</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="state"
                  defaultValue={this.state.state}
                  onChange={(e) => this.setState({ state: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">City</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="city"
                  defaultValue={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">LinkedIn</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="linkedin"
                  defaultValue={this.state.linkedin}
                  onChange={(e) => this.setState({ linkedin: e.target.value })}
                ></input>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Years of Experience
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="years_experience"
                  defaultValue={this.state.years_experience}
                  onChange={(e) =>
                    this.setState({ years_experience: e.target.value })
                  }
                ></input>
              </div>
            </div>

            <div className="col-lg-12 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Trainer Bio</label>
              <div className="col-lg-12">
                <textarea
                  rows="4"
                  className="form-control input2"
                  disabled
                  name="trainer_bio"
                  defaultValue={this.state.trainer_bio}
                  onChange={(e) =>
                    this.setState({ trainer_bio: e.target.value })
                  }
                ></textarea>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Skillset/Speciality
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="skillset_speciality"
                  defaultValue={this.state.skillset_speciality}
                  onChange={(e) =>
                    this.setState({ skillset_speciality: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Specialization</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="specialization"
                  defaultValue={this.state.specialization}
                  onChange={(e) =>
                    this.setState({ specialization: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Mode of Training
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="modeOfTraining"
                  defaultValue={this.state.modeOfTraining}
                  onChange={(e) =>
                    this.setState({ modeOfTraining: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Institutes Associated
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="institutes_associated"
                  defaultValue={this.state.institutes_associated}
                  onChange={(e) =>
                    this.setState({ institutes_associated: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Corporate Experience
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="corporate_experience"
                  defaultValue={this.state.corporate_experience}
                  onChange={(e) =>
                    this.setState({ corporate_experience: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Achievements</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="achievements"
                  defaultValue={this.state.achievements}
                  onChange={(e) =>
                    this.setState({ achievements: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Past Training Sessions
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="past_training_sessions"
                  defaultValue={this.state.past_training_sessions}
                  onChange={(e) =>
                    this.setState({ past_training_sessions: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Past Projects</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="past_projects"
                  defaultValue={this.state.past_projects}
                  onChange={(e) =>
                    this.setState({ past_projects: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Courses Offered</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="courses_offered"
                  defaultValue={this.state.courses_offered}
                  onChange={(e) =>
                    this.setState({ courses_offered: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">Video Links</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="video_links"
                  defaultValue={this.state.video_links}
                  onChange={(e) =>
                    this.setState({ video_links: e.target.value })
                  }
                ></input>
              </div>
            </div>

            <div className="col-lg-12 col-md-6 col-sm-12 my-2">
              <label className="col-sm-8 col-form-label">
                Proposed Plan for Training
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="proposed_plan_for_training"
                  defaultValue={this.state.proposed_plan_for_training}
                  onChange={(e) =>
                    this.setState({
                      proposed_plan_for_training: e.target.value,
                    })
                  }
                ></input>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <label className="col-sm-8 col-form-label">
                Demo Class Name/Slot
              </label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="demo_className_slot"
                  defaultValue={this.state.demo_className_slot}
                  onChange={(e) =>
                    this.setState({ demo_className_slot: e.target.value })
                  }
                ></input>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <label className="col-sm-8 col-form-label">Photo</label>
              <div className="col-lg-12">
                <input
                  className="form-control input2"
                  disabled
                  name="photo"
                  defaultValue={this.state.photo}
                  onChange={(e) => this.setState({ photo: e.target.value })}
                ></input>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

  async populateList(id) {
    try {
      const data = await fetch(
        `${Actions.BASE_URL}${this.entities}/${id}`
      ).then((response) => {
        console.log(response);
        return response.json();
      });

      this.setState(data);
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  }
}

export default withRouter(Get_Team);
