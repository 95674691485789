import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Share } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import img2 from "../../img/digital-trainers-1.png";
import CustomLoader from "../loader/loader";
import StarRating from "../starRating/starRating";
import "./ReviewCard.css";

function Review() {
  const entities = "reviews";

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const populateList = async () => {
    try {
      await axios.get(`${Actions.BASE_URL}${entities}`).then((response) => {
        setItems(response.data);
        setLoading(false);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const homeReviewsData = items.slice(0, 3);

  return (
    <section className="reviewsSection section">
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg">
          <h2 className="main-heading text-center">Top Reviews</h2>
          <Row className="justify-content-center mx-0">
            {homeReviewsData?.length > 0 ? (
              homeReviewsData.map((item) => (
                <Col xl={4} md={6} sm={10} key={item.id} className="mt-2 mb-3">
                  <Card className="my-3 reviewCard d-flex justify-content-center align-reviewDatas-center">
                    <div className="card-image px-3 pt-2">
                      <Card.Img
                        src={item.hero_image_url ? item.hero_image_url : img2}
                        alt="Load Error"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = img2;
                        }}
                        width="100%"
                      />
                    </div>
                    <Card.Body className="text-center py-0 d-flex flex-column justify-content-between">
                      <div className="top-cont">
                        <StarRating rating={item.star_rating} />
                        <Link
                          to={`/share/${item.type}/${item.id}`}
                          className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                        >
                          <Share />
                        </Link>
                        <Card.Title className="mt-2 h6">
                          ~ By&nbsp;
                          <b>
                            {item.reviewer_first_name}&nbsp;
                            {item.reviewer_last_name}
                          </b>
                        </Card.Title>
                        <Card.Text className="mb-sm-2 mb-0">
                          <b>{item.review_title}</b>{" "}
                        </Card.Text>
                        <Card.Text className="mb-0 text-overflow-desc">
                          <q>{item.review_comments} </q>
                        </Card.Text>
                      </div>

                      <div className="itemEdit d-flex align-items-center justify-content-center flex-wrap gap-2">
                        <Link
                          to={`/${entities}/details/${item.entityTypeAssociated}/${item.entityIdAssociated}/${item.id}`}
                          className="actionBtn btn action cursor-pointer"
                          title="courses"
                        >
                          View More
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No Reviews Yet</p>
            )}
          </Row>
        </Container>
      )}
    </section>
  );
}

export default Review;
