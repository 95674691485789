import React, { Component } from "react";
import * as Actions from "../../constants/baseUrl";
import MaterialReactTable from "material-react-table";
import { MenuItem } from "@mui/material";
import axios from "axios";
import "./style.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

export default class Projects extends Component {
  static displayName = Projects.name;

  entities = "projects";

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      isForm: false,
    };
  }

  componentDidMount() {
    this.populateList();
  }

  deleteItem(id) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          className: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${this.entities}/${id}`)
              .then((res) => {
                this.populateList();
              });
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  }

  itemList(items) {
    const siteName = SubDomainRouter("title");
    return (
      <div className="container-lg">
        <Helmet>
          <title>Projects | {siteName}</title>
        </Helmet>

        <div className="col-lg-12 row">
          {items.map((item) => (
            <div className="col-lg-4 mt-2" key={item.id}>
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">{item.name}</h5>
                  <div className="card-actions">
                    <a
                      onClick={(event) =>
                        (window.location.href = `/${this.entities}/edit/${item.id}`)
                      }
                    >
                      <i className="fa fa-edit mx-3"></i>
                    </a>
                    <a onClick={() => this.deleteItem(item.id)}>
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <b>Type: </b>
                    {item.type}
                  </p>
                  <p className="card-text">
                    <b>Email: </b>
                    {item.email}
                  </p>
                  <p className="card-text">
                    <b>Phone: </b>
                    {item.phone}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.itemList(this.state.items)
    );

    return (
      <div>
        <br />
        <br />
        <div className="container-lg">
          <div className="box1">
            <div className="offset-sm-1 col-lg-10 ">
              <h3 id="tabelLabel">Ameerpet IT - Projects</h3>
              <p>Check out the Project...</p>
            </div>
            <div className="col-lg-2 float-end">
              <input
                type="button"
                value="Add"
                className="form-control btn btn-success  add-button" // Added "add-button" class for styling
                onClick={(event) =>
                  (window.location.href = `/${this.entities}/add`)
                }
              />
            </div>
          </div>
          <div className="col-lg-12">{contents}</div>
        </div>
      </div>
    );
  }

  async populateList() {
    try {
      const data = await fetch(`${Actions.BASE_URL}${this.entities}`).then(
        (response) => {
          console.log(response);
          return response.json();
        }
      );
      this.setState({ items: data, loading: false });
    } catch (error) {
      console.log("There was an error", error);
    }
  }
}
