import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import DemoRegistration from "./components/landing/demoregistration";
import ScheduleDemo from "./components/landing/scheduledemo";
import TrainerIntro from "./components/landing/trainerintro";
import LoginSuccess from "./components/login/loginsuccess";
import MyHome from "./components/myhome";
import { OIDC_CONFIG } from "./constants/baseUrl";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AuthPage from "./pages/auth/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider {...OIDC_CONFIG}>
        {window.location.pathname.indexOf("myhome") > -1 ? (
          <MyHome />
        ) : window.location.pathname.indexOf("trainerintro") > -1 ? (
          <TrainerIntro />
        ) : window.location.pathname.indexOf("scheduledemo") > -1 ? (
          <ScheduleDemo />
        ) : window.location.pathname.indexOf("demoregistration") > -1 ? (
          <DemoRegistration />
        ) : window.location.pathname.indexOf("loginsuccess") > -1 ? (
          <LoginSuccess />
        ) : localStorage.getItem("isValidUser") === true ||
          localStorage.getItem("isValidUser") === "true" ? (
          <App />
        ) : (
          <AuthPage />
        )}
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
