import { Wifi } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";

export const ErrorAlert = (message) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
          <Wifi className="display-1 theme-secondary" />
          <h5 className="text-danger mb-0 text-capitalize">{message}</h5>
          <p className="text-secondary">
            <small>Try Again</small>
          </p>
          <button className="btn add-button mt-2" onClick={onClose}>
            Discard
          </button>
        </div>
      );
    },
  });
};
