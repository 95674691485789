import React from "react";
import "./Slider.css";
// Import Swiper styles
import { Container } from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import swiper3 from "../../img/SWIPERIMG3-min.jpg";
import swiper2 from "../../img/swiperIMG2-min.jpg";
import swiper1 from "../../img/swiperImg1-min.jpg";
import SearchBar from "./SearchBar";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";

function Slider({ setSearchQuery, searchQuery }) {
  const currentDomain = window.location.hostname;

  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  return (
    <section className="heroSection position-relative">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={false}
        speed={900}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide
          className="d-flex align-items-center"
          style={{
            backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${swiper1})`,
          }}
        ></SwiperSlide>
        <SwiperSlide
          className="d-flex align-items-center"
          style={{
            backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${swiper2})`,
          }}
        ></SwiperSlide>
        <SwiperSlide
          className="d-flex align-items-center"
          style={{
            backgroundImage: `radial-gradient(#00000099, #0712128c, #00000074), url(${swiper3})`,
          }}
        ></SwiperSlide>
      </Swiper>
      <Container fluid="lg" className="heroContentWrap">
        <div className="heroContent">
          {currentDomain === "events.indianstartups.us" ||
          currentDomain === "events.indianstartups.com" ? (
            <h1>search</h1>
          ) : (
            <h1>
              search&nbsp;
              {demosAlias}
              <br /> <span>{coursesAlias}...</span>
            </h1>
          )}
          {currentDomain === "events.indianstartups.us" ||
          currentDomain === "events.indianstartups.com" ? (
            <p>Search startup events and training programs</p>
          ) : (
            <p>
              Our online courses are built in partnership with technology
              leaders and are designed to meet industry demands.
            </p>
          )}
          <SearchBar setSearchQuery={setSearchQuery} />
          {searchQuery !== "" && (
            <p className="text-start mb-0 searchNote">
              Searching for&nbsp;
              <span className="text-decoration-underline">
                {searchQuery}...
                {console.log(searchQuery)}
              </span>
            </p>
          )}
        </div>
      </Container>
    </section>
  );
}

export default Slider;
