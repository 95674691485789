import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";

const EnquiryBodyContent = (
  selectedItemDetails,
  formData,
  demosAlias,
  coursesAlias,
  trainersListID
) => {
  const myHost = window.location.hostname;

  const demos = selectedItemDetails?.filter((item) => item?.type === "demo");
  const courses = selectedItemDetails?.filter(
    (item) => item?.type === "course"
  );

  console.log(demos);

  const generateHTML = (items) => {
    return items
      ?.map((detail, index) => {
        return `
        <table width="100%" border="0" cellspacing="0" cellpadding="0" key="${index}">
          <tr>
            <td height="16"></td>
          </tr>
          <tr>
            <td width="100%" align="center">
              <table width="560" border="0" cellspacing="0" cellpadding="0"
                style="border-radius: 12px;     box-shadow: 0 1px 20px 0 #455a6433;">
                <tr>
                  <td height="8"></td>
                </tr>
                <tr>
                  <td width="8"></td>
                  <td align="center" width="554">
                    <img src="${
                      detail?.listing_image_url || detail?.hero_image_url
                    }"
                      alt="Load Error" width="100%" height="100%" loading="lazy"
                      style="object-fit: cover; border-radius: 10px; box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.06);">
                  </td>
                  <td width="8"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="12"></td>
                  <td width="560" align="center">
                    <span
                      style="font-family: 'Unbounded', sans-serif; color: #43a39f; text-decoration: none; font-size: 1.25rem; font-weight: 500; line-height: normal;">
                      ${detail?.title}
                    </span>
                  </td>
                  <td width="12"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="8"></td>
          </tr>
          <tr>
            <td align="center">
              <span style="font-size: 12px;">
                ${detail?.start_date} - ${detail?.start_time} <br>
                ${ConvertMinutesToTime({
                  minutes: detail?.duration,
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td height="15"></td>
          </tr>
          <tr>
            <td align="center">
              <a href="${`https://${myHost}/register/${detail?.type}/${detail?.id}`}"
                style="font-size: 14px; font-weight: 500; padding:6px 18px; text-decoration: none; border: 2px solid transparent; background-color: #43a39f; border-radius: 4px; color: #fff;">
                Register
              </a>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="18"></td>
                  <td width="560" align="center" style="line-height: 1.3;">
                    <span style="font-size: 12px; text-align:center; text-wrap: balance;
                    white-space: pre-line;">
                      ${detail?.description}
                    </span>
                  </td>
                  <td width="18"></td>
                </tr>
                <tr>
                  <td height="24"> </td>
                </tr>
              </table>
              <hr width="90%">
            </td>
          </tr>
          <tr>
            <td height="24"> </td>
          </tr>
        </table>
      `;
      })
      .join("");
  };

  const demosHTML = generateHTML(demos);
  const coursesHTML = generateHTML(courses);

  const htmlContent = `

  <!-- ***Body*** -->

  <table width="586px" style="max-width: 586px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;"> 
      <tr>
          <td height="24"></td>
      </tr>
       <tr>
          <td style="font-size: 16px; color: #222; font-weight: 600; font-family: 'Poppins', sans-serif;">
             Hello,
          </td>
      </tr>
      <tr>
          <td height="6"></td>
      </tr>
      <tr>
          <td style="text-wrap: balance; font-size: 12px; color: #333; font-weight: 500; font-family: 'Poppins', sans-serif;">
             A user  is interested in obtaining information about the following  ${
               demosHTML && `Upcoming ${demosAlias}`
             } 
              ${coursesHTML && `Upcoming ${coursesAlias}`}
          </td>
      </tr>
      <tr>
          <td height="12"></td>
      </tr>
  </table>

    <!-- Entity cards -->
    <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
        <td>
          <!-- Demos -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              demosHTML &&
              ` 
              <tr>
                <td>
                  ${demosHTML}
                </td>
              </tr>
            `
            }
          </table>
          
          <!-- Courses -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              coursesHTML &&
              ` 
              <tr>
                <td>
                  ${coursesHTML}
                </td>
              </tr>
            `
            }
          </table>
        </td>
      </tr>
    </table>

     <!-- User Details -->
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center">
        <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td style="text-wrap: balance; font-size: 14px; color: #333; font-weight: 600; font-family: 'Poppins', sans-serif;">
                User Details:
             </td>
        </tr>
        <tr>
            <td height="8"></td>
        </tr>
    </table>
     
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee"> 
         <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td width="12"></td>
            <td>
                <table width="574px"
                    style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
                    border="0" cellspacing="0" cellpadding="0" align="center">
                    <tr>
                        <td>
                            <span>
                                <strong>Name:</strong>&nbsp; 
                                ${formData?.first_name} ${formData?.last_name}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="6"></td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                <strong>Email Address:</strong>&nbsp; ${
                                  trainersListID ? "Available" : formData?.email
                                }
                            </span>
                        </td>
                    </tr>
                    ${
                      formData?.website
                        ? `
                        <tr>
                            <td height="6"></td>
                        </tr>  
                        <tr>
                            <td>
                                <span>
                                    <strong>Website:</strong>&nbsp; ${formData?.website}
                                </span>
                            </td>
                        </tr>
                        `
                        : ""
                    }
                    ${
                      formData?.phone
                        ? `
                        <tr>
                            <td height="6"></td>
                        </tr>
                        <tr>
                            <td>
                                <span>
                                    <strong>Phone No.:</strong>&nbsp; ${
                                      trainersListID
                                        ? "Available"
                                        : formData?.phone
                                    }
                                </span>
                            </td>
                        </tr>
                     `
                        : ""
                    }
                    ${
                      formData?.whatsapp
                        ? `
                        <tr>
                            <td height="6"></td>
                        </tr>
                        <tr>
                            <td>
                                <span>
                                    <strong>Whatsapp No.:</strong>&nbsp; ${
                                      trainersListID
                                        ? "Available"
                                        : formData?.whatsapp
                                    }
                                </span>
                            </td>
                        </tr>
                     `
                        : ""
                    }
                    ${
                      formData?.bio
                        ? `
                        <tr>
                            <td height="6"></td>
                        </tr>
                        <tr>
                            <td>
                                <span>
                                    <strong>BIO:</strong>&nbsp; ${formData?.bio}
                                </span>
                            </td>
                        </tr>
                     `
                        : ""
                    }
                    ${
                      formData?.message
                        ? `
                        <tr>
                            <td height="6"></td>
                        </tr>
                        <tr>
                            <td>
                                <span>
                                    <strong>Message:</strong>&nbsp; ${formData?.message}
                                </span>
                            </td>
                        </tr>
                     `
                        : ""
                    }
                </table>
            </td>
            <td width="12"></td>
        </tr>
        <tr>
            <td height="16"></td>
        </tr>
    </table>
  `;

  return htmlContent;
};

export default EnquiryBodyContent;
