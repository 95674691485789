import axios from "axios";
import React, { useEffect, useState } from "react";
import * as Actions from "../../constants/baseUrl";
import CustomLoader from "../../components/loader/loader";
import Courses from "../../components/homeSegments/Courses";
import Institute from "../../components/homeSegments/Institute";
import Review from "../../components/homeSegments/Review";
import SearchedCard from "../../components/homeSegments/SearchedCard";
import Slider from "../../components/homeSegments/Slider";
import Trainers from "../../components/homeSegments/Trainers";
import Demos from "../../components/homeSegments/demos";
import "./home.css";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const Home = () => {
  const [itemSearched, setItemSearched] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const populateList = async () => {
    setLoading(true);
    if (searchQuery.trim() === "") {
      return null;
    }
    
    try {
      await axios
        .get(`${Actions.BASE_URL}search/${searchQuery}`)
        .then((response) => {
          setItemSearched(response.data);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, [searchQuery]);

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
      </Helmet>

      <Slider setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      {searchQuery === "" ? (
        <>
          <Demos />
          <Courses />
          {/* <Trainers /> */}
          {/* <Institute /> */}
          <Review />
        </>
      ) : (
        <>
          {loading ? (
            <CustomLoader />
          ) : (
            <SearchedCard itemSearched={itemSearched} />
          )}
        </>
      )}
    </>
  );
};

export default Home;
