import React from "react";
import { Container, Nav } from "react-bootstrap";
import { PeopleFill, ShieldLockFill, Upload } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const AdminPage = () => {
  const location = useLocation();

  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>Admin | {siteName}</title>
      </Helmet>

      <div className="mt-4 pt-5 dashboardSec">
        <div className="d-flex flex-md-row flex-column">
          <div className="dashboard-nav px-0 py-md-5">
            <div className="d-flex flex-md-column pb-md-5 gap-md-0 gap-4 justify-content-center">
              <Nav.Item>
                <Link
                  to="/admin/users"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/admin/users" && "active"
                  }`}
                >
                  <PeopleFill /> Users
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/admin/configs"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/admin/configs" && "active"
                  }`}
                >
                  <ShieldLockFill /> Configs
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/admin/upload"
                  className={`nav-link d-flex text-capitalize align-items-center flex-column gap-1 text-center ${
                    location.pathname === "/admin/upload" && "active"
                  }`}
                >
                  <Upload /> Uploader
                </Link>
              </Nav.Item>
            </div>
          </div>
          <Container fluid="lg" className="px-lg-4 pb-5 pt-5 flex-grow-1">
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
