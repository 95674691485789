import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import AddTag from "../tagInput/tagInput";
import CampaignRelations from "../dynamicRelationFieldSet/campaignRelations";
import EmailFooter from "../emailTemplate/emailFooter";
import EmailHeader from "../emailTemplate/emailHeader";
import EmailBodyContent from "../emailTemplate/emailBody";
import CustomLoader from "../loader/loader";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import TokenDetails from "../useUserDetails/useTokenDetails";

const CampaignDraft = (props) => {
  let navigate = useNavigate();
  
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const fromEmail = SubDomainRouter("fromEmail");
  const campaignId = props?.params?.id;

  const relationStructure = [{ type: "", value: "" }];

  const [loadingPopulate, setLoadingPopulate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [relationData, setRelationData] = useState(relationStructure || []);

  const [formData, setFormData] = useState({
    campaign_name: "Campaign Name",
    description: "Campaign Description",
    userId: myUserId,
    type: "campaign",
    emaillists: [],
    entityRelation: [],
    subject: "",
    body_header: "",
    body: "",
    body_footer: "",
    body_plain_text: "",
    from: fromEmail,
    to: [],
    cc: [],
    bcc: [],
    scheduled_date: "",
    scheduled_time: "",
    campaign_sent_status: "",
    tags: [],
    comments: "",
  });

  const [fromUserEmail, setFromUserEmail] = useState(null);
  const [addedIDs, setAddedIDs] = useState([]);
  const [addedCC, setAddedCC] = useState([]);
  const [addedBCC, setAddedBCC] = useState([]);

  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [convertedUTCTime, setConvertedUTCTime] = useState("");
  const [startTime, setStartTime] = useState(new Date());

  const [hasMessage, setHasMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const [selectedItemDetails, setSelectedItemDetails] = useState("");
  // Email part
  const [emailHeaderContent, setEmailHeaderContent] = useState(undefined);
  const [emailBodyContent, setEmailBodyContent] = useState(undefined);
  const [emailFooterContent, setEmailFooterContent] = useState(undefined);

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };

    setFormData(newState);
  };

  const populateForm = () => {
    axios
      .get(`${Actions.BASE_URL}campaigns/${campaignId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData(res.data);
        setRelationData(res.data?.entityRelation || relationStructure);
        setAddedIDs(res.data?.to);
        setAddedCC(res.data?.cc);
        setAddedBCC(res.data?.bcc);
        setLoadingPopulate(false);
      })
      .catch((error) => {
        setLoadingPopulate(false);
        console.log(error.response);
      });
  };

  useEffect(() => {
    populateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  // Save Template data
  const handleTemplateSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const templateFormData = {
      userId: myUserId,
      template_name: formData?.template_name,
      subject: formData?.subject,
      body: formData?.body || emailBodyContent,
      body_plain_text: formData?.body_plain_text,
      body_header: formData?.body_header || emailHeaderContent,
      body_footer: formData?.body_footer || emailFooterContent,
    };
    axios
      .post(`${Actions.BASE_URL}templates`, templateFormData, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        successAlert("Template");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const mainTimeZones = [
    "Asia/Kolkata",
    "Asia/Karachi",
    "Europe/London",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "Australia/Sydney",
    "Europe/Berlin",
    "Asia/Tokyo",
    "Africa/Johannesburg",
    "Europe/Paris",
    "America/Toronto",
    "Asia/Dubai",
    "America/Denver",
    "Asia/Hong_Kong",
    "America/Mexico_City",
    "Europe/Madrid",
    "Asia/Singapore",
    "America/Phoenix",
    "Australia/Perth",
    "Africa/Cairo",
    "Asia/Kuala_Lumpur",
  ];

  const handleTimeZoneChangeURL = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    convertToUTC(eventTime, selectedOption?.value);
  };

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment
      .tz(givenTime, "HH:mm", timeZone)
      .utc()
      .format("HH:mm");
    setConvertedUTCTime(utcTime);

    // console.log(utcTime, timeZone, givenTime);
  };

  const timeZones = moment.tz
    .names()
    .filter((tz) => mainTimeZones.includes(tz))
    .map((tz) => ({
      label: tz,
      value: tz,
    }));
  const handleEventTimeChange = (e) => {
    const newTime = startTime;
    setEventTime(newTime);

    if (selectedTimeZone) {
      convertToUTC(newTime, selectedTimeZone.value);
    }
  };

  useEffect(() => {
    handleEventTimeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, selectedTimeZone]);

  useEffect(() => {
    handleFieldChange("scheduled_time", convertedUTCTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedUTCTime]);

  // Steps
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleDraft = () => {
    setLoading(true);
    const data = {
      ...formData,
      campaign_sent_status: "draft",
      entityRelation: relationData,
      body: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      to: [...addedIDs],
      cc: [...addedCC, fromUserEmail],
      bcc: addedBCC,
      from: fromEmail,
    };
    axios
      .put(`${Actions.BASE_URL}campaigns/${campaignId}`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        navigate("/campaigns-dashboard/drafts");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const handleSubmit = () => {
    setLoading(true);

    const data = {
      ...formData,
      entityRelation: relationData,
      campaign_sent_status: "yes",
      body: `${emailHeaderContent} ${emailBodyContent} ${emailFooterContent}`,
      to: addedIDs,
      cc: [...addedCC, fromUserEmail],
      bcc: addedBCC,
      from: fromEmail,
    };
    axios
      .put(`${Actions.BASE_URL}campaigns/${campaignId}`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        successCampaignAlert("campaign");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2"> Saved As {param}</h5>
            <button className="btn add-button mt-2" onClick={onClose}>
              Done
            </button>
          </div>
        );
      },
    });
  };

  const successCampaignAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              {param} sent successfully
            </h5>
            <button
              className="btn add-button mt-2"
              onClick={() => {
                navigate("/campaigns-dashboard/drafts");
                onClose();
              }}
            >
              Done
            </button>
          </div>
        );
      },
    });
  };

  // Call Req fun initially
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logo = SubDomainRouter("logo");
  const title = SubDomainRouter("title");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const mappedBodyData = EmailBodyContent(
    selectedItemDetails,
    customMessage,
    demosAlias,
    coursesAlias
  );
  const generateEntityDetails = (details) => {
    if (!details || details.length === 0) return "";

    const isUndefined = details?.some((detail) => detail === undefined);

    setSelectedItemDetails(details);

    if (!isUndefined) {
      console.log(
        "undef",
        details?.some((detail) => detail === undefined)
      );
      setEmailBodyContent(mappedBodyData);
      handleFieldChange("body", mappedBodyData);
    }
  };

  useEffect(() => {
    const header_email_content = EmailHeader(logo, demosAlias, coursesAlias);
    const footer_email_content = EmailFooter(logo, title);
    setEmailHeaderContent(header_email_content);
    setEmailFooterContent(footer_email_content);

    const newState = {
      ...formData,
      body_header: header_email_content,
      body_footer: footer_email_content,
    };

    setFormData(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingPopulate ? (
        <CustomLoader />
      ) : (
        <Container
          fluid="lg"
          className="formMain create demosAddFormSection demosEditSection my-5"
        >
          {loading ? (
            <>
              <span className="register-loader"> </span>
              <span className="spinnerOverlay"></span>
            </>
          ) : null}
          <div className="demosAddFormHeader text-center">
            <h2 className="addFormHeading theme-text-2">Campaign Draft</h2>
            <div className="demosAddFormHeader text-center my-3 mb-lg-5">
              <ul className="demosEditList">
                <li>
                  <button
                    className={`stepstriggers ${step === 1 ? "active" : null}`}
                    onClick={() => setStep(1)}
                  >
                    <span>1 </span>
                  </button>
                </li>
                <li>
                  <button
                    className={`stepstriggers ${step === 2 ? "active" : null}`}
                    onClick={() => setStep(2)}
                  >
                    <span>2</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`stepstriggers ${step === 3 ? "active" : null}`}
                    disabled={addedIDs?.length === 0}
                    onClick={() => setStep(3)}
                  >
                    <span>3</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`stepstriggers ${step === 4 ? "active" : null}`}
                    disabled={addedIDs?.length === 0}
                    onClick={() => setStep(4)}
                  >
                    <span>4</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="wizard-fieldset demosAddForm py-5">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {/* Step 1 */}
              <Row className={`mx-0 ${step === 1 ? "d-block" : "d-none"}`}>
                <Col md={12} className="d-flex justify-content-between gap-3">
                  <h5 className="theme-text flex-grow-1">
                    Select relevant course(s) or demo(s)
                  </h5>
                  <Button
                    className="clickable addStyle mb-2 ms-auto d-flex"
                    onClick={() => {
                      const newField = { type: "", value: "" };
                      setRelationData([...relationData, newField]);
                    }}
                  >
                    <span className="addBttn d-block">+</span>
                  </Button>
                </Col>
                <hr />
                <Col md={12}>
                  {relationData?.map((field, index) => (
                    <CampaignRelations
                      key={index}
                      fields={relationData}
                      field={field}
                      index={index}
                      onEntityDetailsChange={(selectedItems) => {
                        generateEntityDetails(selectedItems);
                      }}
                      onFieldChange={(newFields) => {
                        setRelationData(newFields);
                      }}
                      fieldLabels={{
                        valueInput: "Select An Entity",
                      }}
                      selectOptions={[
                        { value: "demo", label: demosAlias },
                        { value: "course", label: coursesAlias },
                      ]}
                    />
                  ))}
                </Col>
                <Col md={12} className="mt-3">
                  <Link
                    to={"/campaigns-dashboard/drafts"}
                    type="button"
                    className="previous-button demosEditBtn w-auto btn"
                  >
                    Discard
                  </Link>
                  <Button
                    type="button"
                    onClick={handleNext}
                    className="next demosEditBtn w-auto d-flex"
                  >
                    Next
                  </Button>
                </Col>
              </Row>

              {step === 2 && (
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text mb-2">
                      Select Email Lists & Sender Info
                    </h5>
                  </Col>
                  <hr />
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        className="italicInput"
                        id="from"
                        required
                        placeholder="From"
                        name="from"
                        value={fromUserEmail || ""}
                        onChange={(e) => setFromUserEmail(e.target.value)}
                      />
                      <Form.Label className="formLabel" htmlFor="from">
                        From
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <AddTag
                      label={"To"}
                      addedTags={addedIDs}
                      setAddedTags={setAddedIDs}
                    />
                  </Col>
                  <Col ms={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleNext}
                      disabled={addedIDs?.length === 0}
                      className="next demosEditBtn"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              )}
              {step === 3 && (
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text mb-2">
                      Compose Email from Template
                    </h5>
                  </Col>
                  <hr />
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Template Name"
                        className="form-control italicInput"
                        name="template_name"
                        value={formData?.template_name || ""}
                        id="template_name"
                        onChange={(e) =>
                          handleFieldChange("template_name", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="template_name"
                      >
                        Template Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="italicInput"
                        id="subject"
                        required
                        placeholder="Enter Event Subject"
                        name="subject"
                        value={formData?.subject || ""}
                        onChange={(e) =>
                          handleFieldChange("subject", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="subject">
                        Subject
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as={"textarea"}
                        rows={"4"}
                        placeholder="body_header"
                        className="form-control max-h-auto"
                        name="body_header"
                        value={formData?.body_header}
                        id="body_header"
                        onChange={(e) =>
                          handleFieldChange("body_header", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="body_header">
                        Body Header
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="d-flex gap-1 align-items-baseline">
                    <Form.Check
                      name="has_message"
                      checked={hasMessage}
                      id="has_message"
                      onChange={() => {
                        setHasMessage(!hasMessage);
                      }}
                    />
                    <Form.Label
                      className="theme-text-2 fw-normal"
                      htmlFor="has_message"
                    >
                      Add a custom message
                    </Form.Label>
                  </Col>
                  {hasMessage && (
                    <Col md={12}>
                      <Form.Group className="form-floating mb-3">
                        <Form.Control
                          as={"textarea"}
                          rows={"4"}
                          placeholder="body_custom_message"
                          className="form-control max-h-auto"
                          name="body_custom_message"
                          value={customMessage}
                          id="body_custom_message"
                          onChange={(e) => setCustomMessage(e.target.value)}
                        />
                        <Form.Label
                          className="form-label"
                          htmlFor="body_custom_message"
                        >
                          Body Custom Message
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as={"textarea"}
                        rows={formData?.body ? "12" : "4"}
                        placeholder="body"
                        className="form-control max-h-auto"
                        name="body"
                        value={formData?.body}
                        id="body"
                        onChange={(e) =>
                          handleFieldChange("body", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="body">
                        Body Content
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as={"textarea"}
                        rows={"4"}
                        placeholder="body_footer"
                        className="form-control max-h-auto"
                        name="body_footer"
                        value={formData?.body_footer}
                        id="body_footer"
                        onChange={(e) =>
                          handleFieldChange("body_footer", e.target.value)
                        }
                      />
                      <Form.Label className="form-label" htmlFor="body_footer">
                        Body Footer
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as={"textarea"}
                        rows={"4"}
                        placeholder="body_plain_text"
                        className="form-control max-h-auto"
                        name="body_plain_text"
                        value={formData?.body_plain_text}
                        id="body_plain_text"
                        onChange={(e) =>
                          handleFieldChange("body_plain_text", e.target.value)
                        }
                      />
                      <Form.Label
                        className="form-label"
                        htmlFor="body_plain_text"
                      >
                        Body Plain Text
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col ms={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn w-auto"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <div className="next d-flex gap-2">
                      <Button
                        type="button"
                        onClick={handleTemplateSubmit}
                        className="demosEditBtn text-nowrap"
                      >
                        Save As Template
                      </Button>
                      <Button
                        type="button"
                        className="demosEditBtn"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              {step === 4 && (
                <Row className="mx-0">
                  <Col md={12}>
                    <h5 className="theme-text mb-2">Send Campaign</h5>
                  </Col>
                  <hr />
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mx-auto mb-3">
                      <ReactDatePicker
                        className="form-control form-select w-100"
                        selected={startTime}
                        onChange={(date) => {
                          setStartTime(date);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        multiple=""
                        dateFormat="h:mm aa"
                      />
                      {startTime == null && (
                        <Form.Label className="formLabel" htmlFor="time">
                          Select a time to send this campaign
                        </Form.Label>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="batch">
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mx-auto mb-3">
                      <Select
                        className="timezone form-control z-3"
                        required
                        id="timezone"
                        options={timeZones}
                        value={selectedTimeZone}
                        onChange={handleTimeZoneChangeURL}
                        placeholder="Select your time zone"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mx-auto mb-3">
                      <Form.Control
                        type="date"
                        className="italicInput"
                        id="scheduled_date"
                        placeholder="Enter date"
                        name="scheduled_date"
                        value={formData?.scheduled_date}
                        onChange={(e) =>
                          handleFieldChange("scheduled_date", e.target.value)
                        }
                      />
                      <Form.Label
                        className="formLabel"
                        htmlFor="scheduled_date"
                      >
                        Scheduled Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <small className="text-end d-block text-dark">
                      required<span className="text-danger">*</span>
                    </small>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        className="italicInput"
                        id="campaign_name"
                        placeholder="Enter Event Subject"
                        name="campaign_name"
                        value={formData?.campaign_name}
                        onChange={(e) =>
                          handleFieldChange("campaign_name", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="campaign_name">
                        Give a Name to this campaign
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        as="textarea"
                        rows={"4"}
                        id="description"
                        placeholder="Enter Event Subject"
                        name="description"
                        value={formData?.description}
                        onChange={(e) =>
                          handleFieldChange("description", e.target.value)
                        }
                      />
                      <Form.Label className="formLabel" htmlFor="to">
                        Campaign Description
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col ms={12} className="mt-3">
                    <Button
                      type="button"
                      className="previous-button demosEditBtn w-auto"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                    <div className="next-button next d-flex gap-sm-3 gap-2">
                      <Button
                        type="button"
                        className="demosEditBtn text-nowrap"
                        onClick={handleDraft}
                      >
                        Update Draft
                      </Button>
                      <Button type="submit" className="demosEditBtn">
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default withRouter(CampaignDraft);
