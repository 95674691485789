import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";

const GeneralMsgModal = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalmessage === "success" ? "Success" : "Failed"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Check2Circle className="display-1 theme-text" />
          <p>
            You have Subscribed to our Newsletter. Now you will receive our
            latest updates.
          </p>
          <Button
            className="btn add-button float-end mt-3"
            onClick={props.onHide}
          >
            Done
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GeneralMsgModal;
