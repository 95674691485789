import { useState, useEffect } from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import TokenDetails from "./useTokenDetails";

const useUserData = (props) => {
  const entities = "users";
  const [userData, setUserData] = useState({});
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const populateList = async () => {
    if ( 
      localStorage.getItem("isValidUser") === "false" ||
      localStorage.getItem("isValidUser") === false
    ) {
      return setTimeout(() => {
        props?.setLoading(false);
      }, 1000);
    }

    try {
      await axios
        .get(`${Actions.BASE_URL}${entities}/email/${myUserId}`)
        .then((response) => {
          setUserData(response.data);
          props?.setLoading(false);
        });
    } catch (error) {
      props?.setLoading(false);
      console.error("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, [myUserId]);

  return userData;
};

export default useUserData;
