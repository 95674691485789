import { Button, Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import axios from "axios";
import * as Actions from "../../constants/baseUrl";
import { Check2Circle } from "react-bootstrap-icons";
import ContactUpload from "./contactUpload";
import TokenDetails from "../useUserDetails/useTokenDetails";

function UploadModal(props) {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const initialFields = {
    id: uuidv4(),
    userId: myUserId,
    type: "contact",
    filecontent: "",
    list_id: props.uploadListID,
  };

  const [documentData, setDocumentData] = useState(initialFields);

  // const addField = (setField, field) => {
  //   setField([...field, {}]);
  // };
  const removeItemById = (fields, setFields, itemId) => {
    const updatedItems = fields.filter((item) => item.id !== itemId);
    setFields(updatedItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${Actions.BASE_URL}upload`, documentData, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="listUploadModal"
    >
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter text-capitalize">
          Upload Your List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <div className="py-3 d-flex align-items-center flex-column align-items-center gap-3">
            <Check2Circle className="display-1 theme-text" />
            <h5>Successfully Uploaded</h5>
            <Button
              className="next add-button float-end mt-3"
              onClick={() => {
                props.setshow(false);
                setSuccess(false);
                window.location.reload();
              }}
            >
              Done
            </Button>
          </div>
        ) : (
          <>
            <ContactUpload
              key={documentData.id}
              field={documentData}
              listType={props.listtype}
              uploadListID={props.uploadlistid}
              onFieldChange={(newField) => {
                console.log(newField);
                setDocumentData(newField);
              }}
              onRemove={() =>
                removeItemById(documentData, setDocumentData, documentData.id)
              }
              fileCategory="document"
              acceptedTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
              noteLabel="XLSX/CSV"
              inputIdPrefix={`listUpload`}
            />
            {/* <Button
    className="clickable add-button mb-3 ms-auto d-flex"
    onClick={() => addField(setDocumentData, documentData)}
  >
    Add More Documents
  </Button> */}

            <Button className="add-button next mt-3" onClick={handleSubmit}>
              Upload
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default UploadModal;
