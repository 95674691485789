import React from "react";
import "./subscriptions.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const Subscriptions = () => {
  const siteName = SubDomainRouter("title");
  return (
    <>
      <Helmet>
        <title>Subscriptions | {siteName}</title>
      </Helmet>

      <Container fluid="lg" className="subsSec my-5 pt-5">
        <div className="headMain text-center">
          <h2 className="mb-0 mt-3">Sunbscription Plans</h2>
          <p className="mt-0 theme-text-2">
            Choose Your Monthly Subscription Plan
          </p>
        </div>

        <Row className="cards py-5 justify-content-center">
          <Col xl={3} lg={4} md={6} sm={8} xs={11} className="card py-5 shadow">
            <ul className="list-unstyled text-center">
              <li className="pack mb-2">Basic</li>
              <li id="basic" className="price bottom-bar">
                $199.99
              </li>
              <li className="bottom-bar py-2">0 Bonus Courses</li>
              <li className="bottom-bar py-2">5 Courses</li>
              <li className="bottom-bar py-2">2 Users Allowed</li>
              <li className="bottom-bar py-2">Sestore 3 GB</li>
              <Button className="btn-sub">Choose Plan</Button>
            </ul>
          </Col>
          <Col xl={3} lg={4} md={6} sm={8} xs={11} className="card py-5 active">
            <ul className="list-unstyled text-center">
              <li className="pack mb-2">Professional</li>
              <li id="professional" className="price bottom-bar">
                $249.99
              </li>
              <li className="bottom-bar py-2">3 Bonus Courses</li>
              <li className="bottom-bar py-2">50 Courses</li>
              <li className="bottom-bar py-2">5 Users Allowed</li>
              <li className="bottom-bar py-2">Sestore 10 GB</li>
              <Button className="btn-sub active-btn">Choose Plan</Button>
            </ul>
          </Col>
          <Col xl={3} lg={4} md={6} sm={8} xs={11} className="card py-5 shadow">
            <ul className="list-unstyled text-center">
              <li className="pack mb-2">Master</li>
              <li id="master" className="price bottom-bar">
                $399.99
              </li>
              <li className="bottom-bar py-2">10 Bonus Courses</li>
              <li className="bottom-bar py-2">Unlimited Courses</li>
              <li className="bottom-bar py-2">Unlimited Users Allowed</li>
              <li className="bottom-bar py-2">Sestore 50 GB</li>
              <Button className="btn-sub">Choose Plan</Button>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Subscriptions;
