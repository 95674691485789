import React from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import "./intro.css";
import LandingNavBar from "./landingnavbar";
import Footer from "../footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddLogo from "./AddLogo.png";

export default class DemoRegistration extends React.Component {
  state = {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    whatsapp: "",
    city: "",
    currentstatus: "",
    mode: "",
    demofor: "",
  };

  handleSubmit = (event) => {
    event.preventDefault();
    var data = this.state;
    var msg =
      "<p>Below are the New Registration Details</p><p>Name: " +
      data.firstname +
      " " +
      data.lastname +
      "</p><p>Email: " +
      data.email +
      "</p><p>Phone: " +
      data.phone +
      "</p><p>Whatsapp: " +
      data.whatsapp +
      "</p><p>City: " +
      data.city +
      "</p><p>Current Status: " +
      data.currentstatus +
      "</p> <p>Mode of Attending: " +
      data.mode +
      "</p><p>Demo For: " +
      data.demofor +
      "</p>";
    console.log(msg);
    var emailRequest = {
      to: [
        "ameerpetit@gmail.com",
        "ravi.chinna225@gmail.com",
        "kumhyd2@yahoo.com",
        "ameerpetit.team@gmail.com",
      ],
      subject: "Course Registration",
      message: msg,
      isHTML: true,
    };

    axios.post(`${Actions.APIGATEWAY_URL}email`, emailRequest).then((res) => {
      alert("Thank you for your interest!");
      window.location.reload();
    });
  };
  render() {
    return (
      <>
        <LandingNavBar />
        <form action="" method="post" role="form">
          <div className="container-lg col-lg-6 scheduledemo">
            <div className="row">
              <div className="">
                <h2 className="payheader">Demo Registration</h2>
              </div>
              <p>
                <span className="mandatory">
                  Please fill all madatory (*) fields
                </span>
              </p>
              <div className="col-lg-6">
                <label htmlFor="name">
                  First Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="firstname"
                  className="form-control"
                  name="firstname"
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="email">
                  Last Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="lastname"
                  className="form-control"
                  name="lastname"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="specialization">
                  Email <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  name="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="phone">
                  Phone <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  name="phone"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="whatsapp">Whatsapp </label>
                <input
                  type="text"
                  id="whatsapp"
                  className="form-control"
                  name="whatsapp"
                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                  required
                />
              </div>

              <div className="col-lg-6 mt-2">
                <label htmlFor="city">
                  City <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  name="city"
                  onChange={(e) => this.setState({ city: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="currentstatus">
                  Current Status <span className="mandatory">*</span>
                </label>
                <select
                  className="form-control"
                  name="currentstatus"
                  onChange={(e) =>
                    this.setState({ currentstatus: e.target.value })
                  }
                  required
                >
                  <option>--Select--</option>
                  <option value="H1-B">H1-B</option>
                  <option value="Student">Student</option>
                  <option value="OPT">OPT</option>
                  <option value="H4">H4</option>
                  <option value="EAD">EAD</option>
                  <option value="GC/Citizen">GC/Citizen</option>
                </select>
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="demofor">
                  Demo For <span className="mandatory">*</span>
                </label>
                <select
                  className="form-control"
                  id="demofor"
                  name="demofor"
                  onChange={(e) => this.setState({ demofor: e.target.value })}
                  required
                >
                  <option>--Select--</option>
                  <option value="Chat GPT">Chat GPT</option>
                </select>
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="mode">
                  Mode of Attending <span className="mandatory">*</span>
                </label>
                <select
                  className="form-control"
                  id="mode"
                  name="mode"
                  onChange={(e) => this.setState({ mode: e.target.value })}
                  required
                >
                  <option>--Select--</option>
                  <option value="Online">Online</option>
                  <option value="Office">At Ameerpet IT Office</option>
                </select>
              </div>
              <div className="mt-2">
                <p>
                  <span className="mandatory">Note :</span>If you select Mode of
                  Attending as <b>"Ameerpet IT Office"</b> than please do attend
                  Demo at following location
                </p>
                <p>
                  Address:{" "}
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/AgwdULgFH5nXVKng7"
                  >
                    44790 S Grimmer Blvd,Ste #103, Fremont, CA 94538, USA {"("}
                    USA{")"}{" "}
                  </a>
                </p>
                <p>
                  <span className="mandatory">Important :</span>After Verifying
                  the details above mentioned email will receive a{" "}
                  <b>Demo link</b>, Also you should use the same email for
                  joining the demo.
                </p>
              </div>
              {this.state.firstname != "" &&
              this.state.lastname != "" &&
              this.state.email != "" &&
              this.state.duration != "" &&
              this.state.demotitle != "" &&
              this.state.demodetails != "" ? (
                <div className="col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    className="btn btn-success form-control"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  />
                </div>
              ) : (
                <div className=" col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    name="submit"
                    value="Submit"
                    className="btn btn-default form-control"
                    disabled="disabled"
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}
