import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { PencilSquare, Trash3Fill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import CustomLoader from "../../../components/loader/loader";
import * as Actions from "../../../constants/baseUrl";
import useUserData from "../../../components/useUserDetails/useUserDetails";

const Users = () => {
  const userData = useUserData();
  const role = userData.role;

  const [loading, setLoading] = useState(true);
  const [users, setUser] = useState([]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Actions.BASE_URL}users/admin`);
      const sortedData = response?.data?.sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setUser(sortedData);
    } catch (error) {
      console.error("Error calling API:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [role]);
  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios.delete(`${Actions.BASE_URL}users/${id}`).then((res) => {
              getUsers();
            });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div className="my-demos mb-4">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="my-3">
            <h5 className="theme-text mb-3">Users</h5>

            {users?.length > 0 ? (
              <Table className="striped" bordered responsive>
                <thead>
                  <tr>
                    <th className="bg-theme bg-opacity-50 text-light">
                      User Name
                    </th>
                    <th className="bg-theme bg-opacity-50 text-light">Email</th>
                    <th className="bg-theme bg-opacity-50 text-light">Role</th>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td className="text-overflow-desc-xs text-nowrap">
                        {user.first_name}&nbsp;{user.last_name}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>
                        <span className="d-flex align-items-center justify-content-center gap-2">
                          <Link
                            to={`/admin/users/edit/${user.id}`}
                            className="btn-link theme-text text-center me-1"
                          >
                            <PencilSquare />
                          </Link>
                          <Link
                            to={"#"}
                            className="btn-link text-danger text-center p-0"
                            onClick={() => deleteItem(user.id)}
                          >
                            <Trash3Fill />
                          </Link>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No Users Founds...</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Users;
