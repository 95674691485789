import React, { Component } from "react";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import "./form.css";
import { Col, Container, Form, FormControl, Row } from "react-bootstrap";

class Get_Project extends Component {
  entities = "projects";

  state = {
    id: "",
    userId: "",
    type: "",
    blogTitle: "",
    blogTwoLinerIntro: "",
    blogBannerLink: "",
    blogContent: "",
    blogAuthor: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    category: "",
    comments: "",
  };

  componentDidMount() {
    console.log("Props:", this.props);
    this.populateList(this.props.params.id);
  }

  render() {
    return (
      <Container fluid="lg" className="mt-5">
        <h1 className="heading">Details of Project</h1>
        <Form className="mt-4 form" onSubmit={this.handleSubmit}>
          <Row>
            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">ID</Form.Label>
              <FormControl
                disabled
                name="id"
                value={this.state.id}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">User ID</Form.Label>
              <FormControl
                disabled
                name="userId"
                value={this.state.userId}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Type</Form.Label>
              <FormControl
                disabled
                name="type"
                value={this.state.type}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Blog Title</Form.Label>
              <FormControl
                disabled
                name="blogTitle"
                value={this.state.blogTitle}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Blog(2) Liner Intro</Form.Label>
              <FormControl
                disabled
                name="blogTwoLinerIntro"
                value={this.state.blogTwoLinerIntro}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Blog Banner Link</Form.Label>
              <FormControl
                disabled
                name="blogBannerLink"
                value={this.state.blogBannerLink}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Blog Content</Form.Label>
              <FormControl
                disabled
                name="blogContent"
                value={this.state.blogContent}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Blog Author</Form.Label>
              <FormControl
                disabled
                name="blogAuthor"
                value={this.state.blogAuthor}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Email</Form.Label>
              <FormControl
                disabled
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Phone</Form.Label>
              <FormControl
                disabled
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">WhatsApp</Form.Label>
              <FormControl
                disabled
                name="whatsapp"
                value={this.state.whatsapp}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Website</Form.Label>
              <FormControl
                disabled
                name="website"
                value={this.state.website}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Category</Form.Label>
              <FormControl
                disabled
                name="category"
                value={this.state.category}
                onChange={this.handleChange}
              />
            </Col>

            <Col lg={4} md={6} className="my-2">
              <Form.Label className="col-8">Comments</Form.Label>
              <FormControl
                disabled
                name="comments"
                value={this.state.comments}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }

  async populateList(id) {
    try {
      const data = await fetch(
        `${Actions.BASE_URL}${this.entities}/${id}`
      ).then((response) => {
        console.log(response);
        return response.json();
      });

      this.setState(data);
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  }
}

export default withRouter(Get_Project);
