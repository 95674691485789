import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Toast } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Clock } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DurationSelect from "../durationSelect/durationSelect";
import "./scheduleDateModal.css";

const ScheduleDateModalCourse = (props) => {
  const [value, onChange] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  const [requestMade, setRequestMade] = useState(false);
  const [hasURL, setHasURL] = useState(false);
  const [activeTile, setActiveTile] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [convertedUTCTime, setConvertedUTCTime] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isURL, setIsURL] = useState(false);

  const tileClassName = ({ date }) => {
    return activeTile && activeTile.getDate() === date.getDate()
      ? "active"
      : "";
  };

  // handle field change
  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...props?.state,
      [fieldName]: fieldValue,
    };
    props?.setstate(newState);
  };

  const handleTileClick = (value) => {
    setActiveTile(value);
  };

  useEffect(() => {
    // Set the default timezone to the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone({ label: userTimezone, value: userTimezone });
  }, []);

  const mainTimeZones = [
    "Asia/Kolkata",
    "Asia/Karachi",
    "Europe/London",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "Australia/Sydney",
    "Europe/Berlin",
    "Asia/Tokyo",
    "Africa/Johannesburg",
    "Europe/Paris",
    "America/Toronto",
    "Asia/Dubai",
    "America/Denver",
    "Asia/Hong_Kong",
    "America/Mexico_City",
    "Europe/Madrid",
    "Asia/Singapore",
    "America/Phoenix",
    "Australia/Perth",
    "Africa/Cairo",
    "Asia/Kuala_Lumpur",
  ];

  const timeZones = moment.tz
    .names()
    .filter((tz) => mainTimeZones.includes(tz))
    .map((tz) => ({
      label: tz,
      value: tz,
    }));

  const handleDateChange = (newValue) => {
    onChange(newValue);
    setFormattedDate(moment(newValue).format("YYYY/MM/DD"));
  };

  useEffect(() => {
    handleFieldChange("start_date", formattedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedDate]);

  const handleEventTimeChange = (e) => {
    const newTime = startTime;
    setEventTime(newTime);

    if (selectedTimeZone) {
      convertToUTC(newTime, selectedTimeZone.value);
    }
  };

  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption);
    convertToUTC(eventTime, selectedOption?.value);
  };

  useEffect(() => {
    handleEventTimeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment
      .tz(givenTime, "HH:mm", timeZone)
      .utc()
      .format("HH:mm");
    setConvertedUTCTime(utcTime);
  };

  useEffect(() => {
    handleFieldChange("start_time", convertedUTCTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedUTCTime]);

  useEffect(() => {
    // made request when has date in state
    if (props.state?.start_date) {
      setRequestMade(true);
    } else {
      setRequestMade(false);
    }
    // made request when has date in state
    if (props.state?.register_url) {
      setHasURL(true);
    } else {
      setHasURL(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state?.start_date]);

  const handleSubmit = () => {
    if (hasURL) {
      if (props.state?.register_url && isURL) {
        setIsValid(true);
        setIsChanged(false);
        props?.setshow(false);
      } else {
        setIsValid(false);
        setIsChanged(true);
      }
    }
  };

  useEffect(() => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidURL = urlPattern.test(props.state?.register_url);
    setIsURL(isValidURL);
  }, [props.state?.register_url, isURL, isValid]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize"
        >
          Schedule your {props?.entity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mx-0">
          <Col md={12}>
            <Form.Group className="d-flex gap-2 align-items-end mb-3">
              <Form.Check
                id="meetingURL"
                className="custom-checkbox ps-0 theme-text-2 fw-medium"
                onChange={() => setHasURL(!hasURL)}
                checked={hasURL}
                label="I have my own meeting URL"
              />
            </Form.Group>
          </Col>
        </Row>
        {hasURL ? (
          <>
            <Row className="justify-content-center mx-0">
              <Col md={12}>
                <small className="text-end d-block text-dark">
                  required<span className="text-danger">*</span>
                </small>
                <Form.Group className="form-floating">
                  <Form.Control
                    id="register_url"
                    name="register_url"
                    type="text"
                    placeholder="register Url"
                    value={props.state.register_url}
                    className={
                      (!isURL || !isValid) && isChanged ? "border-danger" : ""
                    }
                    onChange={(e) =>
                      handleFieldChange("register_url", e.target.value)
                    }
                  />
                  <Form.Label className="form-label" htmlFor="register_url">
                    Register Link For Users
                  </Form.Label>
                  {(!isURL || !isValid) && isChanged && (
                    <small className="text-end d-block text-danger">
                      Enter a valid register link
                    </small>
                  )}
                </Form.Group>
                <small className="text-start d-block text-dark fw-bold">
                  <span className="text-danger">*</span>Enter your Meeting URL
                  first
                </small>
              </Col>
            </Row>
            <Row className="position-relative py-3">
              {!isValid && !props.state.register_url && (
                <div className="semi-bg position-absolute w-100 h-100 start-0 top-0 overlayCalender"></div>
              )}
              <Col lg={6} className="position-relative">
                <Calendar
                  onChange={handleDateChange}
                  value={value}
                  className="mx-auto"
                  prevLabel={<ChevronLeft />}
                  tileClassName={tileClassName}
                  onClickDay={handleTileClick}
                  nextLabel={<ChevronRight />}
                  minDate={new Date()}
                />

                <Form.Group className="form-floating my-3 mx-auto">
                  <Select
                    className="timezone form-control z-3"
                    required
                    id="timezone"
                    options={timeZones}
                    value={selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    placeholder="Select your time zone"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-floating mx-auto mb-3">
                  <ReactDatePicker
                    className="form-control form-select w-100"
                    selected={startTime}
                    onChange={(date) => {
                      setStartTime(date);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    dateFormat="h:mm aa"
                  />
                </Form.Group>
                <Form.Group className="form-floating mx-auto mb-3">
                  <DurationSelect
                    isCourse={true}
                    field={props?.state}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="justify-content-center mx-0">
            <Col lg={6} className="position-relative">
              <Calendar
                onChange={handleDateChange}
                value={props.state?.start_date || value}
                className="mx-auto"
                prevLabel={<ChevronLeft />}
                tileClassName={tileClassName}
                onClickDay={handleTileClick}
                nextLabel={<ChevronRight />}
                minDate={new Date()}
              />

              <Form.Group className="form-floating my-3 mx-auto">
                <Select
                  className="timezone form-control z-3"
                  required
                  id="timezone"
                  options={timeZones}
                  value={selectedTimeZone}
                  onChange={handleTimeZoneChange}
                  placeholder="Select your time zone"
                />
              </Form.Group>
              {requestMade && (
                <>
                  <Form.Group className="form-floating mx-auto mb-3">
                    <ReactDatePicker
                      className="form-control form-select w-100"
                      selected={startTime}
                      onChange={(date) => {
                        setStartTime(date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      dateFormat="h:mm aa"
                    />
                    <Toast
                      show={showToast}
                      onClose={() => setShowToast(false)}
                      autohide
                      delay={3000}
                      style={{
                        position: "absolute",
                        top: "100%",
                        opacity: 1,
                        left: 0,
                        zIndex: "99",
                      }}
                    >
                      <Toast.Header closeButton={false}>
                        <strong className="mr-auto text-danger">
                          <Clock />
                          &nbsp;Error
                        </strong>
                      </Toast.Header>
                      <Toast.Body>
                        You cannot select a Reserved Time Slot
                      </Toast.Body>
                    </Toast>
                  </Form.Group>
                  <Form.Group className="form-floating mx-auto mb-3">
                    <DurationSelect
                      isCourse={true}
                      field={props?.state}
                      handleFieldChange={handleFieldChange}
                    />
                  </Form.Group>
                </>
              )}
            </Col>
          </Row>
        )}
        {hasURL ? (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={handleSubmit}
          >
            Done
          </Button>
        ) : (
          <Button
            className="btn add-button float-end mt-3 px-4"
            onClick={() => props?.setshow(false)}
          >
            Done
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleDateModalCourse;
