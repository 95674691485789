import UTCLocalTimeConverterEmail from "../UTCLocalTimeConverter/UTCLocalTimeConverterEmail";
import ConvertMinutesToTime from "../convertFromMinutes/convertFromMinutes  ";

const SponsorEmailBodyContent = (
  selectedItemDetails,
  customMessage,
  userDetails,
  userEmail
) => {
  const myHost = window.location.hostname;

  const demos = selectedItemDetails?.filter((item) => item?.type === "demo");
  const courses = selectedItemDetails?.filter(
    (item) => item?.type === "course"
  );

  const generateHTML = (items) => {
    return items
      ?.map((detail, index) => {
        return `
        <table width="100%" border="0" cellspacing="0" cellpadding="0" key="${index}">
          <tr>
            <td height="16"></td>
          </tr>
          <tr>
            <td width="100%" align="center">
              <table width="100%" border="0" cellspacing="0" cellpadding="0"
                style="border-radius: 12px;     box-shadow: 0 1px 20px 0 #455a6433; max-width:560px;">
                <tr>
                  <td height="8"></td>
                </tr>
                <tr>
                  <td width="8"></td>
                  <td align="center" width="554">
                    <img src="${
                      detail?.listing_image_url || detail?.hero_image_url
                    }"
                      alt="Load Error" width="100%" height="100%" loading="lazy"
                      style="object-fit: cover; max-height: 18rem; border-radius: 10px; box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.06);">
                  </td>
                  <td width="8"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="12"></td>
                  <td width="560" align="center">
                    <span
                      style="font-family: 'Unbounded', sans-serif; color: #43a39f; text-decoration: none; font-size: 1.25rem; font-weight: 500; line-height: normal;">
                      ${detail?.title}
                    </span>
                  </td>
                  <td width="12"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="8"></td>
          </tr>
          <tr>
            <td align="center">
              <span style="font-size: 12px;"> 
              ${detail?.start_date} -  
              ${UTCLocalTimeConverterEmail({
                utcTime: detail?.start_time,
                zone: true,
              })}
                 <br>
                ${ConvertMinutesToTime({
                  minutes: detail?.duration,
                })}
              </span>
            </td>
          </tr>
          <tr>
            <td height="15"></td>
          </tr>
          <tr>
            <td align="center">
            <table width="200" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td width="100" align="center">
              <a href="${`https://${myHost}/register/${detail?.type}/${detail?.id}`}"
              style="font-size: 14px; font-weight: 500; padding:6px 18px; text-decoration: none; border: 2px solid transparent; background-color: #43a39f; border-radius: 4px; color: #fff;">
              Register
            </a>
              </td> 
              <td width="100" align="center">
              <a href="${`https://${myHost}/pay?currency=${detail?.currency}&amount=${detail?.amount}&email=${detail?.userId}`}"
              style="font-size: 14px; font-weight: 500; padding:6px 18px; text-decoration: none; border: 2px solid transparent; background-color: #43a39f; border-radius: 4px; color: #fff;">
              Pay
            </a>
              </td>
            </tr>
          </table>
            </td>
          </tr>
          <tr>
            <td height="18"></td>
          </tr>
          <tr>
            <td width="100%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td width="18"></td>
                  <td width="560" align="center" style="line-height: 1.3;">
                    <span style="font-size: 12px; text-align:center; text-wrap: balance;
                    white-space: pre-line;">
                      ${detail?.description}
                    </span>
                  </td>
                  <td width="18"></td>
                </tr>
                <tr>
                  <td height="24"> </td>
                </tr>
              </table>
              <hr width="90%">
            </td>
          </tr>
          <tr>
            <td height="24"> </td>
          </tr>
        </table>
      `;
      })
      .join("");
  };

  const demosHTML = generateHTML(demos);
  const coursesHTML = generateHTML(courses);

  const htmlContent = `

  <!-- ***Body*** --> 

  <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
          <td height="6"></td>
      </tr>
      <!-- Main Message --> 
      <tr>
          <td width="100%" style="line-height: 1.3;">
              <span style="font-size: 12px; font-family: 'Poppins', sans-serif;  text-wrap: pretty;
              white-space: pre-line;"> 
              ${customMessage}
              </span>
          </td>
      </tr> 
  </table>

  <!-- User Details -->
  <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
      cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee">
      <tr>
          <td height="12"></td>
      </tr>
      <tr>
          <td width="12"></td>
          <td>
  <table width="574px"
  style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
  border="0" cellspacing="0" cellpadding="0" align="center">
  <tr>
      <td>
          <span>
              <strong>Name:</strong>&nbsp; ${userDetails?.first_name} ${
    userDetails?.last_name
  }
          </span>
      </td>
  </tr> 

  ${
    userDetails?.email
      ? `
  <tr>
      <td height="6"></td>
  </tr>
  <tr>
      <td>
          <span>
              <strong>Email Address:</strong>&nbsp; ${userDetails?.email}
          </span>
      </td>
  </tr>
  `
      : ""
  }
  <tr>
      <td height="6"></td>
  </tr>
  ${
    userDetails?.phone
      ? `<tr>
          <td>
          <span>
              <strong>Phone:</strong>&nbsp; ${userDetails?.phone}
          </span>
          </td>
      </tr>
      <tr>
          <td height="6"></td>
      </tr>
      `
      : ""
  }
</table>
</td>
<td width="12"></td>
</tr>
</table>

    <!-- Entity cards -->
    <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
      align="center" style="text-wrap: balance;">
      <tr>
        <td>
          <!-- Demos -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              demosHTML &&
              `
              <tr>
                <td>
                  ${demosHTML}
                </td>
              </tr>
            `
            }
          </table>
          
          <!-- Courses -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            ${
              coursesHTML &&
              ` 
              <tr>
                <td>
                  ${coursesHTML}
                </td>
              </tr>
            `
            }
          </table>
        </td>
      </tr>
    </table>
  `;

  return htmlContent;
};

export default SponsorEmailBodyContent;
