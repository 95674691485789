import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import useUserData from "../useUserDetails/useUserDetails";
import userPlacehold from "../../img/user.png";
import "./navbarstyle.css";

function UnAuthNavBar() {
  const location = useLocation();
  const userData = useUserData();

  const logoSrc = SubDomainRouter("logo");
  const isHeightMoreThan100vh = window.innerHeight > 100;
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const homeNavItem = SubDomainRouter("homeNavItem");
  const demosNavItem = SubDomainRouter("demosNavItem");
  const coursesNavItem = SubDomainRouter("homeNavItem");
  const corporatesNavItem = SubDomainRouter("corporatesNavItem");
  const blogNavItem = SubDomainRouter("blogNavItem");
  const contactNavItem = SubDomainRouter("contactNavItem");
  const codeAINavItem = SubDomainRouter("codeAINavItem");

  return (
    <>
      <Navbar
        expand="lg"
        className={
          isHeightMoreThan100vh
            ? "align-items-center py-0 pad"
            : "align-items-center py-0"
        }
      >
        <Container fluid="xl">
          <Link className="navbar-brand" to="/">
            <img src={`${logoSrc}`} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block d-none">
            <Nav className="mx-auto my-lg-0 my-3 align-items-center">
              {homeNavItem === "show" && (
                <Link
                  to={`/`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/` && "active-link"
              }`}
                >
                  Home
                </Link>
              )}
              {demosNavItem === "show" && (
                <Link
                  to={`/demos`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/demos` && "active-link"
              }`}
                >
                  {demosAlias}
                </Link>
              )}
              {coursesNavItem === "show" && (
                <Link
                  to={`/courses`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/courses` && "active-link"
              }`}
                >
                  {coursesAlias}
                </Link>
              )}
              {corporatesNavItem === "show" && (
                <Link
                  to={`/corporates`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/corporates` && "active-link"
              }`}
                >
                  corporates
                </Link>
              )}
              {contactNavItem === "show" && (
                <Link
                  to={`/contact`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/contact` && "active-link"
              }`}
                >
                  contact
                </Link>
              )}
              {blogNavItem === "show" && (
                <Link
                  to={`/blogs`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/blogs` && "active-link"
              }`}
                >
                  blogs
                </Link>
              )}
              {codeAINavItem === "show" && (
                <Link
                  to={`/code-ai`}
                  className={`
              nav-link text-capitalize ${
                location.pathname === `/code-ai` && "active-link"
              }`}
                >
                  Code(AI)
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="right d-flex align-items-center gap-sm-3 gap-2">
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center"
              title={
                <img
                  src={
                    userData?.photo_image_url
                      ? userData?.photo_image_url
                      : userData?.first_name || userData?.type
                      ? `https://placehold.co/280x280/42a49f/white?text=${
                          userData?.first_name?.slice(0, 1) ||
                          userData?.type?.slice(0, 1)
                        }`
                      : userPlacehold
                  }
                  loading="lazy"
                  alt=""
                />
              }
              id="basic-nav-dropdown"
            >
              <Link className="dropdown-item" to="/my-profile">
                Sign In
              </Link>
            </NavDropdown>
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center d-lg-none d-block"
              title={<Navbar.Toggle />}
              id="basic-nav-dropdown"
            >
              {homeNavItem === "show" && (
                <Link
                  to={`/`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/` && "active-link"
              }`}
                >
                  Home
                </Link>
              )}
              {demosNavItem === "show" && (
                <Link
                  to={`/demos`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/demos` && "active-link"
              }`}
                >
                  {demosAlias}
                </Link>
              )}
              {coursesNavItem === "show" && (
                <Link
                  to={`/courses`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/courses` && "active-link"
              }`}
                >
                  {coursesAlias}
                </Link>
              )}
              {corporatesNavItem === "show" && (
                <Link
                  to={`/corporates`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/corporates` && "active-link"
              }`}
                >
                  corporates
                </Link>
              )}
              {contactNavItem === "show" && (
                <Link
                  to={`/contact`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/contact` && "active-link"
              }`}
                >
                  contact
                </Link>
              )}
              {blogNavItem === "show" && (
                <Link
                  to={`/blogs`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/blogs` && "active-link"
              }`}
                >
                  blogs
                </Link>
              )}
              {codeAINavItem === "show" && (
                <Link
                  to={`/code-ai`}
                  className={`
              nav-link text-capitalize  dropdown-item px-3 py-1 fw-normal position-relative  ${
                location.pathname === `/code-ai` && "active-link"
              }`}
                >
                  Code(AI)
                </Link>
              )}
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default UnAuthNavBar;
