import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet"; 
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

const MyContacts = () => {
  const location = useLocation();
  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>My Contacts | {siteName}</title>
      </Helmet>

      <div className="my-5 py-5 my-contacts">
        <Row className="campaigns-wrapper p-3 pb-5 mt-3 align-items-stretch">
          <Col
            md={2}
            className="ps-0 pe-md-2 pe-0 border-end border-opacity-50 my-sm-3 mb-4 overflow-x-md-scroll md-border"
          >
            <Nav
              variant="pills"
              className="flex-md-column justify-content-md-start justify-content-center flex-nowrap"
            >
              <Nav.Item>
                <Link
                  to={`/my-contacts/all-contacts`}
                  className={`nav-link text-nowrap ${location.pathname === "/my-contacts/all-contacts"
                    ? "active"
                    : ""
                    }`}
                >
                  all contacts
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/my-contacts/email-lists`}
                  className={`nav-link text-nowrap ${location.pathname === "/my-contacts/email-lists"
                    ? "active"
                    : ""
                    }`}
                >
                  email lists
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/my-contacts/whatsapp-lists`}
                  className={`nav-link text-nowrap ${location.pathname === "/my-contacts/whatsapp-lists"
                    ? "active"
                    : ""
                    }`}
                >
                  whatsapp lists
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/my-contacts/telegram-lists`}
                  className={`nav-link text-nowrap ${location.pathname === "/my-contacts/telegram-lists"
                    ? "active"
                    : ""
                    }`}
                >
                  telegram lists
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to={`/my-contacts/sms-lists`}
                  className={`nav-link text-nowrap ${location.pathname === "/my-contacts/sms-lists"
                    ? "active"
                    : ""
                    }`}
                >
                  SMS lists
                </Link>
              </Nav.Item> 
            </Nav>
          </Col>
          <Col md={10} className="px-lg-4 pe-md-0 mx-auto">
            <Outlet />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyContacts;
