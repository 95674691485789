import React, { Component } from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import withRouter from "../../constants/withRouter";
import "./style.css";
import "./form.css";

class Edit_Project extends Component {
  entities = "projects";

  state = {
    id: "",
    userId: "",
    type: "",
    blogTitle: "",
    blogTwoLinerIntro: "",
    blogBannerLink: "",
    blogContent: "",
    blogAuthor: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    category: "",
    comments: "",
  };

  componentDidMount() {
    console.log("Props:", this.props);
    this.populateList(this.props.params.id);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const item = this.state;
    console.log(item);

    axios
      .put(`${Actions.BASE_URL}${this.entities}/${item.id}`, item)
      .then((res) => {
        alert("data updated successfully!");
        window.location.href = "/" + this.entities;
        this.setState({
          id: "",
          userId: "",
          type: "",
          blogTitle: "",
          blogTwoLinerIntro: "",
          blogBannerLink: "",
          blogContent: "",
          blogAuthor: "",
          email: "",
          phone: "",
          whatsapp: "",
          website: "",
          category: "",
          comments: "",
        });
      });
  };

  resetfields() {
    this.setState({
      id: "",
      userId: "",
      type: "",
      blogTitle: "",
      blogTwoLinerIntro: "",
      blogBannerLink: "",
      blogContent: "",
      blogAuthor: "",
      email: "",
      phone: "",
      whatsapp: "",
      website: "",
      category: "",
      comments: "",
    });
  }

  render() {
    return (
      <>
        <br />
        <h1 className="heading">Edit Project</h1>

        <div className="container">
          <form
            className="col-lg-10 row mt-4 form"
            onSubmit={this.handleSubmit}
          >
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="id"
                  name="id"
                  placeholder="ID"
                  value={this.state.id}
                  onChange={(e) => this.setState({ id: e.target.value })}
                />
                <label htmlFor="id">ID</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="userId"
                  name="userId"
                  placeholder="User ID"
                  value={this.state.userId}
                  onChange={(e) => this.setState({ userId: e.target.value })}
                />
                <label htmlFor="userId">User ID</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="type"
                  name="type"
                  placeholder="Type"
                  value={this.state.type}
                  onChange={(e) => this.setState({ type: e.target.value })}
                />
                <label htmlFor="type">Type</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="blogTitle"
                  name="blogTitle"
                  placeholder="Blog Title"
                  value={this.state.blogTitle}
                  onChange={(e) => this.setState({ blogTitle: e.target.value })}
                />
                <label htmlFor="blogTitle">Blog Title</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="blogTwoLinerIntro"
                  name="blogTwoLinerIntro"
                  placeholder="Blog Two Liner Intro"
                  value={this.state.blogTwoLinerIntro}
                  onChange={(e) =>
                    this.setState({ blogTwoLinerIntro: e.target.value })
                  }
                />
                <label htmlFor="blogTwoLinerIntro">Blog Two Liner Intro</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="blogBannerLink"
                  name="blogBannerLink"
                  placeholder="Blog Banner Link"
                  value={this.state.blogBannerLink}
                  onChange={(e) =>
                    this.setState({ blogBannerLink: e.target.value })
                  }
                />
                <label htmlFor="blogBannerLink">Blog Banner Link</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="blogContent"
                  name="blogContent"
                  placeholder="Blog Content"
                  value={this.state.blogContent}
                  onChange={(e) =>
                    this.setState({ blogContent: e.target.value })
                  }
                />
                <label htmlFor="blogContent">Blog Content</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="blogAuthor"
                  name="blogAuthor"
                  placeholder="Blog Author"
                  value={this.state.blogAuthor}
                  onChange={(e) =>
                    this.setState({ blogAuthor: e.target.value })
                  }
                />
                <label htmlFor="blogAuthor">Blog Author</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <label htmlFor="email">Email</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
                <label htmlFor="phone">Phone</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="whatsapp"
                  name="whatsapp"
                  placeholder="WhatsApp"
                  value={this.state.whatsapp}
                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                />
                <label htmlFor="whatsapp">WhatsApp</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="website"
                  name="website"
                  placeholder="Website"
                  value={this.state.website}
                  onChange={(e) => this.setState({ website: e.target.value })}
                />
                <label htmlFor="website">Website</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="category"
                  name="category"
                  placeholder="Category"
                  value={this.state.category}
                  onChange={(e) => this.setState({ category: e.target.value })}
                />
                <label htmlFor="category">Category</label>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-floating form-floating mb-3">
                <input
                  className="form-control"
                  id="comments"
                  name="comments"
                  placeholder="Comments"
                  value={this.state.comments}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                />
                <label htmlFor="comments">Comments</label>
              </div>
            </div>
            <div className="form-group text-center col-lg-12">
              <button type="submit" className="btn button2 mt-3">
                {this.state.id ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  async populateList(id) {
    try {
      const data = await fetch(
        `${Actions.BASE_URL}${this.entities}/${id}`
      ).then((response) => {
        console.log(response);
        return response.json();
      });

      this.setState(data);
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  }
}

export default withRouter(Edit_Project);
