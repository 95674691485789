import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";

const DynamicFieldSetURL = ({ onFieldChange, category }) => {
  const [fields, setFields] = useState([
    {
      documentType: "link",
      field_name: "",
      file_content: "",
      file_extension: ".html",
    },
  ]);

  const handleFieldsChange = (index, updatedAttachment) => {
    const updatedFields = [...fields];
    updatedFields[index] = updatedAttachment;
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  const addField = () => {
    const newAttachment = {
      documentType: "link",
      field_name: "",
      file_content: "",
      file_extension: ".html",
    };
    setFields([...fields, newAttachment]);
    onFieldChange(fields);
  };

  const onFieldRemove = (index) => {
    const updatedFields = fields.filter((_, i) => index !== i);
    setFields(updatedFields);
    onFieldChange(updatedFields);
  };

  return (
    <Col md={12} className="dynamicField">
      <Button
        className="clickable addStyle mb-3 ms-auto d-flex"
        onClick={addField}
      >
        <span className="addBttn d-block">+</span>
      </Button>
      {fields.map((field, index) => (
        <div key={index} className="d-flex gap-3 w-100 align-items-center mb-3">
          <div className="d-flex align-items-center gap-3 flex-sm-row flex-column flex-grow-1">
            <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
              <Form.Control
                type="text"
                className="w-100"
                name="document"
                id={`type-${index}`}
                placeholder="Type"
                value={field.field_name}
                onChange={(e) => {
                  const updatedAttachment = {
                    ...field,
                    field_name: e.target.value,
                  };
                  handleFieldsChange(index, updatedAttachment);
                }}
              />
              <Form.Label className="form-label" htmlFor={`type-${index}`}>
                Link Type
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-md-3 flex-grow-1 w-100 form-floating position-relative">
              <Form.Control
                type="url"
                className="w-100"
                name="document"
                id={`link-${index}`}
                placeholder="Link"
                value={field.file_content}
                onChange={(e) => {
                  const updatedAttachment = {
                    ...field,
                    file_content: e.target.value,
                  };
                  handleFieldsChange(index, updatedAttachment);
                }}
              />
              <Form.Label className="form-label" htmlFor={`link-${index}`}>
                URL
              </Form.Label>
            </Form.Group>
          </div>
          <Button
            className="clickable addStyle mb-md-3"
            onClick={() => onFieldRemove(index)}
          >
            <span className="addBttn d-block">-</span>
          </Button>
        </div>
      ))}
    </Col>
  );
};

export default DynamicFieldSetURL;
