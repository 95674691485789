const EmailHeader = (logo, demosAlias, coursesAlias) => {
  const myHost = window.location.hostname;

  const htmlContent = `
  <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>HTML Email Template</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Unbounded:wght@200;300;400;500;600;700&display=swap"
        rel="stylesheet">
</head>

<body>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: 'Poppins', sans-serif;">
        <tr>
            <td>
                <!-- Header -->
                <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
                    align="center">
                    <tr>
                        <td height="20"></td>
                    </tr>
                    <tr>
                        <td align="center">
                            <a href="https://${myHost}/" target="_blank" style="text-decoration: none;">
                                <img src="${logo}" alt="AmeerpetIT - World's Biggest IT
                                    Training Hub" width="180">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td height="20"></td>
                    </tr>
                    <tr bgcolor="#43a39f" style="font-size: 12px; font-family: 'Unbounded', sans-serif;">
                        <td>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td height="8"></td>
                                </tr>
                                <tr>
                                    <td align="center" width="120">
                                        <a href="https://${myHost}/demos" target="_blank"
                                            style="color: #fff; text-decoration: none; text-transform: capitalize;">
                                            ${demosAlias}
                                        </a>
                                    </td>
                                    <td align="center" width="120">
                                        <a href="https://${myHost}/courses" target="_blank"
                                            style="color: #fff; text-decoration: none; text-transform: capitalize;">
                                            ${coursesAlias}
                                        </a>
                                    </td>
                                    <td align="center" width="120">
                                        <a href="https://${myHost}/trainers" target="_blank"
                                            style="color: #fff; text-decoration: none; text-transform: capitalize;">
                                            Trainers
                                        </a>
                                    </td>
                                    <td align="center" width="120">
                                        <a href="https://${myHost}/institutes" target="_blank"
                                            style="color: #fff; text-decoration: none; text-transform: capitalize;">
                                            Institutes
                                        </a>
                                    </td>
                                    <td align="center" width="120">
                                        <a href="https://${myHost}/contact" target="_blank"
                                            style="color: #fff; text-decoration: none; text-transform: capitalize;">
                                            Contact
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="8"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
              </td>
        </tr>
    </table> `;
  return htmlContent;
};

export default EmailHeader;
