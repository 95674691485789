import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import * as Actions from "../../constants/baseUrl";
import "./register.css";
import useUserData from "../useUserDetails/useUserDetails";
import TokenDetails from "../useUserDetails/useTokenDetails";
import { useNavigate } from "react-router-dom";

const RegisterModal = (props) => {
  const navigate = useNavigate();

  const tokenDetails = TokenDetails();
  const myUserID = tokenDetails?.Email;
  const myToken = localStorage.getItem("token");

  const entity = props.modaldata.type;
  const entityId = props.modaldata.id;

  const userData = useUserData();

  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [checkedCourse, setCheckedCourse] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState([]);
  const [formData, setFormData] = useState({
    meeting_id: "",
    first_name: "",
    last_name: "",
    email: myUserID,
    phone: "",
    whatsapp: "",
    website: "",
    city: "",
    job_title: "",
    industry: "",
    interestInFullCourse: true,
    title: "",
  });
  const [emailChanged, setEmailChanged] = useState(false);

  const handleFieldChange = (fieldName, fieldValue) => {
    if (fieldName === "email" && fieldValue !== myUserID) {
      setEmailChanged(true);
    }
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleRegister = () => {
    const data = {
      ...formData,
      meeting_id: props.modaldata.meeting_id,
      entityId: props.modaldata.id,
      entityType: props.modaldata.type,
    };

    axios
      .post(`${Actions.BASE_URL}demos/register`, data, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setRegistered(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.httpstatuscode === "BadRequest") {
          setLoading(false);
          setErrorMessage(true);
          setErrorMessageContent(error.response.data);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleRegister();
  };
  const handleDone = () => {
    props.setshow(false);
    setRegistered(false);
    setErrorMessage(false);

    props.modaldata.register_url
      ? (window.location = props.modaldata.register_url)
      : props.modaldata.subcategorylevel1 === "paid"
      ? navigate(
          `/pay?email=${props.modaldata.userId}&currency=${props.modaldata.currency}&amount=${props.modaldata.amount}`
        )
      : navigate(`/share/${entity}/${entityId}`);
  };

  useEffect(() => {
    myUserID === formData.email
      ? setEmailChanged(false)
      : setEmailChanged(true);
  }, [formData.email, myUserID]);

  useEffect(() => {
    setFormData({
      ...formData,
      city: userData?.city,
      phone: userData?.phone,
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      website: userData?.website,
      whatsapp: userData?.whatsapp,
      industry: userData?.industry,
      job_title: userData?.job_title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      {loading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}
      {registered ? (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleDone}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="h5">
              Your Registration Info is submitted.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <Check2Circle className="display-1 theme-text" />
            <h5>Registered</h5>
            {props.modaldata.register_url ? (
              <Alert className="alert-theme-2 p-1 border-0">
                *This is an external event. You may need to register again
              </Alert>
            ) : (
              ""
            )}
            <Button
              className="btn add-button float-end mt-3"
              onClick={handleDone}
            >
              Done
            </Button>
          </Modal.Body>
        </Modal>
      ) : errorMessage ? (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            props.setshow(false);
            setErrorMessage(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="h5">
              {errorMessageContent.Error}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
            <XCircle className="display-1 text-danger" />
            <h6 className="text-danger mt-2 text-center">
              {errorMessageContent.Message}
            </h6>
            <Button
              className="btn add-button float-end mt-3 px-4"
              onClick={() => {
                props.setshow(false);
                setErrorMessage(false);
              }}
            >
              OK
            </Button>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => props.setshow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="h5">
              Register For&nbsp;{props.modaldata.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="d-none">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="meeting_id"
                      name="meeting_id"
                      placeholder="meeting_id"
                      onChange={(e) => {
                        handleFieldChange("meeting_id", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="meeting_id">meeting_id</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      required
                      value={formData?.first_name}
                      placeholder="First Name"
                      onChange={(e) => {
                        handleFieldChange("first_name", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="first_name">First Name</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="last_name"
                      value={formData?.last_name}
                      required
                      name="last_name"
                      placeholder="Last Name"
                      onChange={(e) => {
                        handleFieldChange("last_name", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="last_name">Last Name</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      required
                      value={formData?.email}
                      placeholder="email"
                      onChange={(e) => {
                        handleFieldChange("email", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="email">Email</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData?.phone}
                      placeholder="Phone"
                      onChange={(e) => {
                        handleFieldChange("phone", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="whatsapp"
                      name="whatsapp"
                      type="tel"
                      value={formData?.whatsapp}
                      placeholder="Whatsapp"
                      onChange={(e) => {
                        handleFieldChange("whatsapp", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="whatsapp">Whatsapp</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData?.city}
                      placeholder="City"
                      onChange={(e) => {
                        handleFieldChange("city", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="city">City</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="job_title"
                      name="job_title"
                      value={formData?.job_title}
                      placeholder="Title"
                      onChange={(e) => {
                        handleFieldChange("job_title", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="job_title">Job Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6} className="px-1 pb-3">
                  <Form.Group className="form-floating">
                    <Form.Control
                      className="form-control"
                      id="industry"
                      name="industry"
                      value={formData?.industry}
                      placeholder="Industry"
                      onChange={(e) => {
                        handleFieldChange("industry", e.target.value);
                      }}
                    />
                    <Form.Label htmlFor="industry">Industry</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={12} className="px-1 pb-3">
                  <Form.Group>
                    <Form.Check
                      label="Interested in Full Course"
                      name="interestInFullCourse"
                      type="checkbox"
                      id="interestInFullCourse"
                      className="ps-2 mb-2 custom-checkbox"
                      checked={checkedCourse}
                      onChange={() => {
                        handleFieldChange(
                          "interestInFullCourse",
                          !formData.interestInFullCourse
                        );
                      }}
                      onClick={() => setCheckedCourse(!checkedCourse)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  {emailChanged && (
                    <Alert className="p-2 alert-theme-2">
                      <small>
                        Changing your email will exclude this&nbsp;
                        {props.modaldata.type} from dashboard.
                      </small>
                    </Alert>
                  )}
                </Col>
                <Col xs={12}>
                  <Button type="submit" className="btn add-button float-end">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default RegisterModal;
