import React, { useState, useEffect } from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import "./payment.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";

function Pay() {
  const [formData, setFormData] = useState({
    email: "",
    customername: "",
    name: "",
    number: "",
    expiryYear: "",
    expiryMonth: "",
    cvc: "",
    currency: "",
    amount: 0,
    customerId: "",
    receiptEmail: "",
    description: "",
  });

  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var data = {
      customer: {
        email: formData.email,
        name: formData.customername,
        card: {
          name: formData.customername,
          number: formData.number,
          expiryYear: formData.expiryYear,
          expiryMonth: formData.expiryMonth,
          cvc: formData.cvc,
        },
      },
      charge: {
        currency: formData.currency,
        amount: parseInt(formData.amount),
        customerId: "cus_O5Swke2kscZQrA",
        receiptEmail: formData.email,
        description: "Your Test Payment for $" + formData.amount,
      },
    };

    axios
      .post(`${Actions.PAYMENT_URL}Stripe/pay`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        alert("data saved successfully!");
      });
  };

  const getQuery = (keys) => {
    const name = new URLSearchParams(window.location.search).get(keys);
    return name;
  };

  useEffect(() => {
    setFormData({
      currency: getQuery("currency"),
      amount: getQuery("amount"),
      email: getQuery("email"),
    });
  }, []);
  const siteName = SubDomainRouter("title");

  return (
    <>
      <Helmet>
        <title>Payment | {siteName}</title>
      </Helmet>

      <Container fluid="lg" className="py-5 my-5">
        <h2 className="theme-text-2 text-center py-3">Payment</h2>
        <Form>
          <Row className="payment-container">
            <Col md={6} className="payment-left">
              <h2 className="payheader theme-text-2 mb-md-4 mb-3">
                Customer Details
              </h2>
              <Form.Group className="form-floating mb-md-4 mb-3">
                <Form.Control
                  type="text"
                  id="email"
                  name="email"
                  placeholder="rakesh@yahoo.com"
                  value={formData.email}
                  onChange={(e) => handleFieldChange("email", e.target.value)}
                />
                <label htmlFor="email">Email</label>
              </Form.Group>
              <Form.Group className="form-floating mb-md-4 mb-3">
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  placeholder="John More Doe"
                  onChange={(e) =>
                    handleFieldChange("customername", e.target.value)
                  }
                />
                <label htmlFor="name">Name on Card</label>
              </Form.Group>
              <Form.Group className="form-floating mb-md-4 mb-3">
                <Form.Control
                  type="text"
                  id="number"
                  name="number"
                  placeholder="4242-4242-4242-4242"
                  onChange={(e) => handleFieldChange("number", e.target.value)}
                />
                <label htmlFor="ccnum">Credit card number</label>
              </Form.Group>
              <Row className="mb-md-0 mb-3 mx-0">
                <Col className="px-0">
                  <Form.Group className="form-floating">
                    <Form.Control
                      type="text"
                      id="expiryMonth"
                      name="expiryMonth"
                      placeholder="05"
                      onChange={(e) =>
                        handleFieldChange("expiryMonth", e.target.value)
                      }
                    />
                    <label htmlFor="expiryMonth">Exp Month</label>
                  </Form.Group>
                </Col>
                <Col className="px-0">
                  <Form.Group className="form-floating">
                    <Form.Control
                      type="text"
                      id="expiryYear"
                      name="expiryYear"
                      className="mid-field"
                      placeholder="2025"
                      onChange={(e) =>
                        handleFieldChange("expiryYear", e.target.value)
                      }
                    />
                    <label htmlFor="expiryYear">Exp Year</label>
                  </Form.Group>
                </Col>
                <Col className="px-0">
                  <Form.Group className="form-floating">
                    <Form.Control
                      type="text"
                      id="cvv"
                      name="cvv"
                      placeholder="352"
                      onChange={(e) => handleFieldChange("cvc", e.target.value)}
                    />
                    <label htmlFor="cvv">CVV</label>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col
              md={6}
              className="payment-right flex-column d-flex justify-content-between"
            >
              <div>
                <h2 className="payheader theme-text-2 mb-md-4 mb-3">Charge</h2>
                <Form.Group className="form-floating mb-md-4 mb-3">
                  <select
                    id="currency"
                    className="form-control form-select text-uppercase"
                    name="currency"
                    placeholder={"usd"}
                    value={formData.currency}
                    onChange={(e) =>
                      handleFieldChange("currency", e.target.value)
                    }
                  >
                    <option value="">Currency</option>
                    <option value="usd">usd</option>
                    <option value="cad">cad</option>
                    <option value="inr">inr</option>
                    <option value="eur">eur</option>
                    <option value="gbp">gbp</option>
                  </select>
                </Form.Group>
                <Form.Group className="form-floating mb-md-4 mb-3">
                  <Form.Control
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="2000"
                    value={formData.amount}
                    onChange={(e) =>
                      handleFieldChange("amount", e.target.value)
                    }
                  />
                  <label htmlFor="amount">Amount </label>
                  <span className="position-absolute end-0 h-100 top-0 d-flex align-items-center pe-3">
                    .00
                  </span>
                </Form.Group>
              </div>

              <div>
                <Form.Control
                  type="submit"
                  name="submit"
                  value="Submit"
                  className="btn add-button"
                  onClick={handleSubmit}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default Pay;
