import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { PencilSquare, Trash3Fill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import CustomLoader from "../loader/loader"; 
import TokenDetails from "../useUserDetails/useTokenDetails";

const CampaignDrafts = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [drafts, setDrafts] = useState([]);
  // Get data
  const getDrafts = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}campaigns/drafts/${myUserId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setDrafts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDrafts();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}campaigns/${id}`, {
                headers: {
                  Authorization: "Bearer " + myToken,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                getDrafts();
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : drafts?.length > 0 ? (
        <>
          <div className="d-flex mb-3 align-items-center justify-content-between gap-2 fle-wrap">
            <h5 className="theme-text mb-sm-0">Drafts</h5>
          </div>

          <div className="my-3">
            <Table className="striped" bordered responsive>
              <thead>
                <tr>
                  <th className="bg-theme bg-opacity-50 text-light">
                    Campaign Name
                  </th>
                  <th
                    className="bg-theme bg-opacity-50 text-light"
                    style={{ width: 100 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {drafts.map((campaign, index) => (
                  <tr key={index}>
                    <td>{campaign.campaign_name}</td>
                    <td className="d-flex align-items-center justify-content-center gap-2">
                      <Link
                        to={`/campaigns-dashboard/email-campaign/draft/${campaign.id}`}
                        className="btn-link theme-text text-center"
                      >
                        <PencilSquare />
                      </Link>
                      <Link
                        to={"#"}
                        className="btn-link text-danger text-center p-0"
                        onClick={() => deleteItem(campaign.id)}
                      >
                        <Trash3Fill />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <p>No Saved Drafts</p>
      )}
    </>
  );
};

export default CampaignDrafts;
