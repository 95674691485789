import React from "react";
import { Button, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicLocationEdit = ({ fields, index, onFieldChange }) => {
  const field = fields[index];

  const handleCityChange = (city) => {
    const updatedField = { ...field, city: city };
    const updatedFields = [...fields];
    updatedFields[index] = updatedField;
    onFieldChange(updatedFields);
  };

  const handleAddressChange = (address) => {
    const updatedField = { ...field, address: address };
    const updatedFields = [...fields];
    updatedFields[index] = updatedField;
    onFieldChange(updatedFields);
  };

  const onFieldRemove = () => {
    const updatedFields = fields.filter((_, i) => i !== index);
    onFieldChange(updatedFields);
  };

  return (
    <div className="d-flex gap-4 w-100 align-items-center mb-3">
      <div className="flex-grow-1 d-flex gap-3 flex-wrap">
        <div className="form-floating flex-grow-1">
          <Form.Control
            type="text"
            placeholder="City"
            value={field?.city}
            onChange={(e) => handleCityChange(e.target.value)}
            className="form-control italicInput"
            name={`city`}
            id={`city`}
          />
          <label className="form-label" htmlFor={`city`}>
            City
          </label>
        </div>
        <div className="form-floating flex-grow-1">
          <Form.Control
            type="text"
            placeholder="Address"
            value={field?.address}
            onChange={(e) => handleAddressChange(e.target.value)}
            className="form-control italicInput"
            name={`address`}
            id={`address`}
          />
          <label className="form-label" htmlFor={`address`}>
            Address
          </label>
        </div>
      </div>
      <Button
        className="clickable addStyle bg-danger border-danger"
        onClick={onFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default DynamicLocationEdit;
