import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import * as Actions from "../../constants/baseUrl";
import DurationSelect from "../durationSelect/durationSelect";

const BatchForm = ({ field, onFieldChange, onRemove, isCourse }) => {
  const myToken = localStorage.getItem("token");

  const [typesData, setTypesData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(moment().format("HH:mm")); // Initialize with the current time
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    label: userTimeZone,
    value: userTimeZone,
  });
  const [convertedUTCTime, setConvertedUTCTime] = useState("");

  const mainTimeZones = [
    "Asia/Kolkata",
    "Asia/Karachi",
    "Europe/London",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "Australia/Sydney",
    "Europe/Berlin",
    "Asia/Tokyo",
    "Africa/Johannesburg",
    "Europe/Paris",
    "America/Toronto",
    "Asia/Dubai",
    "America/Denver",
    "Asia/Hong_Kong",
    "America/Mexico_City",
    "Europe/Madrid",
    "Asia/Singapore",
    "America/Phoenix",
    "Australia/Perth",
    "Africa/Cairo",
    "Asia/Kuala_Lumpur",
  ];

  const timeZones = mainTimeZones.map((tz) => ({
    label: tz,
    value: tz,
  }));

  const handleFieldChange = (fieldName, fieldValue) => {
    const updatedField = {
      ...field,
      id: uuidv4(),
      [fieldName]: fieldValue,
    };

    onFieldChange(updatedField);
  };

  const convertToUTC = (givenTime, timeZone) => {
    const utcTime = moment
      .tz(givenTime, "HH:mm", timeZone)
      .utc()
      .format("HH:mm");
    setConvertedUTCTime(utcTime);
  };

  useEffect(() => {
    convertToUTC(selectedTime, selectedTimeZone?.value);
  }, [selectedTime, selectedTimeZone]);

  // Get course types
  const getType = () => {
    axios
      .get(`${Actions.BASE_URL}enums/teachingModes`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTypesData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    handleFieldChange("startTime", convertedUTCTime);
  }, [convertedUTCTime]);

  return (
    <div className="d-flex gap-3 mt-3 batch">
      <Row className="mx-0 flex-grow-1">
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              name="courseName"
              placeholder="Course Name"
              value={field?.courseName}
              onChange={(e) => handleFieldChange("courseName", e.target.value)}
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="courseName-label"
            >
              Course Name
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <ReactDatePicker
              selected={moment(selectedTime, "HH:mm").toDate()}
              onChange={(date) => setSelectedTime(moment(date).format("HH:mm"))}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              className="w-100 form-control form-select"
              dateFormat="h:mm aa"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Select
              className="form-control form-select w-100 timezone"
              id="timezone"
              options={timeZones}
              value={selectedTimeZone}
              onChange={(selectedOption) => {
                setSelectedTimeZone(selectedOption);
              }}
              placeholder="Select your time zone"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="date"
              className="w-100"
              name="date"
              value={field?.date}
              onChange={(e) => handleFieldChange("date", e.target.value)}
            />
            <Form.Label className="form-label file-label" htmlFor="date-label">
              Date
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <DurationSelect
              isCourse={isCourse}
              field={field}
              handleFieldChange={handleFieldChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="text"
              className="w-100"
              name="trainer"
              placeholder="Trainer"
              value={field?.trainer}
              onChange={(e) => handleFieldChange("trainer", e.target.value)}
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="trainer-label"
            >
              Trainer
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Select
              className="form-control"
              aria-label="event type"
              required
              onChange={(e) =>
                handleFieldChange("teachingMode", e.target.value)
              }
              id="subcategorylevel2"
              name="subcategorylevel2"
            >
              <option value="">Teaching Mode</option>
              {typesData.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="form-floating position-relative mb-3">
            <Form.Control
              type="url"
              className="w-100"
              name="register_link"
              placeholder="Link"
              value={field?.register_link}
              onChange={(e) =>
                handleFieldChange("register_link", e.target.value)
              }
            />
            <Form.Label
              className="form-label file-label"
              htmlFor="register_link-label"
            >
              Batch Register Link
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Button
        className="clickable addStyle bg-danger border-danger"
        onClick={() => onRemove(field.id)}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </div>
  );
};

export default BatchForm;
