import React, { Component } from "react";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import "./style.css";

class Get_Mentor extends Component {
  entities = "mentors";

  state = {
    title: "",
    phone: "",
    whatsapp: "",
    website: "",
    email: "",
  };
  componentDidMount() {
    console.log("Props:", this.props);
    this.populateList(this.props.params.id);
  }

  render() {
    return (
      <div className="container">
        <form
          className="offset-sm-2 col-lg-8 row mt-4 boxstyle"
          onSubmit={this.handleSubmit}
        >
          <div className="col-lg-6 col-md-6">
            <label className="col-sm-8 col-form-label">Title</label>
            <div className="col-lg-12">
              <input
                className="form-control"
                disabled
                name="title"
                defaultValue={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
              ></input>
              <input
                type="hidden"
                className="form-control"
                name="id"
                defaultValue={this.state.id}
              ></input>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <label className="col-sm-8 col-form-label">Email</label>
            <div className="col-lg-12">
              <input
                className="form-control"
                disabled
                name="email"
                defaultValue={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              ></input>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <label className="col-sm-8 col-form-label">Phone</label>
            <div className="col-lg-12">
              <input
                className="form-control"
                disabled
                name="phone"
                defaultValue={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              ></input>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <label className="col-sm-8 col-form-label">Whatsapp</label>
            <div className="col-lg-12">
              <input
                className="form-control"
                disabled
                name="whatsapp"
                defaultValue={this.state.whatsapp}
                onChange={(e) => this.setState({ whatsapp: e.target.value })}
              ></input>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <label className="col-sm-8 col-form-label">Website</label>
            <div className="col-lg-12">
              <input
                className="form-control"
                disabled
                name="website"
                defaultValue={this.state.website}
                onChange={(e) => this.setState({ website: e.target.value })}
              ></input>
            </div>
          </div>
          <div className="row col-lg-12 mt-4">
            <div className="col-sm-4 d-grid">
              <input
                type="reset"
                value="Back"
                className="btn btn-secondary"
                onClick={() => {
                  window.location.href = "/" + this.entities;
                }}
              ></input>
            </div>
          </div>
        </form>
      </div>
    );
  }
  async populateList(id) {
    try {
      const data = await fetch(
        `${Actions.BASE_URL}${this.entities}/${id}`
      ).then((response) => {
        console.log(response);
        return response.json();
      });

      this.setState(data);
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  }
}

export default withRouter(Get_Mentor);
