import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SubDomainRouter from "../subDomainRouter/subDomainRouter";
import useUserData from "../useUserDetails/useUserDetails";
import { AdminMenuDataMainSite } from "./adminmenudata";
import "./navbarstyle.css";

function AdminNavBar() {
  const userData = useUserData();

  const location = useLocation();
  const logoSrc = SubDomainRouter("logo");
  const coursesAlias = SubDomainRouter("coursesAlias");
  const demosAlias = SubDomainRouter("demosAlias");

  const homeNavItem = SubDomainRouter("homeNavItem");
  const demosNavItem = SubDomainRouter("demosNavItem");
  const coursesNavItem = SubDomainRouter("homeNavItem");
  const reviewsNavItem = SubDomainRouter("reviewsNavItem");
  const corporatesNavItem = SubDomainRouter("corporatesNavItem");
  const blogNavItem = SubDomainRouter("blogNavItem");
  const codeAINavItem = SubDomainRouter("codeAINavItem");

  return (
    <>
      <Navbar expand="lg" className="align-items-center py-0">
        <Container fluid="lg">
          <Link className="navbar-brand" to="/">
            <img src={`${logoSrc}`} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto my-lg-0 my-3 align-items-center">
              {homeNavItem === "show" && (
                <Link
                  to={`/`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/` && "active-link"
                }`}
                >
                  Home
                </Link>
              )}
              {demosNavItem === "show" && (
                <Link
                  to={`/demos`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/demos` && "active-link"
                }`}
                >
                  {demosAlias}
                </Link>
              )}

              {coursesNavItem === "show" && (
                <Link
                  to={`/courses`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/courses` && "active-link"
                }`}
                >
                  {coursesAlias}
                </Link>
              )}
              {reviewsNavItem === "show" && (
                <Link
                  to={`/reviews`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/reviews` && "active-link"
                }`}
                >
                  reviews
                </Link>
              )}
              {corporatesNavItem === "show" && (
                <Link
                  to={`/corporates`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/corporates` && "active-link"
                }`}
                >
                  corporates
                </Link>
              )}
              {blogNavItem === "show" && (
                <Link
                  to={`/blogs`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/blogs` && "active-link"
                }`}
                >
                  blogs
                </Link>
              )}

              {codeAINavItem === "show" && (
                <Link
                  to={`/code-ai`}
                  className={`
                nav-link text-capitalize ${
                  location.pathname === `/code-ai` && "active-link"
                }`}
                >
                  Code(AI)
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="right d-flex align-items-center gap-3">
            <NavDropdown
              className="dropDownImg d-flex align-items-center justify-content-center"
              title={
                <img
                  src={
                    userData?.photo_image_url ||
                    `https://placehold.co/280x280/42a49f/white?text=${
                      userData?.first_name?.slice(0, 1) ||
                      userData?.type?.slice(0, 1) ||
                      "I"
                    }`
                  }
                  loading="lazy"
                  alt=""
                />
              }
              id="basic-nav-dropdown"
            >
              {AdminMenuDataMainSite.slice(0, 5).map((item, index) => (
                <Link className="dropdown-item" key={index} to={item.Url}>
                  {item.title}
                </Link>
              ))}
              <NavDropdown.Divider />
              {AdminMenuDataMainSite.slice(5).map((item, index) => (
                <Link className="dropdown-item" key={index} to={item.Url}>
                  {item.title}
                </Link>
              ))}
              <NavDropdown.Divider />
              <Link
                className="dropdown-item"
                to="/super-admin-dashboard/config-management"
              >
                Super Admin Dashboard
              </Link>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  localStorage.removeItem("isValidUser");
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavBar;
