import Box from "@mui/material/Box";
import LightGallery from "lightgallery/react";
import * as React from "react";
import "./gallery.css";

// import styles
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const ImageGallery = ({ imageData }) => {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  const hasImage = imageData?.some(
    (item) => item.url !== null && item.url !== ""
  );

  return (
    <div className="gallery">
      <Box sx={{ minHeight: 400 }}>
        {imageData && hasImage ? (
          <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
          >
            {imageData?.map((item, index) => (
              <a
                href={item.url}
                key={index}
                className="d-block w-100 position-relative rounded-1 slider-img-btn"
              >
                <img
                  srcSet={item.url}
                  src={item.url}
                  alt={item.field_name}
                  loading="lazy"
                  className="w-100 d-block rounded-1 h-100 object-fit-cover"
                />
                {item.field_name !== "" && (
                  <div className="gallery-image-label p-1">
                    {item.field_name}
                  </div>
                )}
              </a>
            ))}
          </LightGallery>
        ) : (
          <p>No Images Found</p>
        )}
      </Box>
    </div>
  );
};

export default ImageGallery;
