import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import config from "../../global-config";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import { ErrorAlert } from "../../components/infoPopup/infoPopup";
import UnAuthNavBar from "../../components/headers/navbarUnAuth";
import RequestAuthenticate from "../../components/login/requestAuthenticate";
import CreatePassword from "./changepassword/changepassword";
import Home from "../home/Home";
import Corporates from "../corporates/corporates";
import Contact from "../contact";
import Footer from "../../components/footer/Footer";
import SuccessModal from "../../components/login/successModal";
import CustomLoader from "../../components/loader/loader";

const AuthPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");

  const siteName = SubDomainRouter("title");
  const favicon = SubDomainRouter("favicon");

  const loadedConfig = sessionStorage.getItem("storedData");
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    if (loadedConfig) {
      setConfigLoading(false);
    }
  }, [loadedConfig]);

  useEffect(() => {
    const currentDomain = window.location.hostname;

    if (config[currentDomain]) {
      document.title = siteName;
      updateFavicon(favicon);
    } else {
      console.log("Running on:", currentDomain);
    }
  }, [favicon, siteName]);

  const updateFavicon = (favicon) => {
    const link =
      document.querySelector("link[rel~='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (mode) {
      const request = {
        Email: e.currentTarget["email"].value,
        Password: e.currentTarget["password"].value,
        //?====== for prod===========
        username: `${e.currentTarget["email"].value}_${window.location.host}`,
        //?******** for local host
        // username: `${e.currentTarget["email"].value}_${"localhost"}`,
        host: window.location.host,
      };
      axios
        .post(`${Actions.AUTH_URL}Auth/Login`, request)
        .then((res) => {
          let data = res.data;
          if (
            data === "DOESNOTEXIST" ||
            data.AuthToken === null ||
            data.AuthToken === "" ||
            data.AuthToken === undefined
          ) {
            localStorage.setItem("isValidUser", false);
          } else {
            localStorage.setItem("isValidUser", true);
            localStorage.setItem("token", data.AuthToken);
          }
          window.location.reload();
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          handleApiError(error);
          setLoading(false);
        });
    } else {
      let request = {
        Password: e.currentTarget["createpassword"].value,
        Role: e.currentTarget["role"].value,
        Email: e.currentTarget["newEmail"].value,
        Phone: e.currentTarget["phone"].value,
        FirstName: e.currentTarget["firstname"].value,
        LastName: e.currentTarget["lastname"].value,
        FullName: `${e.currentTarget["firstname"].value} ${e.currentTarget["lastname"].value}`,
        //?====== for prod===========
        username: `${e.currentTarget["newEmail"].value}_${window.location.host}`,
        //?******** for local host
        // username: `${e.currentTarget["newEmail"].value}_${"localhost"}`,
        host: window.location.host,
      };
      axios
        .post(`${Actions.AUTH_URL}Users/PostUser`, request)
        .then((res) => {
          console.log(res?.data);
          if (res?.data?.Data === "SUCCESS") {
            setShowSuccessModal(true);
            formRef.current.reset();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          handleApiError(error);
          setLoading(false);
        });
    }
  };

  const handleApiError = (error) => {
    console.log(error);
    if (error?.message === "Network Error") {
      ErrorAlert(error?.message);
    }

    if (error.response?.data) {
      // Use a regular expression to capture everything until the first full stop or line break
      const match = error.response.data.match(
        /System\.ApplicationException: (.*?)(\n|$)/s
      );

      if (match && match.length > 1) {
        const errorMessage = match[1];
        setSignupErrorMsg(errorMessage.trim());
        console.log(errorMessage);
      } else {
        // If the error message is not in the expected format, log it for debugging purposes
        console.error("Unexpected error format:", error.response.data);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
      </Helmet>
      {/* If not authorized */}

      {configLoading ? (
        <CustomLoader height={"100vh"} className={"pt-0 mt-0 pb-0 mb-0"} />
      ) : (
        <>
          {location.pathname === "/" ||
          location.pathname === "/corporates" ||
          location.pathname === "/contact" ? (
            <UnAuthNavBar />
          ) : null}
          <Routes>
            <Route
              path="*"
              exact
              element={
                <div className={`app app--is-${mode ? "login" : "signup"}`}>
                  {loading ? (
                    <>
                      <span className="register-loader theme-text-2"> </span>
                      <span className="spinnerOverlay"></span>
                    </>
                  ) : null}
                  <RequestAuthenticate
                    mode={mode}
                    formRef={formRef}
                    signupErrorMsg={signupErrorMsg}
                    setMode={setMode}
                    setLoading={setLoading}
                    onSubmit={handleSubmit}
                    onChange={function (e) {
                      console.log(e.target.value);
                    }}
                  />
                </div>
              }
            />
            <Route path="/CreatePassword" element={<CreatePassword />} />
            <Route path="/" exact element={<Home />} />
            <Route path="/corporates" element={<Corporates />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          {location.pathname === "/" ||
          location.pathname === "/corporates" ||
          location.pathname === "/contact" ? (
            <Footer />
          ) : null}

          {showSuccessModal ? (
            <SuccessModal
              setMode={setMode}
              show={showSuccessModal}
              setshow={setShowSuccessModal}
              onHide={() => {
                setShowSuccessModal(false);
              }}
            />
          ) : null}
        </>
      )}
    </>
  );
};
export default AuthPage;
