import axios from "axios";
import { useEffect, useState } from "react";
import * as Actions from "../../constants/baseUrl";

const SubDomainRouter = (contentType) => {
  const [content, setContent] = useState("");
  const myToken = sessionStorage.getItem("token");

  useEffect(() => {
    const storedDomainData =
      JSON.parse(sessionStorage.getItem("storedData")) || {};

    // If data is already in sessionStorage, use it
    if (Object.keys(storedDomainData).length > 0) {
      if (contentType) {
        setContent(storedDomainData?.appsettings[contentType] || null);
      } else {
        setContent(storedDomainData?.appsettings);
      }
    } else {
      // Otherwise, make the API call and store the data
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Actions.BASE_URL}configs`, {
            headers: {
              Authorization: "Bearer " + myToken,
            },
          });
          if (contentType) {
            setContent(response?.data?.appsettings[contentType] || null);
          } else {
            setContent(storedDomainData?.appsettings);
          }

          sessionStorage.setItem("storedData", JSON.stringify(response.data));
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [contentType, myToken]);

  return content;
};

export default SubDomainRouter;
