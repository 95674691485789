// DynamicOptionsFieldset.js
import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";

const DynamicOptionsFieldset = ({ fields, setFields, index }) => {
  const handleFieldRemove = () => {
    const { [index]: removedField, ...updatedFields } = fields;
    setFields(updatedFields);
  };

  const handleInputChange = (value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [index]: value,
    }));
  };

  return (
    <Col md={6} className="d-flex align-items-center mb-3">
      <Form.Group className="form-floating flex-grow-1 pe-1">
        <Form.Control
          type="text"
          id={`Option${index}`}
          placeholder="Enter something"
          value={fields[index] || ""}
          onChange={(e) => handleInputChange(e.target.value)}
          className="form-control italicInput"
        />
        <Form.Label
          className="form-label text-capitalize"
          htmlFor={`Option${index}`}
        >
          Option {index}
        </Form.Label>
      </Form.Group>
      <Button
        className="clickable addStyle bg-danger border-danger"
        onClick={handleFieldRemove}
      >
        <span className="addBttn d-block">
          <Trash />
        </span>
      </Button>
    </Col>
  );
};

export default DynamicOptionsFieldset;
