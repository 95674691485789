import Masonry from "@mui/lab/Masonry";
import Box from "@mui/material/Box";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { PlayArrowOutlined } from "@mui/icons-material";

const Label = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  background: "transparent",
  color: "#fff",
  border: "none",
  boxShadow: "none",
  backdropFilter: "blur(3px)",
}));
const VideoGallery = ({ videoData }) => {
  const hasVideo = videoData?.some(
    (item) => item.url !== null && item.url !== ""
  );
  return (
    <div>
      <Box sx={{ minHeight: 400 }}>
        {videoData && hasVideo ? (
          <Masonry columns={2} spacing={2}>
            {videoData?.map((item, index) => (
              <div key={index} className="position-relative">
                <video
                  srcSet={item.url}
                  src={item.url}
                  alt={item.field_name}
                  title={item.field_name}
                  loading="lazy"
                  controls
                  className="d-block w-100 h-100 rounded-1 border-secondary border-3 border"
                ></video>
                {item.field_name !== "" && <Label>{item.field_name}</Label>}
              </div>
            ))}
          </Masonry>
        ) : (
          <p>No Videos Found</p>
        )}
      </Box>
    </div>
  );
};

export default VideoGallery;
