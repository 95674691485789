import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import CustomLoader from "../../../components/loader/loader";
import * as Actions from "../../../constants/baseUrl";
import DynamicGeneralFieldset from "../../../components/dynamicFieldset/dynamicFieldset";
import { ErrorAlert } from "../../../components/infoPopup/infoPopup";
import DynamicOptionsFieldset from "../../../components/dynamicOptionsFieldset/dynamicOptionsFieldset";

const ConfigsPage = () => {
  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);
  const [reqData, setReqData] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [domainData, setDomainData] = useState({
    title: "",
    name: "",
    logo: "",
    favicon: "",
    demoAlias: "",
    demosAlias: "",
    courseAlias: "",
    coursesAlias: "",
    trainersListID: "",
    fromEmail: "",
    countryFilter: "",
    tagsFilter: "",
    coursesFilter: "",
    learningLevelFilter: "",
    paymentFilter: "",
    teachingModeFilter: "",
    ratingsFilter: "",
    homeNavItem: "",
    demosNavItem: "",
    coursesNavItem: "",
    reviewsNavItem: "",
    corporatesNavItem: "",
    blogNavItem: "",
    contactNavItem: "",
    codeAINavItem: "",
  });
  const [countryTypes, setCountryTypes] = useState({});
  const [courseNames, setCourseNames] = useState({});
  const [subcategoryTypes, setSubcategoryTypes] = useState({});
  const [teachingModes, setTeachingModes] = useState({});
  const [paymentTypes, setPaymentTypes] = useState({});
  const [starRatings, setStarRatings] = useState({});
  const [userRoles, setUserRoles] = useState({});

  const myToken = localStorage.getItem("token");

  const populateList = async () => {
    axios
      .get(`${Actions.BASE_URL}configs`, {
        headers: {
          Authorization: "Bearer " + myToken,
        },
      })
      .then((response) => {
        setDomainData(response?.data?.appsettings);
        setReqData(response?.data);
        setLoading(false);
        setCountryTypes(response?.data?.categorysettings?.countryTypes);
        setCourseNames(response?.data?.categorysettings?.courseNames);
        setSubcategoryTypes(response?.data?.categorysettings?.subcategoryTypes);
        setTeachingModes(response?.data?.categorysettings?.teachingModes);
        setPaymentTypes(response?.data?.categorysettings?.paymentTypes);
        setStarRatings(response?.data?.categorysettings?.starRatings);
        setCustomFields(
          response?.data?.customfields || [{ key: "", value: "" }]
        );
        setUserRoles(response?.data?.appsettingsarray?.userRole);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (fieldName, fieldValue) => {
    setDomainData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(reqData);
    setReqLoading(true);

    const data = {
      ...reqData,
      id: reqData.id,
      type: reqData.type,
      userId: reqData.userId,
      appsettings: domainData,
      customfields: customFields,
      categorysettings: {
        ...reqData.categorysettings,
        countryTypes: countryTypes,
        courseNames: courseNames,
        subcategoryTypes: subcategoryTypes,
        paymentTypes: paymentTypes,
        teachingModes: teachingModes,
        starRatings: starRatings,
      },
    };

    axios
      .put(`${Actions.BASE_URL}configs/${reqData.id}`, data)
      .then((response) => {
        successAlert();
        sessionStorage.setItem("storedData", JSON.stringify(response.data));
        setReqLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error?.message === "Network Error") {
          ErrorAlert(error?.message);
        }
      });
  };

  const successAlert = (param) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui d-flex align-items-center justify-content-center flex-column bg-white p-4 rounded-1">
            <Check2Circle className="display-1 theme-text" />
            <h5 className="theme-text-2 text-center mt-2 text-capitalize">
              Data Updated
            </h5>
          </div>
        );
      },
    });
  };
  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="profile-edit">
          <h3 className="heading-text theme-text-2 text-center">
            Update Config Details
          </h3>
          <div className="demosAddForm">
            <Form className="row" onSubmit={handleSubmit}>
              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Type"
                    value={domainData?.title}
                    onChange={(e) => handleFieldChange("title", e.target.value)}
                  />
                  <label htmlFor="title">Site Title</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Type"
                    value={domainData?.name}
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                  />
                  <label htmlFor="name">Site Name</label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="logo"
                    name="logo"
                    placeholder="Logo Url"
                    value={domainData?.logo}
                    onChange={(e) => handleFieldChange("logo", e.target.value)}
                  />
                  <label htmlFor="logo">Logo Url</label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="favicon"
                    name="favicon"
                    placeholder="FavIcon URL"
                    value={domainData?.favicon}
                    onChange={(e) =>
                      handleFieldChange("favicon", e.target.value)
                    }
                  />
                  <label htmlFor="favicon">FavIcon URL</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="demoAlias"
                    name="demoAlias"
                    placeholder="demoAlias"
                    value={domainData?.demoAlias}
                    onChange={(e) =>
                      handleFieldChange("demoAlias", e.target.value)
                    }
                  />
                  <label htmlFor="demoAlias">demoAlias</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="demosAlias"
                    name="demosAlias"
                    placeholder="demosAlias"
                    value={domainData?.demosAlias}
                    onChange={(e) =>
                      handleFieldChange("demosAlias", e.target.value)
                    }
                  />
                  <label htmlFor="demosAlias">demosAlias</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="courseAlias"
                    name="courseAlias"
                    placeholder="courseAlias"
                    value={domainData?.courseAlias}
                    onChange={(e) =>
                      handleFieldChange("courseAlias", e.target.value)
                    }
                  />
                  <label htmlFor="courseAlias">courseAlias</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="coursesAlias"
                    name="coursesAlias"
                    placeholder="coursesAlias"
                    value={domainData?.coursesAlias}
                    onChange={(e) =>
                      handleFieldChange("coursesAlias", e.target.value)
                    }
                  />
                  <label htmlFor="coursesAlias">coursesAlias</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="adminEmail"
                    name="adminEmail"
                    placeholder="adminEmail"
                    value={domainData?.adminEmail}
                    onChange={(e) =>
                      handleFieldChange("adminEmail", e.target.value)
                    }
                  />
                  <label htmlFor="adminEmail">Admin Email</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="supportEmail"
                    name="supportEmail"
                    placeholder="supportEmail"
                    value={domainData?.supportEmail}
                    onChange={(e) =>
                      handleFieldChange("supportEmail", e.target.value)
                    }
                  />
                  <label htmlFor="supportEmail">Support Email</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="trainersListID"
                    name="trainersListID"
                    placeholder="trainersListID"
                    value={domainData?.trainersListID}
                    onChange={(e) =>
                      handleFieldChange("trainersListID", e.target.value)
                    }
                  />
                  <label htmlFor="trainersListID">Trainers List ID</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="fromEmail"
                    name="fromEmail"
                    placeholder="fromEmail"
                    value={domainData?.fromEmail}
                    onChange={(e) =>
                      handleFieldChange("fromEmail", e.target.value)
                    }
                  />
                  <label htmlFor="fromEmail">
                    Add Email to send mails from
                  </label>
                </Form.Group>
              </Col>
              <h6 className="theme-text-2 fw-normal mt-3">Navbar Items</h6>
              <hr />
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="homeNavItem"
                  label="Home Nav-Item"
                  checked={domainData?.homeNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("homeNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="demosNavItem"
                  label="Demos Nav-Item"
                  checked={domainData?.demosNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("demosNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="coursesNavItem"
                  label="Courses Nav-Item"
                  checked={domainData?.coursesNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("coursesNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="reviewsNavItem"
                  label="Reviews Nav-Item"
                  checked={domainData?.reviewsNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("reviewsNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="corporatesNavItem"
                  label="Corporates Nav-Item"
                  checked={domainData?.corporatesNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("corporatesNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="blogNavItem"
                  label="Blog Nav-Item"
                  checked={domainData?.blogNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("blogNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="contactNavItem"
                  label="Contact Nav-Item"
                  checked={domainData?.contactNavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("contactNavItem", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="codeAINavItem"
                  label="codeAI Nav-Item"
                  checked={domainData?.codeAINavItem === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("codeAINavItem", newValue);
                  }}
                />
              </Col>
              <h6 className="theme-text-2 fw-normal mt-3">Sidebar Filters</h6>
              <hr />
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="countryFilter"
                  label="Country Filter"
                  checked={domainData?.countryFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("countryFilter", newValue);
                  }}
                />
              </Col>

              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="tagsFilter"
                  label="Tags Filter"
                  checked={domainData?.tagsFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("tagsFilter", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="coursesFilter"
                  label="Courses Filter"
                  checked={domainData?.coursesFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("coursesFilter", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="learningLevelFilter"
                  label="Learning Level Filter"
                  checked={domainData?.learningLevelFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("learningLevelFilter", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="paymentFilter"
                  label="Payment Filter"
                  checked={domainData?.paymentFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("paymentFilter", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="teachingModeFilter"
                  label="Teaching Mode Filter"
                  checked={domainData?.teachingModeFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("teachingModeFilter", newValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="checkbox"
                  className="custom-checkbox ps-0 mb-3"
                  id="ratingsFilter"
                  label="Ratings Filter"
                  checked={domainData?.ratingsFilter === "show"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "show" : "hide";
                    handleFieldChange("ratingsFilter", newValue);
                  }}
                />
              </Col>

              {/*************** enums *****************/}
              {/* Countries */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Country Types</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setCountryTypes((prevCountryTypes) => {
                      const newIndex =
                        Object.keys(prevCountryTypes)?.length.toString();
                      return {
                        ...prevCountryTypes,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(countryTypes)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={countryTypes}
                      setFields={setCountryTypes}
                    />
                  ))}
                </Row>
              </Col>
              {/* Course Names */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Course Names</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setCourseNames((prevState) => {
                      const newIndex =
                        Object.keys(prevState)?.length.toString();
                      return {
                        ...prevState,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(courseNames)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={courseNames}
                      setFields={setCourseNames}
                    />
                  ))}
                </Row>
              </Col>
              {/* Subcategory Types */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Subcategory Types</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setSubcategoryTypes((prevState) => {
                      const newIndex =
                        Object.keys(prevState)?.length.toString();
                      return {
                        ...prevState,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(subcategoryTypes)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={subcategoryTypes}
                      setFields={setSubcategoryTypes}
                    />
                  ))}
                </Row>
              </Col>
              {/* Teaching Modes */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Teaching Modes</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setTeachingModes((prevState) => {
                      const newIndex =
                        Object.keys(prevState)?.length.toString();
                      return {
                        ...prevState,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(teachingModes)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={teachingModes}
                      setFields={setTeachingModes}
                    />
                  ))}
                </Row>
              </Col>
              {/* Payment Types */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Payment Types</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setPaymentTypes((prevState) => {
                      const newIndex =
                        Object.keys(prevState)?.length.toString();
                      return {
                        ...prevState,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(paymentTypes)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={paymentTypes}
                      setFields={setPaymentTypes}
                    />
                  ))}
                </Row>
              </Col>
              {/* StarRatings */}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">StarRatings</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    setStarRatings((prevState) => {
                      const newIndex =
                        Object.keys(prevState)?.length.toString();
                      return {
                        ...prevState,
                        [newIndex]: "",
                      };
                    });
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12} className="mt-3">
                <Row>
                  {Object.keys(starRatings)?.map((index) => (
                    <DynamicOptionsFieldset
                      key={index}
                      index={index}
                      fields={starRatings}
                      setFields={setStarRatings}
                    />
                  ))}
                </Row>
              </Col>

              {/***************  Custom Fields ********************/}
              <Col
                md={12}
                className="d-flex justify-content-between gap-3 mt-4 mb-2"
              >
                <h6 className="theme-text-2 flex-grow-1">Add Custom Fields</h6>
                <Button
                  className="clickable addStyle ms-auto d-flex"
                  onClick={() => {
                    const newField = { key: "", value: "" };
                    setCustomFields([...customFields, newField]);
                  }}
                >
                  <span className="addBttn d-block">+</span>
                </Button>
              </Col>
              <hr />
              <Col md={12}>
                {customFields?.map((field, index) => (
                  <DynamicGeneralFieldset
                    key={index}
                    fields={customFields}
                    index={index}
                    onFieldChange={(newFields) => {
                      setCustomFields(newFields);
                    }}
                  />
                ))}
              </Col>

              <Col
                lg={12}
                className="d-flex justify-content-end flex-wrap mt-2 gap-2"
              >
                <Button type="submit" className="add-button next w-auto">
                  Update
                </Button>
              </Col>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default ConfigsPage;
