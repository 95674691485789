import axios from "axios";
import React from "react";
import * as Actions from "../../constants/baseUrl";
import LandingNavBar from "./landingnavbar";

export default class ScheduleDemo extends React.Component {
  state = {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    whatsapp: "",
    demotitle: "",
    demodetails: "",
    datetime: null,
    duration: 0,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    var data = this.state;
    var msg =
      "<p>New Demo Request by <b>" +
      data.firstname +
      " " +
      data.lastname +
      "</b> Below are the more details for demo schedule </p><p>Demo Title: " +
      data.demotitle +
      "</p><p>Details: " +
      data.demodetails +
      "</p><p>Email: " +
      data.email +
      "</p><p>Phone: " +
      data.phone +
      "</p><p>Date & Time: " +
      data.datetime +
      "</p><p>Duration: " +
      data.duration +
      "</p>";
    console.log(msg);
    var emailRequest = {
      to: [
        "ameerpetit@gmail.com",
        "ravi.chinna225@gmail.com",
        "kumhyd2@yahoo.com",
        "ameerpetit.team@gmail.com",
      ],
      subject: "Demo Schedule Request",
      message: msg,
      isHTML: true,
    };

    axios.post(`${Actions.APIGATEWAY_URL}email`, emailRequest).then((res) => {
      alert(
        "Thank you for your interest!. We will reach out to you with an updated information."
      );
      window.location.reload();
    });
  };
  render() {
    return (
      <>
        <LandingNavBar />
        <form action="" method="post" role="form">
          <div className="container-lg col-lg-6 scheduledemo">
            <div className="row">
              <div className="">
                <h2 className="payheader">Demo Schedule Request</h2>
              </div>
              <p>
                <span className="mandatory">
                  Please fill all madatory (*) fields
                </span>
              </p>
              <div className="col-lg-6">
                <label htmlFor="name">
                  First Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="firstname"
                  className="form-control"
                  name="firstname"
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="email">
                  Last Name <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="lastname"
                  className="form-control"
                  name="lastname"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                  required
                />
              </div>

              <div className="col-lg-12 mt-2">
                <label htmlFor="demotitle">
                  Demo Session Title <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="demotitle"
                  className="form-control"
                  name="demotitle"
                  onChange={(e) => this.setState({ demotitle: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-12 mt-2">
                <label htmlFor="demodetails">
                  Demo Details <span className="mandatory">*</span>
                </label>
                <textarea
                  id="demodetails"
                  className="form-control"
                  name="demodetails"
                  rows={5}
                  onChange={(e) =>
                    this.setState({ demodetails: e.target.value })
                  }
                  required
                ></textarea>
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="specialization">
                  Email <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  name="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="phone">
                  Phone <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  name="phone"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="whatsapp">Whatsapp </label>
                <input
                  type="text"
                  id="whatsapp"
                  className="form-control"
                  name="whatsapp"
                  onChange={(e) => this.setState({ whatsapp: e.target.value })}
                  required
                />
              </div>

              <div className="col-lg-6 mt-2">
                <label htmlFor="datetime">
                  Date & Time {"(PST)"}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="datetime-local"
                  id="datetime"
                  className="form-control"
                  name="datetime"
                  onChange={(e) => this.setState({ datetime: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-6 mt-2">
                <label htmlFor="duration">
                  Duration {"("}Hours{")"} <span className="mandatory">*</span>
                </label>
                <input
                  type="number"
                  id="duration"
                  className="form-control"
                  name="duration"
                  onChange={(e) => this.setState({ duration: e.target.value })}
                  required
                />
              </div>
              <div className="mt-2"></div>
              {this.state.firstname != "" &&
              this.state.lastname != "" &&
              this.state.email != "" &&
              this.state.duration != "" &&
              this.state.demotitle != "" &&
              this.state.demodetails != "" ? (
                <div className="col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    className="btn btn-success form-control"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  />
                </div>
              ) : (
                <div className=" col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    name="submit"
                    value="Submit"
                    className="btn btn-default form-control"
                    disabled="disabled"
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}
