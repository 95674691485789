import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { PencilSquare, PlusCircle, Trash3Fill } from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import CustomLoader from "../loader/loader";
import TokenDetails from "../useUserDetails/useTokenDetails";

const Templates = () => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  // Get data
  const getTemplates = () => {
    setLoading(true);
    axios
      .get(`${Actions.BASE_URL}templates/users/${myUserId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTemplates(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}templates/${id}`, {
                headers: {
                  Authorization: "Bearer " + myToken,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                getTemplates();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="d-flex mb-3 align-items-center justify-content-between gap-2 fle-wrap">
            <h5 className="theme-text mb-sm-0">Templates</h5>
            <Link
              to="/campaigns-dashboard/template/add"
              className="btn add-button d-flex align-items-center"
            >
              <PlusCircle />
              <span className="d-lg-block text-nowrap d-none">
                &nbsp; Create Template
              </span>
            </Link>
          </div>

          <div className="my-3">
            {templates?.length > 0 ? (
              <Table className="striped" bordered responsive>
                <thead>
                  <tr>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Template Name
                    </th>
                    <th className="bg-theme bg-opacity-50 text-light">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, index) => (
                    <tr key={index}>
                      <td className="text-overflow-desc-xs text-nowrap">
                        {template.template_name}
                      </td>
                      <td>
                        <span className="d-flex align-items-center justify-content-center gap-2">
                          <Link
                            to={`/campaigns-dashboard/template/edit/${template.id}`}
                            className="btn-link theme-text text-center me-1"
                          >
                            <PencilSquare />
                          </Link>
                          <Link
                            to={"#"}
                            className="btn-link text-danger text-center p-0"
                            onClick={() => deleteItem(template.id)}
                          >
                            <Trash3Fill />
                          </Link>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No Templates Founds...</p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Templates;
