import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import withRouter from "../../constants/withRouter";
import CustomLoader from "../loader/loader";
import TokenDetails from "../useUserDetails/useTokenDetails";

const TelegramListContactForm = (props) => {
  const myToken = localStorage.getItem("token");
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const contactId = props?.params?.contactId;
  const listId = props?.params?.listId;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(contactId ? true : false);
  const [formData, setFormData] = useState({
    userId: myUserId,
    lists: [listId],
    type: "contact",
    approval_status: "",
    entityRelation: [{ telegramlist: listId }],
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    telegram: "",
    website: "",
    city: "",
    title: "",
    industry: "",
  });

  const handleFieldChange = (fieldName, fieldValue) => {
    const newState = {
      ...formData,
      [fieldName]: fieldValue,
    };
    setFormData(newState);
  };

  const populateForm = () => {
    axios
      .get(`${Actions.BASE_URL}contacts/${contactId}`, {
        headers: {
          Authorization: "Bearer " + myToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (contactId) {
      populateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      ...formData,
    };
    if (contactId && listId) {
      axios
        .put(`${Actions.BASE_URL}contacts/${contactId}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          navigate(`/my-contacts/telegram-list/contact/${listId}`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    } else {
      axios
        .post(`${Actions.BASE_URL}contacts/${myUserId}/${listId}`, data, {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          navigate(`/my-contacts/telegram-list/contact/${listId}`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    }
  };

  const hasData =
    formData?.first_name === "" ||
    formData?.last_name === "" ||
    formData?.telegram === "";

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="formMain create my-5 py-5">
          <div className="demosAddFormHeader text-center mt-3">
            <h2 className="addFormHeading theme-text-2">Personal Details</h2>
          </div>
          <fieldset className="wizard-fieldset show demosAddForm py-4">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="mx-0 align-items-end">
                <Col md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      className="form-control italicInput"
                      name="first_name"
                      value={formData?.first_name}
                      id="first_name"
                      onChange={(e) =>
                        handleFieldChange("first_name", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="first_name">
                      First Name
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      className="form-control italicInput"
                      name="last_name"
                      value={formData?.last_name}
                      id="last_name"
                      onChange={(e) =>
                        handleFieldChange("last_name", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="last_name">
                      Last Name
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <small className="text-end d-block text-dark">
                    required<span className="text-danger">*</span>
                  </small>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="telegram"
                      className="form-control"
                      name="telegram"
                      value={formData?.telegram}
                      id="telegram"
                      onChange={(e) =>
                        handleFieldChange("telegram", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="telegram">
                      Telegram
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Phone #"
                      className="form-control"
                      name="phone"
                      value={formData?.phone}
                      id="phone"
                      onChange={(e) =>
                        handleFieldChange("phone", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="phone">
                      Phone #
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-floating mb-3">
                    <Form.Control
                      type="text"
                      placeholder="email"
                      className="form-control"
                      name="email"
                      value={formData?.email}
                      id="email"
                      onChange={(e) =>
                        handleFieldChange("email", e.target.value)
                      }
                    />
                    <Form.Label className="form-label" htmlFor="email">
                      Email Address
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col md={12} className="mt-3">
                  <Link
                    to={`/my-contacts/telegram-list/contact/${listId}`}
                    className="previous-button add-button btn demosEditBtn"
                  >
                    Cancel
                  </Link>
                  <Button
                    type="submit"
                    disabled={hasData}
                    className="next add-button btn demosEditBtn"
                  >
                    {contactId && listId ? "Update Contact" : "Add Contact"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Container>
      )}
    </>
  );
};

export default withRouter(TelegramListContactForm);
