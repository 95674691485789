import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import "./landingnavbarstyle.css"

function LandingNavBar() {
  return (
    <Navbar expand="lg" className="NavbarItems">
    <Navbar.Brand href="/">
      <img src={logo} alt="" className="logstyle" width={160} height={70} />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
     
    </Navbar.Collapse>
  </Navbar>
  

  );
}

export default LandingNavBar;
