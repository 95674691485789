const NewsletterBody = (details, trainersListID) => {
  const htmlContent = `
        <table width="600px" style="max-width: 600px; width: 100%;" border="0" cellspacing="0" cellpadding="0"
        align="center" style="text-wrap: balance;">
        <tr>
            <td height="24"></td>
        </tr>
        <!-- Main Message -->
        <tr>
            <td>
                <span style="font-weight: bold; font-size: 16px; font-family: 'Unbounded', sans-serif;">
                    Hey,
                </span>
            </td>
        </tr>
        <tr>
            <td height="6"></td>
        </tr>
        <tr>
            <td width="100%" style="line-height: 1.3;">
                <span style="font-size: 12px; font-family: 'Poppins', sans-serif;">
                 A new subscriber has just joined our newsletter. Please checkout the following details:
                </span>
            </td>
        </tr>
        <tr>
            <td height="30"></td>
        </tr>
    </table>
    <!-- User Details -->
    <table width="600px" style="max-width: 600px; width: 100%; border-radius: 4px;" border="0"
        cellspacing="0" cellpadding="0" align="center" bgcolor="#e7eeee">
        <tr>
            <td height="12"></td>
        </tr>
        <tr>
            <td width="12"></td>
            <td>
                <table width="574px"
                    style="max-width: 574px; width: 100%; font-size: 12px; font-family: 'Poppins', sans-serif;"
                    border="0" cellspacing="0" cellpadding="0" align="center">
                     <tr>
                        <td height="6"></td>
                    </tr> 
                    <tr>
                        <td>
                            <span>
                                <strong>Email:</strong>&nbsp; ${details?.subscriberEmail}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td height="12"></td>
                    </tr> 
                </table>
            </td>
            <td width="12"></td>
        </tr>
        <tr>
            <td height="12"></td>
        </tr>
    </table>
  `;

  return htmlContent;
};

export default NewsletterBody;
