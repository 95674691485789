import React from "react";
import * as Actions from "../../constants/baseUrl";
import axios from "axios";
import "./intro.css";
import LandingNavBar from "./landingnavbar";
import Footer from "../footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddLogo from "./AddLogo.png";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default class EmailComposer extends React.Component {
  state = {
    subject: "",
    from: "",
    toAddress: "",
    body: "",
  };
  state = {
    editorState: EditorState.createEmpty(),
  };
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  handleSubmit = (event) => {
    debugger;
    event.preventDefault();
    var data = this.state;
    var msg = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    var emailRequest = {
      to: data.toAddress.split(";"),
      subject: data.subject,
      message: msg,
      isHTML: true,
    };

    axios.post(`${Actions.APIGATEWAY_URL}email`, emailRequest).then((res) => {
      alert("Email(s) Sent Successfully");
      window.location.reload();
    });
  };
  render() {
    const { editorState } = this.state;
    return (
      <>
        <form action="" method="post" role="form">
          <div className="container-lg col-lg-6 scheduledemo">
            <div className="row">
              <div className="">
                <h2 className="payheader">Compose Email</h2>
              </div>
              <p>
                <span className="mandatory">
                  Please fill all madatory (*) fields
                </span>
              </p>
              <div className="col-lg-12">
                <label htmlFor="subject">
                  Subject <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  className="form-control"
                  name="subject"
                  onChange={(e) => this.setState({ subject: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-12 mt-2">
                <label htmlFor="toAddress">
                  To Address<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="toAddress"
                  className="form-control"
                  name="toAddress"
                  onChange={(e) => this.setState({ toAddress: e.target.value })}
                  required
                />
              </div>
              <div className="col-lg-12 mt-2">
                <label htmlFor="body">
                  Message <span className="mandatory">*</span>
                </label>
                {/* <textarea id="body" className="form-control" rows={10} name="body" onChange={(e) => this.setState({ body: e.target.value })} required></textarea> */}
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  editorContent={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  editorStyle={{ height: "150px", border: "1px solid #000" }}
                />
              </div>

              {this.state.subject != "" && this.state.toAddress != "" ? (
                <div className="col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    className="btn btn-success form-control"
                    name="submit"
                    value="Send"
                    onClick={this.handleSubmit}
                  />
                </div>
              ) : (
                <div className=" col-lg-12 mt-5">
                  {" "}
                  <input
                    type="submit"
                    name="submit"
                    value="Send"
                    className="btn btn-default form-control"
                    disabled="disabled"
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}
