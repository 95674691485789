import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import config from "./global-config";

//************ Used Routes *************//
import Footer from "./components/footer/Footer";
import AboutPage from "./pages/about/AboutPage";
import Contact from "./pages/contact/";
import Home from "./pages/home/Home";

import AdminNavbar from "./components/headers/adminnavbar";
import Navbar from "./components/headers/navbar";

import MyDashboard from "./pages/dashboard/dashboard";
import Subscriptions from "./pages/subscriptions/subscriptions";

import AddReview from "./pages/reviews/addReview";
import ReviewDetails from "./pages/reviews/details";
import ReviewEdit from "./pages/reviews/edit";
import Reviews from "./pages/reviews/getAll";

import AddBlog from "./pages/blog/add";
import BlogDetails from "./pages/blog/details";
import Blogs from "./pages/blog/getAll";

import DemoAdd from "./pages/demos/add";
import DemoDetails from "./pages/demos/details";
import DemoEdit from "./pages/demos/edit";
import Demos from "./pages/demos/getAll";
import PastDemos from "./pages/demos/pastDemos";

import AddCourse from "./pages/courses/add";
import CloneCourse from "./pages/courses/clone";
import CourseDetails from "./pages/courses/details";
import EditCourse from "./pages/courses/edit";
import CourseListing from "./pages/courses/getAll";

import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import CloneDemo from "./pages/demos/clone";
import Enquiry from "./pages/enquiry/enquiry";

import MyCourses from "./components/myCourses/myCourses";
import MyDemos from "./components/myDemos/myDemos";
import MyGroups from "./pages/myGroups/myGroups";

import DraftForm from "./components/campaignEmail/draftForm";
import CampaignDrafts from "./components/campaignEmail/drafts";
import EmailCampaign from "./components/campaignEmail/emailCampaign";
import CampaignDashboard from "./pages/campaignDashboard/campaignDashboard";

import TemplateForm from "./components/campaignEmail/templateForm";
import Templates from "./components/campaignEmail/templates";

import EmailListAdd from "./components/campaignEmailLists/EmailListAdd";
import EmailListContactForm from "./components/campaignEmailLists/EmailListContactForm";
import EmailContacts from "./components/campaignEmailLists/emailContacts";
import EmailLists from "./components/campaignEmailLists/emailLists";

import ShareData from "./pages/share/share";

import ChatAI from "./pages/chatAI/chatAI";

import Coordinator from "./pages/coordinator/coordinator";
import Corporates from "./pages/corporates/corporates";
import HowItWorks from "./pages/howItWorks/howItWorks";
import ProfileEdit from "./pages/profile/edit";
import Profile from "./pages/profile/profile";

import AllContacts from "./pages/myContacts/allContacts";
import MyContacts from "./pages/myContacts/myContacts";

import WhatsappContacts from "./components/campaignWhatsappLists/whatsappContacts";
import WhatsappListsAddForm from "./components/campaignWhatsappLists/whatsappListAdd";
import WhatsappListContactForm from "./components/campaignWhatsappLists/whatsappListContactForm";
import WhatsappLists from "./components/campaignWhatsappLists/whatsappLists";

import TelegramContacts from "./components/campaignTelegramLists/telegramContacts";
import TelegramListsAddForm from "./components/campaignTelegramLists/telegramListAdd";
import TelegramListContactForm from "./components/campaignTelegramLists/telegramListContactForm";
import TelegramLists from "./components/campaignTelegramLists/telegramLists";

import PhoneContacts from "./components/campaignPhoneLists/phoneContacts";
import PhoneListsAddForm from "./components/campaignPhoneLists/phoneListAdd";
import PhoneListContactForm from "./components/campaignPhoneLists/phoneListContactForm";
import PhoneLists from "./components/campaignPhoneLists/phoneLists";

import GroupAdd from "./pages/myGroups/groupAdd";
import GroupEdit from "./pages/myGroups/groupEdit";
import GroupItems from "./pages/myGroups/groupItems";

import RequestSponsorship from "./pages/sponsorship/requestSponsorship";

import SMSCampaign from "./components/campaignPhone/campaignPhone";
import TelegramCampaign from "./components/campaignTelegram/campaignTelegram";
import WhatsAppCampaign from "./components/campaignWhatsapp/whatsappCampaign";

import Users from "./pages/admin/users/users";
import UsersEdit from "./pages/admin/users/usersEdit";
import Registrants from "./pages/registrants/Registrants";

import DailyNewsletter from "./components/dailyNewsletter/dailyNewsletter";
import GeneratedEmail from "./pages/emailTemplate/generatedEmail";

import Register from "./pages/register/register";

import SubDomainRouter from "./components/subDomainRouter/subDomainRouter";
import TokenDetails from "./components/useUserDetails/useTokenDetails";
import useUserData from "./components/useUserDetails/useUserDetails";

import UploadFiles from "./pages/admin/uploader/fileupload";
import JoinAsTrainer from "./pages/joinAsTrainer/joinAsTrainer";
import UniqueItems from "./pages/myGroups/uniqueItems";
import Pay from "./pages/pay/paymentNew";

import LoginSuccess from "./components/login/loginsuccess";
import ChangePassword from "./pages/auth/changepassword/changepassword";

// ! Unused Routes
import AddInstitute from "./components/institutes/add";
import CloneInstitute from "./components/institutes/clone";
import InstituteDetails from "./components/institutes/details";
import EditInstitute from "./components/institutes/edit";
import InstituteListing from "./components/institutes/getAll";
import AdminPage from "./pages/admin/admin";

import ProjectAdd from "./components/projects/add";
import ProjectDetails from "./components/projects/details";
import ProjectEdit from "./components/projects/edit";
import Projects from "./components/projects/getAll";

import TeamAdd from "./components/teams/add";
import TeamDetails from "./components/teams/details";
import TeamEdit from "./components/teams/edit";
import Teams from "./components/teams/getAll";

import SubscriberAdd from "./components/subscribers/add";
import SubscriberDetails from "./components/subscribers/details";
import SubscriberEdit from "./components/subscribers/edit";
import Subscribers from "./components/subscribers/getAll";

import MentorAdd from "./components/mentors/add";
import MentorDetails from "./components/mentors/details";
import MentorEdit from "./components/mentors/edit";
import Mentors from "./components/mentors/getAll";

import BigBrotherAdd from "./components/bigbrothers/add";
import BigBrotherDetails from "./components/bigbrothers/details";
import BigBrotherEdit from "./components/bigbrothers/edit";
import BigBrothers from "./components/bigbrothers/getAll";

import Privacy from "./components/privacy/privacy";
import Terms from "./components/terms/terms";

import CourseRegistration from "./components/landing/demoregistration";
import EmailComposer from "./components/landing/email";

import CustomLoader from "./components/loader/loader";
import TrainerAdd from "./components/trainers/add";
import TrainerDetails from "./components/trainers/details";
import TrainerEdit from "./components/trainers/edit";
import Trainers from "./components/trainers/getAll";
import TrainerVideosAdd from "./components/trainers/intro";
import DisplayVideos from "./components/videos/display";
import TrainerDisplayVideos from "./components/videos/trainerVideos";
import ConfigsPage from "./pages/admin/configs/config";
import SuperAdminDashboard from "./pages/superAdmin/SuperAdminDashboard";
import ConfigManagement from "./pages/superAdmin/configManagement";
import AddConfigs from "./pages/superAdmin/AddConfig";
import EditConfig from "./pages/superAdmin/EditConfig";

export default function App() {
  const tokenDetails = TokenDetails();
  const userData = useUserData();
  const role = userData.role;
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const favicon = SubDomainRouter("favicon");

  const loadedConfig = sessionStorage.getItem("storedData");
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    if (loadedConfig) {
      setConfigLoading(false);
    }
  }, [loadedConfig]);

  useEffect(() => {
    const currentDomain = window.location.hostname;

    if (config[currentDomain]) {
      document.title = siteName;
      updateFavicon(favicon);
    } else {
      console.log("Running on:", currentDomain);
    }
  }, [favicon, siteName]);

  const updateFavicon = (favicon) => {
    const link =
      document.querySelector("link[rel~='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const specRoles =
    role === "admin" ||
    role === "superadmin" ||
    role === "trainer" ||
    role === "training-coordinator" ||
    myUserId === "ravi.chinna225@gmail.com";

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
      </Helmet>
      {configLoading ? (
        <CustomLoader height={"100vh"} className={"pt-0 mt-0 pb-0 mb-0"} />
      ) : (
        <>
          <div className="mainmenu">
            {role === "admin" || "superadmin" ? <AdminNavbar /> : <Navbar />}
          </div>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/courses" element={<CourseListing />} />
            <Route path="/courses/details/:id" element={<CourseDetails />} />

            <Route path="/trainers" element={<Trainers />} />
            <Route path="/trainers/intro" element={<TrainerVideosAdd />} />
            <Route path="/trainers/details/:id" element={<TrainerDetails />} />

            <Route path="/code-ai" element={<ChatAI />} />
            <Route path="/corporates" element={<Corporates />} />

            <Route path="/institutes" element={<InstituteListing />} />
            <Route
              path="/institutes/details/:id"
              element={<InstituteDetails />}
            />

            <Route path="/reviews" element={<Reviews />} />
            <Route
              path="/reviews/details/:entityType/:entityId/:id"
              element={<ReviewDetails />}
            />
            <Route path="/reviews/edit/:id" element={<ReviewEdit />} />
            <Route
              path="/reviews/:entityType/:id/add"
              element={<AddReview />}
            />

            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/details/:id" element={<BlogDetails />} />
            <Route path="/blogs/add" element={<AddBlog />} />
            <Route path="/blogs/edit/:id" element={<AddBlog />} />

            <Route path="/demos" element={<Demos />} />
            <Route path="/demos/details/:id" element={<DemoDetails />} />

            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/details/:id" element={<ProjectDetails />} />
            <Route path="/projects/add" element={<ProjectAdd />} />
            <Route path="/projects/edit/:id" element={<ProjectEdit />} />

            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/details/:id" element={<TeamDetails />} />
            <Route path="/teams/add" element={<TeamAdd />} />
            <Route path="/teams/edit/:id" element={<TeamEdit />} />

            <Route path="/subscribers" element={<Subscribers />} />
            <Route
              path="/subscribers/details/:id"
              element={<SubscriberDetails />}
            />
            <Route path="/subscribers/add" element={<SubscriberAdd />} />
            <Route path="/subscribers/edit/:id" element={<SubscriberEdit />} />

            <Route path="/mentors" element={<Mentors />} />
            <Route path="/mentors/details/:id" element={<MentorDetails />} />
            <Route path="/mentors/add" element={<MentorAdd />} />
            <Route path="/mentors/edit/:id" element={<MentorEdit />} />

            <Route path="/bigbrothers" element={<BigBrothers />} />
            <Route
              path="/bigbrothers/details/:id"
              element={<BigBrotherDetails />}
            />
            <Route path="/bigbrothers/add" element={<BigBrotherAdd />} />
            <Route path="/bigbrothers/edit/:id" element={<BigBrotherEdit />} />

            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/videos" element={<DisplayVideos />} />
            <Route path="/trainervideos" element={<TrainerDisplayVideos />} />

            <Route path="/pay" element={<Pay />} />

            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/loginsuccess" element={<LoginSuccess />} />

            <Route path="/email" element={<EmailComposer />} />
            <Route path="/subscriptions" element={<Subscriptions />} />

            <Route path="/share/:entityType/:id" element={<ShareData />} />
            <Route path="/register/:entityType/:id" element={<Register />} />

            <Route
              path="/courseregistration"
              element={<CourseRegistration />}
            />

            <Route
              path="/enquiries/:entityType/:id/add"
              element={<Enquiry />}
            />
            <Route path="/coordinator/join" element={<Coordinator />} />
            <Route path="/trainer/join" element={<JoinAsTrainer />} />

            <Route path="/my-profile" element={<Profile />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />

            <Route path="/how-it-works" element={<HowItWorks />} />

            <Route path="/my/:id" element={<UniqueItems />} />

            <Route
              path="/request-sponsor/:entityType/:id"
              element={<RequestSponsorship />}
            />

            {/* superadmin Routes */}
            {/* {role === "superadmin" && ( */}
            <Route
              path="/super-admin-dashboard/*"
              element={<SuperAdminDashboard />}
            >
              <Route path="config-management" element={<ConfigManagement />} />
              <Route path="config/add" element={<AddConfigs />} />
              <Route path="config/edit/:id" element={<EditConfig />} />
            </Route>
            {/* )} */}
            {/* ________/\_________ */}

            {/* Dashboard Routes */}
            <Route path="/dashboard/*" element={<MyDashboard />}>
              <Route path="my-groups" element={<MyGroups />} />
              <Route index path="my-demos" element={<MyDemos />} />
              <Route path="my-courses" element={<MyCourses />} />
            </Route>
            {/* ________/\_________ */}

            {role === "admin" || "superadmin" ? (
              <>
                <Route
                  path="/campaigns-dashboard/*"
                  element={<CampaignDashboard />}
                >
                  <Route path="newsletter" element={<DailyNewsletter />} />
                </Route>
                <Route path="/admin" element={<AdminPage />}>
                  <Route path="users" element={<Users />} />
                  <Route path="upload" element={<UploadFiles />} />
                  <Route path="configs" element={<ConfigsPage />} />
                </Route>
                <Route path="/admin/users/edit/:id" element={<UsersEdit />} />
                <Route path="/trainers/add" element={<TrainerAdd />} />
              </>
            ) : null}
            {/* Routes accessible to admin */}
            {specRoles && (
              <>
                <Route path="/courses/add" element={<AddCourse />} />
                <Route path="/courses/edit/:id" element={<EditCourse />} />
                <Route path="/courses/clone/:id" element={<CloneCourse />} />

                <Route path="/institutes/add" element={<AddInstitute />} />
                <Route
                  path="/institutes/edit/:id"
                  element={<EditInstitute />}
                />
                <Route
                  path="/institutes/clone/:id"
                  element={<CloneInstitute />}
                />

                <Route path="/trainers/edit/:id" element={<TrainerEdit />} />

                <Route path="/demos/past" element={<PastDemos />} />
                <Route path="/demos/add" element={<DemoAdd />} />
                <Route path="/demos/clone/:id" element={<CloneDemo />} />
                <Route path="/demos/edit/:id" element={<DemoEdit />} />

                <Route path="/groups/add" element={<GroupAdd />} />
                <Route path="/groups/edit/:id" element={<GroupEdit />} />
                <Route path="/groups/items/:id" element={<GroupItems />} />

                <Route
                  path="/registrants/:entity/:id"
                  element={<Registrants />}
                />

                {/* contacts */}
                <Route path="/my-contacts/*" element={<MyContacts />}>
                  <Route path="all-contacts" element={<AllContacts />} />
                  <Route path="email-lists" element={<EmailLists />} />
                  <Route path="whatsapp-lists" element={<WhatsappLists />} />
                  <Route path="telegram-lists" element={<TelegramLists />} />
                  <Route path="sms-lists" element={<PhoneLists />} />
                </Route>
                {/* EmailLists */}
                <Route
                  path="/my-contacts/email-list/contact/:id"
                  element={<EmailContacts />}
                />
                <Route
                  path="/my-contacts/email-list/contact/:listId/:contactId/edit"
                  element={<EmailListContactForm />}
                />
                <Route
                  path="/my-contacts/email-list/contact/:listId/add"
                  element={<EmailListContactForm />}
                />
                <Route
                  path="/my-contacts/email-list/add"
                  element={<EmailListAdd />}
                />
                <Route
                  path="/my-contacts/email-list/:id/edit"
                  element={<EmailListAdd />}
                />
                {/* WhatsappLists */}
                <Route
                  path="/my-contacts/whatsapp-list/:id/edit"
                  element={<WhatsappListsAddForm />}
                />
                <Route
                  path="/my-contacts/whatsapp-list/add"
                  element={<WhatsappListsAddForm />}
                />
                <Route
                  path="/my-contacts/whatsapp-list/contact/:listId/:contactId/edit"
                  element={<WhatsappListContactForm />}
                />
                <Route
                  path="/my-contacts/whatsapp-list/contact/:listId/add"
                  element={<WhatsappListContactForm />}
                />
                <Route
                  path="/my-contacts/whatsapp-list/contact/:id"
                  element={<WhatsappContacts />}
                />
                {/* TelegramLists */}
                <Route
                  path="/my-contacts/telegram-list/:id/edit"
                  element={<TelegramListsAddForm />}
                />
                <Route
                  path="/my-contacts/telegram-list/add"
                  element={<TelegramListsAddForm />}
                />
                <Route
                  path="/my-contacts/telegram-list/contact/:listId/:contactId/edit"
                  element={<TelegramListContactForm />}
                />
                <Route
                  path="/my-contacts/telegram-list/contact/:listId/add"
                  element={<TelegramListContactForm />}
                />
                <Route
                  path="/my-contacts/telegram-list/contact/:id"
                  element={<TelegramContacts />}
                />
                {/* PhoneLists */}
                <Route
                  path="/my-contacts/sms-list/:id/edit"
                  element={<PhoneListsAddForm />}
                />
                <Route
                  path="/my-contacts/sms-list/add"
                  element={<PhoneListsAddForm />}
                />
                <Route
                  path="/my-contacts/sms-list/contact/:listId/:contactId/edit"
                  element={<PhoneListContactForm />}
                />
                <Route
                  path="/my-contacts/sms-list/contact/:listId/add"
                  element={<PhoneListContactForm />}
                />
                <Route
                  path="/my-contacts/sms-list/contact/:id"
                  element={<PhoneContacts />}
                />

                {/* campaigns */}
                <Route
                  path="/campaigns-dashboard/*"
                  element={<CampaignDashboard />}
                >
                  <Route
                    index
                    path="email-campaign"
                    element={<EmailCampaign />}
                  />
                  <Route path="templates" element={<Templates />} />
                  <Route path="drafts" element={<CampaignDrafts />} />
                  <Route
                    path="whatsapp-campaign"
                    element={<WhatsAppCampaign />}
                  />
                  <Route
                    path="telegram-campaign"
                    element={<TelegramCampaign />}
                  />
                  <Route path="sms-campaign" element={<SMSCampaign />} />
                </Route>

                <Route
                  path="/campaigns-dashboard/email-campaign/draft/:id"
                  element={<DraftForm />}
                />
                <Route
                  path="/campaigns-dashboard/template/edit/:id"
                  element={<TemplateForm />}
                />
                <Route
                  path="/campaigns-dashboard/template/add"
                  element={<TemplateForm />}
                />

                {/* Email template --generated */}
                <Route path="/campaigns/email" element={<GeneratedEmail />} />
              </>
            )}

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}
