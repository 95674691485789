import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Share } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import * as Actions from "../../constants/baseUrl";
import INSTITUTEIMG from "../../img/institute.png";
import CustomLoader from "../loader/loader";
import "./InstituteCard.css";

function Institute() {
  const entities = "institutes";

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const populateList = async () => {
    try {
      await axios.get(`${Actions.BASE_URL}${entities}`).then((response) => {
        setItems(response.data);
        setLoading(false);
      });
    } catch (error) {
      console.log("There was an error", error);
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const homeInstituteData = items.slice(0, 3);

  return (
    <section className="instituteSection section semi-bg">
      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg">
          <h2 className="main-heading text-center pb-3">Top Institutes</h2>
          <Row className="justify-content-center mx-0">
            {homeInstituteData?.length > 0 ? (
              homeInstituteData.map((item) => (
                <Col xl={4} md={6} sm={10} className="mt-2 mb-3" key={item.id}>
                  <Card className="demoCard">
                    <div className="itemImg">
                      <Link
                        to={`/${entities}/details/${item.id}`}
                        className="linkImg"
                      >
                        <img
                          src={
                            item.listing_image_url ||
                            item.hero_image_url ||
                            INSTITUTEIMG
                          }
                          className="img-fluid"
                          alt="Load Error"
                        />
                      </Link>
                    </div>
                    <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                      <div className="card-details text-center">
                        <h3 className="itemName text-left mb-0">
                          <Link
                            to={`/${entities}/details/${item.id}`}
                            className="detailLink text-left"
                          >
                            {item.name}
                          </Link>
                        </h3>

                        <Link
                          to={`/share/course/${item.id}`}
                          className="entity-share-btn d-flex align-items-center cursor-pointer float-end"
                          title="review"
                        >
                          <Share />
                        </Link>
                        <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                          {item.brief_two_liner_bio}
                        </Card.Text>
                      </div>
                      <div className="itemEdit d-flex flex-wrap justify-content-center mt-3 gap-2">
                        <Link
                          to={`/${entities}/details/${item.id}`}
                          className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                        >
                          View More
                        </Link>
                        <Link
                          to={`/${entities}/details/${item.id}?demo=reviews`}
                          className="actionBtn btn action d-flex align-items-center justify-content-center text-center flex-grow-1 cursor-pointer"
                          title="review"
                        >
                          Reviews
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">No Institutes Found</p>
            )}
          </Row>
        </Container>
      )}
    </section>
  );
}

export default Institute;
