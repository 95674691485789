const config = {
  localhost: {
    title: "Ameerpet IT",
    name: "Ameerpet",
    logo: "https://ameerpetit.com/static/media/logo.a30efeaa7c1e3afd9b7d.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_4060bc59-31b5-46f7-8b53-2d8fc59c810b.ico",
    link: "ameerpetit.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "training",
    coursesAlias: "trainings",
    adminsListID: ["asanachennai@gmail.com"],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "656e88e6a432b4adb9a54e20",
    fromEmail: "contact@ameerpetit.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  
  "ameerpetit.com": {
    title: "Ameerpet IT",
    name: "Ameerpet",
    logo: "https://ameerpetit.com/static/media/logo.a30efeaa7c1e3afd9b7d.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_4060bc59-31b5-46f7-8b53-2d8fc59c810b.ico",
    link: "ameerpetit.com",
    demoAlias: "demo",
    demosAlias: "demos",
    courseAlias: "course",
    coursesAlias: "courses",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "65712c68a2664a4197cec890",
    fromEmail: "contact@ameerpetit.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "mata.ameerpetit.com": {
    title: "Mata IT",
    name: "Mata",
    logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_19c78aaf-237e-4b12-b2bb-56162d8502fe.jpg",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_19c78aaf-237e-4b12-b2bb-56162d8502fe.jpg",
    link: "mata.ameerpetit.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "training",
    coursesAlias: "trainings",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["chaitanya.ameerpetit@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@ameerpetit.com",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "sstech.ameerpetit.com": {
    title: "SSTech IT",
    name: "SSTech",
    logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_7462fc49-4d10-43b6-b0ed-c093862f14c1.jpg",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_7462fc49-4d10-43b6-b0ed-c093862f14c1.jpg",
    link: "ameerpetit.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "training",
    coursesAlias: "trainings",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@ameerpetit.com",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "bata.ameerpetit.com": {
    title: "Bata IT",
    name: "Bata",
    logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_3a8a8d1f-f758-40f7-b9e3-306bd80c751f.jpg",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_3a8a8d1f-f758-40f7-b9e3-306bd80c751f.jpg",
    link: "ameerpetit.com",
    demoAlias: "meeting",
    demosAlias: "meetings",
    courseAlias: "course",
    coursesAlias: "courses",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@ameerpetit.com",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "onehub.ameerpetit.com": {
    title: "OneHub IT",
    name: "OneHub",
    logo: "https://ameerpetit.com/static/media/logo.a30efeaa7c1e3afd9b7d.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_4060bc59-31b5-46f7-8b53-2d8fc59c810b.ico",
    link: "ameerpetit.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "training",
    coursesAlias: "trainings",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanyathondapu@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
    ],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@ameerpetit.com",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "ecs.ameerpetit.com": {
    title: "ECS IT",
    name: "ECS",
    logo: "https://ameerpetit.com/static/media/logo.a30efeaa7c1e3afd9b7d.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_4060bc59-31b5-46f7-8b53-2d8fc59c810b.ico",
    link: "ameerpetit.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "trainings",
    coursesAlias: "training",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanyathondapu@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
    ],
    supportListID: ["chaitanyathondapu@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@ameerpetit.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "show",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "show",
  },
  "events.indianstartups.us": {
    title: "Indian Startups",
    name: "Indian Startups",
    logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_aa9b0928-732a-467a-9d00-7beba1477592.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_aa9b0928-732a-467a-9d00-7beba1477592.png",
    link: "events.indianstartups.us",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "trainings",
    coursesAlias: "training",
    adminsListID: [
      "intellide@gmail.com",
      "chaitanyathondapu@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "asanachennai@gmail.com",
    ],
    supportListID: ["chaitanyathondapu@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@indianstartups.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "hide",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "hide",
  },
  "events.indianstartups.com": {
    title: "Indian Startups",
    name: "Indian Startups",
    logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_aa9b0928-732a-467a-9d00-7beba1477592.png",
    favicon:
      "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5C_aa9b0928-732a-467a-9d00-7beba1477592.png",
    link: "events.indianstartups.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "trainings",
    coursesAlias: "training",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["chaitanya.ameerpetit@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@indianstartups.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "hide",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "hide",
  },
  "desimeetings.com": {
    title: "Desi Meetings",
    name: "Desi Meetings",
    logo: "https://placehold.co/200x150/orange/white?text=Desi+Meetings",
    favicon: "https://placehold.co/200x150/orange/white?text=Desi+Meetings",
    link: "desimeetings.com",
    demoAlias: "event",
    demosAlias: "events",
    courseAlias: "trainings",
    coursesAlias: "training",
    adminsListID: [
      "intellide@gmail.com",
      "asanachennai@gmail.com",
      "chaitanya.ameerpetit@gmail.com",
      "chaitanyathondapu@gmail.com",
    ],
    supportListID: ["sohailaman121@gmail.com"],
    trainersListID: "656537ad7e2ea970eb7d2143",
    fromEmail: "contact@desimeetings.com",
    countryFilter: "show",
    tagsFilter: "show",
    coursesFilter: "hide",
    learningLevelFilter: "show",
    paymentFilter: "show",
    teachingModeFilter: "show",
    ratingsFilter: "hide",
  },
};

export default config;
