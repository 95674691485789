import React from "react";
import * as Actions from "../../constants/baseUrl";
// import axios from "axios";
import VideoEmbed from "./videoEmbeded";
import "./videostyle.css";
import "../../App.css";
import CustomLoader from "../loader/loader";
import { Helmet } from "react-helmet";

export default class Display_TrainerVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [], loading: true };
  }
  componentDidMount() {
    this.populateList();
  }
  async populateList() {
    try {
      const data = await fetch(`${Actions.BASE_URL}trainers`).then(
        (response) => {
          console.log(response);
          return response.json();
        }
      );
      this.setState({ items: data, loading: false });
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }
  }
  displayVideos() {
    return (
      <>
        <Helmet>
          <title>
            Trainer Videos | Ameerpet IT - World's Biggest Live Instructors Marketplace
          </title>
        </Helmet>

        {this.state.items.map((item) =>
          Object.keys(item.video_urls).length > 0 ? (
            <div className="col-lg-12 col-md-12 row mt-4">
              <h3 className="vidheader">{item.name}</h3>
              {item.approvalStatus == "approved" ? (
                Object.values(item.video_urls).map((value, index) => {
                  return (
                    <div className="col-lg-4 col-md-4 videoContent">
                      {/* Update Url from trainer response */}
                      <VideoEmbed url={`${value}`} />
                      {/* <div className='col-lg-12 col-md-12 videodesc'>
        <p><strong>Trainer :</strong> {item.name}</p>
        <p><strong>Description :</strong> {item.category}</p>
        </div> */}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )
        )}
      </>
    );
  }
  videoProgress() {
    return <CustomLoader />;
  }
  render() {
    return (
      <div className="container videocontainer">
        {this.state.loading ? this.videoProgress() : this.displayVideos()}
        {/* <div className='col-lg-4 col-md-4 videoContent'>
                    <VideoEmbed url="https://www.youtube.com/embed/RM4C6MOqUwk" />  
                    </div>
                    <div className='col-lg-4 col-md-4 videoContent'>
                    <VideoEmbed url="https://www.youtube.com/embed/vK4gcXIJJ0s" />  
                    </div>
                    <div className='col-lg-4 col-md-4 videoContent'>
                    <VideoEmbed url="https://www.youtube.com/embed/qtumX65i4Zk" />  
                    </div>
                    <div className='col-lg-4 col-md-4 videoContent'>
                    <VideoEmbed url="https://www.youtube.com/embed/vK4gcXIJJ0s" />  
                    </div> */}
      </div>
    );
  }
}
