export const HowItWorksData = [
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit suscipit tempora non illo ea dolore dolorum voluptatem, accusantium at hic, delectus corporis recusandae dolorem doloribus beatae modi eius temporibus inventore voluptatum nisi ipsa quidem nulla error! Error repudiandae officia voluptatem nemo aliquid est, non velit sit dolores, voluptas id exercitationem.",
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit suscipit tempora non illo ea dolore dolorum voluptatem, accusantium at hic, delectus corporis recusandae dolorem doloribus beatae modi eius temporibus inventore voluptatum nisi ipsa quidem nulla error! Error repudiandae officia voluptatem nemo aliquid est, non velit sit dolores, voluptas id exercitationem.",
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit suscipit tempora non illo ea dolore dolorum voluptatem, accusantium at hic, delectus corporis recusandae dolorem doloribus beatae modi eius temporibus inventore voluptatum nisi ipsa quidem nulla error! Error repudiandae officia voluptatem nemo aliquid est, non velit sit dolores, voluptas id exercitationem.",
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit suscipit tempora non illo ea dolore dolorum voluptatem, accusantium at hic, delectus corporis recusandae dolorem doloribus beatae modi eius temporibus inventore voluptatum nisi ipsa quidem nulla error! Error repudiandae officia voluptatem nemo aliquid est, non velit sit dolores, voluptas id exercitationem.",
  },
  {
    question: "Lorem ipsum dolor sit amet consectetur, adipisicing elit?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit suscipit tempora non illo ea dolore dolorum voluptatem, accusantium at hic, delectus corporis recusandae dolorem doloribus beatae modi eius temporibus inventore voluptatum nisi ipsa quidem nulla error! Error repudiandae officia voluptatem nemo aliquid est, non velit sit dolores, voluptas id exercitationem.",
  },
];
